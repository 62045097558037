import React, { useState, useEffect } from 'react';
import './VotePoll.css';
import { useParams, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import image from "../../assets/images/codeNotValid.png";
import imageTimeEnd from "../../assets/images/timeEnd.png"
import MessageModal from '../MessageModal/MessageModal';

const VotePoll = () => {
    const { pollId } = useParams();
    const [poll, setPoll] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [hasVoted, setHasVoted] = useState(false);
    const [allowedToVote, setAllowedToVote] = useState(false);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [visible, setIsVisible] = useState(false);
    const navigate = useNavigate();

    const customDecode = (encoded) => {
        const decodedBase64 = atob(encoded);
        const strippedPrefix = decodedBase64.replace('customPrefix', '');
        const reversedBase64 = strippedPrefix.split('').reverse().join('');
        const parsedWordArray = CryptoJS.enc.Base64.parse(reversedBase64);
        const decoded = CryptoJS.enc.Utf8.stringify(parsedWordArray);
        return decoded;
    };

    const decodedPollId = customDecode(pollId);

    useEffect(() => {
        const fetchPoll = async () => {
            setLoading(true);
            const token = localStorage.getItem('token');

            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/polls/getPollById`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ pollId: decodedPollId, token }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setPoll(data);
                    setAllowedToVote(true);
                } else {
                    const result = await response.json();
                    setMessage(result.error || 'Problém s načítaním hlasovania');
                    if (result.error === 'Chyba pri načitaní hlasovania') {
                        setHasVoted(true);
                        setAllowedToVote(true);
                    } else if (result.error === 'User is not invited to this poll') {
                        setAllowedToVote(false);
                    }
                }
            } catch (error) {
                setMessage('Problém s načítaním hlasovania');
            } finally {
                setLoading(false);
            }
        };

        fetchPoll();
    }, [decodedPollId]);

    const handleOptionChange = (questionIndex, idOption) => {
        setSelectedOptions({ ...selectedOptions, [questionIndex]: idOption });
    };

    const handleVote = async () => {
        if (hasVoted) {
            setMessage('Už ste hlasovali');
            return;
        } else if (!allowedToVote) {
            setMessage('Nemáte povolenie hlasovať');
            return;
        }

        const allQuestionsAnswered = poll.questions.every((question, qIndex) => selectedOptions[qIndex] !== undefined);
        if (!allQuestionsAnswered) {
            setMessage('Prosím odpovedzte na všetky otázky');
            return;
        }

        setLoading(true);
        const token = localStorage.getItem('token');
        const role = localStorage.getItem('role');

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/polls/vote`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ pollId: decodedPollId, selectedOptions, role, token })
            });

            if (response.ok) {
                setMessage('Hlasovanie bolo úspešné');
                setHasVoted(true);
                setIsVisible(true);
            } else {
                const result = await response.json();
                setMessage(result.message || 'Chyba pri hlasovaní');
            }
        } catch (error) {
            setMessage('Chyba pri hlasovaní');
        } finally {
            setLoading(false);
        }
    };

    const handleModalFunction = () => {
        setIsVisible(false);
        navigate('/pollList');
    }

    const isPollEnded = poll && (poll.dateEnd !== null && new Date(poll.dateEnd) < new Date());


    return (
        <div className="vote-poll-container">
            <MessageModal isVisible={visible} message={"Hlasovanie prebehlo úspešne"} buttonFunction={handleModalFunction}/>
            <h2>Hlasovanie</h2>
            {loading && <div className="loading-spinner-component"><div className="spinner"></div></div>}
            {hasVoted && 
                <div style={{ marginTop: "0px" }}>
                <div style={{ marginTop: "-210px" }}>
                    <div className="centered-container">
                        <h2 className="centered-text">Už ste hlasovali</h2>
                    </div>
                    <div className="centered-container-img" style={{ marginBottom: "90px" }}>
                        <img src={image} style={{ height: "100px", width: "100px" }} alt="No votes available" />
                    </div>
                </div>
            </div>
            }

            {isPollEnded && 
                <div style={{ marginTop: "0px" }}>
                <div style={{ marginTop: "-210px" }}>
                    <div className="centered-container">
                        <h2 className="centered-text">Hlasovanie už skončilo</h2>
                    </div>
                    <div className="centered-container-img" style={{ marginBottom: "90px" }}>
                        <img src={imageTimeEnd} style={{ height: "100px", width: "100px" }} alt="No votes available" />
                    </div>
                </div>
            </div>
            }

            {!allowedToVote && 
                <div style={{ marginTop: "0px" }}>
                <div style={{ marginTop: "-210px" }}>
                    <div className="centered-container">
                        <h2 className="centered-text">Nemáte povolenie hlasovať</h2>
                    </div>
                    <div className="centered-container-img" style={{ marginBottom: "90px" }}>
                        <img src={image} style={{ height: "100px", width: "100px" }} alt="No votes available" />
                    </div>
                </div>
            </div>
            }
            {(poll && !hasVoted && allowedToVote && !isPollEnded) && (
                
                <>
                    <h2>{poll.pollName}</h2>
                    {poll.questions.map((question, qIndex) => (
                        <div key={qIndex} className="question-block">
                            <h3>{question.question}</h3>
                            {question.options.map((option, oIndex) => (
                                <label key={option.idOption} className="option-label">
                                    <input
                                        type="radio"
                                        name={`question-${qIndex}`}
                                        onChange={() => handleOptionChange(qIndex, option.idOption)}
                                        disabled={hasVoted}
                                    /> {option.option}
                                </label>
                            ))}
                        </div>
                    ))}
                    {message && <p className="message">{message}</p>}
                    <button className="vote-button" onClick={handleVote} disabled={hasVoted || loading}>
                        {loading ? 'Hlasovanie...' : 'Hlasovať'}
                    </button>
                </>
            )}
        </div>
    );
};

export default VotePoll;
