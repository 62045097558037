import React, { createContext, useState, useContext } from 'react';

export const CalendarContext = createContext();

export const CalendarProvider = ({ children }) => {
    const [shouldResetCalendar, setShouldResetCalendar] = useState(false);

    return (
        <CalendarContext.Provider value={{ shouldResetCalendar, setShouldResetCalendar }}>
            {children}
        </CalendarContext.Provider>
    );
};

