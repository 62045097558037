import React, { useState, useEffect } from 'react';
import "./EvaluationForm.css";

const EvaluationForm = ({ isVisible, onClose }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [subject, setSubject] = useState('');  // New state for the subject
    const [file, setFile] = useState(null);
    const [showClass, setShowClass] = useState('');
    const [blinkClass, setBlinkClass] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [successSend, setSuccessSend] = useState(false);

    useEffect(() => {
        if (isVisible) {
            setShowClass('show');
        } else {
            setShowClass('');
        }
    }, [isVisible]);

    useEffect(() => {
        if (successSend) {
            setSuccessSend(false);
        }
    }, [isVisible]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleEvaluationSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const token = localStorage.getItem("token");
            const rspId = localStorage.getItem('selectedProgramId');
            const userData = JSON.parse(localStorage.getItem('user'));
            const formData = new FormData();
            formData.append('title', title);
            formData.append('description', description);
            formData.append('subject', subject);  
            formData.append('file', file);
            formData.append('token', token);
            formData.append('rspId', rspId);
            formData.append('fileAuthor', userData.name);

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/addEvaluation`, {
                method: 'POST',
                body: formData,
            });

            setLoading(false);
            if (response.ok) {
                setMessage(null);
                setTitle('');
                setDescription('');
                setSubject('');
                setFile(null);
                setSuccessSend(true);
            } else {
                const result = await response.json();
                setMessage(result.message || 'Problém pri odosielaní záznamu evaluácie');
            }
        } catch (error) {
            setMessage('Problém pri odosielaní záznamu evaluácie');
            setLoading(false);
        }
    };

    const handleOverlayClick = () => {
        setBlinkClass('blink');
        setTimeout(() => {
            setBlinkClass('');
        }, 300);
    };

    return (
        <div className={`modal-overlay ${showClass} ${blinkClass}`} onClick={handleOverlayClick}>
            {loading && <div className="loading-spinner"></div>}
            <div className={`modal-content2 ${showClass} ${blinkClass}`} onClick={(e) => e.stopPropagation()}>
                {!successSend && (
                    <>
                        <button className="close-button" onClick={onClose}>&times;</button>
                        <h2 id="titleModal" style={{marginBottom: "5px"}}>Pridať Záznam Evaluácie</h2>
                        <form onSubmit={handleEvaluationSubmit}>
                            <input
                                type="text"
                                className="input-field"
                                placeholder="Názov evaluácie"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                            />
                            <textarea
                                className="textarea-field"
                                placeholder="Popis evaluácie"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                required
                            />
                            <input
                                type="text"
                                className="input-field"
                                placeholder="Predmet"
                                value={subject}  // Adding input field for the subject
                                onChange={(e) => setSubject(e.target.value)}
                                required
                            />
                            <input
                                type="file"
                                onChange={handleFileChange}
                                required
                            />
                            <span className="error-message">{message}</span>
                            <button type="submit" className="submit-button">Odoslať záznam evaluácie</button>
                        </form>
                    </>
                )}
                {successSend && (
                    <>
                        <button className="close-button" onClick={onClose}>&times;</button>
                        <h2 id="titleModal">Záznam Evaluácie bol úspešne odoslaný</h2>
                        <button className="ok-button" onClick={onClose}>Ok</button>
                    </>
                )}
            </div>
        </div>
    );
};

export default EvaluationForm;
