import React, { useState, useEffect } from 'react';
import './FeedbackList.css';
import { FaFileAlt, FaTrashAlt } from 'react-icons/fa';
import ConfirmModal from '../ConfrimModal/ConfirmModal';
import MessageModal from '../MessageModal/MessageModal';
import image from '../../assets/images/errorDocument.png';

const FeedbackList = () => {
    const [feedbacks, setFeedbacks] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
    const [isConfirmVisible, setIsConfirmVisible] = useState(false);
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [feedbackToDelete, setFeedbackToDelete] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchFeedbacks = async () => {
            setIsLoading(true);
            const rspId = localStorage.getItem('selectedProgramId');
            const token = localStorage.getItem('token');

            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getFeedbacks`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ rspId, token }),
                });

                if (response.ok) {
                    const data = await response.json();
                    const sortedData = data.sort((a, b) => new Date(b.dateStart) - new Date(a.dateStart));
                    setFeedbacks(sortedData);
                    setFilteredFeedbacks(sortedData);
                } else {
                    if(response.status === 404){

                    } else{
                        setError('Problém získať podnety');
                    }
                    
                }
            } catch (error) {
                
            } finally {
                setIsLoading(false);
            }
        };

        fetchFeedbacks();
    }, []);

    useEffect(() => {
        const lowercasedFilter = searchTerm.toLowerCase().replace(/\s+/g, ''); // Odstráni všetky medzery
        const filteredData = feedbacks.filter(item => {
            const titleMatch = item.title.toLowerCase().includes(lowercasedFilter);
            const descriptionMatch = item.description && item.description.toLowerCase().includes(lowercasedFilter);
            const fileNameMatch = item.fileName && item.fileName.toLowerCase().includes(lowercasedFilter);
            
            const dateString = item.dateStart && new Date(item.dateStart).toLocaleDateString().replace(/\s+/g, '');
            const dateMatch = dateString && dateString.includes(lowercasedFilter);
    
            return titleMatch || descriptionMatch || fileNameMatch || dateMatch;
        });
    
        setFilteredFeedbacks(filteredData);
    }, [searchTerm, feedbacks]);
    
    
    const handleFileClick = (fileId) => {
        const token = localStorage.getItem('token');
        window.open(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getFeedbackFile/${fileId}/${token}`, '_blank');
    };

    const handleFeedbackDelete = (feedbackId, title) => {
        setFeedbackToDelete({ feedbackId, title });
        setIsConfirmVisible(true);
        setMessage(`Naozaj si prajete vymazať podnet: ${title}?`);
    };

    const confirmDeleteFeedback = async () => {
        if (feedbackToDelete) {
            const token = localStorage.getItem('token');
            const rspId = localStorage.getItem('selectedProgramId');
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/deleteFeedback`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ feedbackId: feedbackToDelete.feedbackId, token, rspId }),
                });

                if (response.ok) {
                    setFeedbacks(feedbacks.filter(feedback => feedback.id !== feedbackToDelete.feedbackId));
                    setFilteredFeedbacks(filteredFeedbacks.filter(feedback => feedback.id !== feedbackToDelete.feedbackId));
                    setIsMessageVisible(true);
                    setMessage(`Podnet: ${feedbackToDelete.title} bol úspešne odstránený.`);
                } else {
                    setMessage(`Podnet: ${feedbackToDelete.title} sa nepodarilo odstrániť.`)
                    setIsMessageVisible(true);
                }
            } catch (error) {
                setMessage(`Podnet: ${feedbackToDelete.title} sa nepodarilo odstrániť.`)
                setIsMessageVisible(true);
            } finally {
                setIsConfirmVisible(false);
                setFeedbackToDelete(null);
            }
        }
    };

    const handleLeftButtonFunction = () => {
        setFeedbackToDelete(null);
        setIsConfirmVisible(false);
    };

    const handleRightButtonFunction = () => {
        confirmDeleteFeedback();
    };

    const messageModalButtonFunction = () => {
        setIsMessageVisible(false);
    };

    return (
        <div className="feedback-list-wrapper">
            <ConfirmModal
                isVisible={isConfirmVisible}
                message={message}
                leftButtonText="Nie"
                rightButtonText="Áno"
                leftButtonFunction={handleLeftButtonFunction}
                rightButtonFunction={handleRightButtonFunction}
            />
            <MessageModal
                isVisible={isMessageVisible}
                message={message}
                buttonFunction={messageModalButtonFunction}
            />
            <h2 className="feedback-list-title">Zoznam podnetov</h2>
            {isLoading && <div className="loading-spinner-component"><div className="spinner"></div></div>}
            {error && <p className="error-message">{error}</p>}
            {feedbacks.length === 0 && !isLoading &&
            <div style={{ marginTop: "-210px" }}>
                <div className="centered-container">
                    <h2 className="centered-text">Zatiaľ žiadne podnety</h2>
                </div>
                 <div className="centered-container-img" style={{ marginBottom: "90px" }}>
                    <img src={image} alt="No proposals available" />
                 </div>
                 </div>
            }
            {feedbacks.length > 0 && !isLoading &&
                <input
                    type="text"
                    placeholder="Hľadať podľa názvu, popisu, dátumu alebo názvu súboru..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
            }
            <ul className="feedback-list">
                {filteredFeedbacks.map(feedback => (
                    <li key={feedback.id} className="feedback-item">
                        <FaFileAlt className="file-icon" />
                        <div className="feedback-info">
                            <div className="feedback-title-container">
                                <span className="feedback-title">Podnet: {feedback.title}</span>
                                <FaTrashAlt className="delete-icon" onClick={() => handleFeedbackDelete(feedback.id, feedback.title)} />
                            </div>
                            <p className="feedback-description">Popis: {feedback.description}</p>
                            <span className="feedback-date">Dátum pridania: {new Date(feedback.dateStart).toLocaleDateString()}</span>
                            {feedback.fileName ? (
                                <>
                                    <span className="feedback-fileName" onClick={() => handleFileClick(feedback.id)}>Názov súboru: {feedback.fileName}</span>
                                    <div className="feedback-actions">
                                        <button className="file-download-button2" onClick={() => handleFileClick(feedback.id)}>Stiahnuť súbor</button>
                                    </div>
                                </>
                            ) : (
                                <span className="no-file">Bez priloženého súboru</span>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FeedbackList;
