import React, { useState, useEffect } from 'react';
import "./ConfirmModal.css";
import "../MessageModal/MessageModal.css";

const ConfirmModal = ({ isVisible, message, leftButtonText, rightButtonText, leftButtonFunction, rightButtonFunction }) => {
    const [showClass, setShowClass] = useState(''); 
    const [blinkClass, setBlinkClass] = useState('');

    useEffect(() => {
        if (isVisible) {
            setShowClass('show');
        } else {
            setShowClass('');
        }
    }, [isVisible]);

    const handleOverlayClick = () => {
        setBlinkClass('blink');
        setTimeout(() => {
            setBlinkClass('');
        }, 300); 
    };

    return (
        <div className={`modal-overlay ${showClass} ${blinkClass}`} onClick={handleOverlayClick}>
            <div style={{width: "470px", marginLeft: "25px", marginRight: "25px"}} className={`modal-content2 ${showClass} ${blinkClass}`} onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={leftButtonFunction}>&times;</button>
                <h2 style={{ color: "black", marginTop: "15px", textAlign: "center", fontFamily: "Arial, Helvetica, sans-serif", fontSize: "28px" }} className='text-message'> {message} </h2>
                <div className='button-container2'>
                    <button className='function-button left-button' onClick={leftButtonFunction}>{leftButtonText}</button>
                    <button className='function-button right-button' onClick={rightButtonFunction}>{rightButtonText}</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
