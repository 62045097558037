import React, { useState, useEffect, useContext } from 'react';
import { ProgramContext } from '../../Contexts/ProgramContext';
import './CancelMeetingPage.css';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import CancelMeeting from '../../Components/CancelMeeting/CancelMeeting';

const CancelMeetingPage = () => {
    return (
        <div>
           <div>
            <Navbar></Navbar>
           </div>
           <div style={{  marginTop: "100px" }}>
                <CancelMeeting></CancelMeeting>
           </div>
           <div style={{marginTop: "150px"}}>
            <Footer></Footer>
           </div>
        </div>
    )
}


export default CancelMeetingPage;