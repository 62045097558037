import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PollList.css';
import { FaVoteYea, FaPlus } from 'react-icons/fa';
import image from "../../assets/images/noVotes.png";
import CryptoJS from 'crypto-js';

const PollList = () => {
    const [polls, setPolls] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPolls, setFilteredPolls] = useState([]);
    const [userData, setUserData] = useState({});
    const [authors, setAuthors] = useState({});
    const navigate = useNavigate();

    const customEncode = (id) => {
        const base64 = CryptoJS.enc.Utf8.parse(id.toString());
        const reversedBase64 = base64.toString(CryptoJS.enc.Base64).split('').reverse().join('');
        const prefixed = `customPrefix${reversedBase64}`;
        return btoa(prefixed);
    };

    useEffect(() => {
        const fetchPolls = async () => {
            setIsLoading(true);
            const selectedProgramId = localStorage.getItem('selectedProgramId');
            const token = localStorage.getItem('token');
            const user = JSON.parse(localStorage.getItem('user'));

            setUserData(user);

            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/polls/getRspPolls`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ selectedProgramId, token }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setPolls(data);
                    setFilteredPolls(data);

                    // Fetch author names
                    const authorIds = [...new Set(data.map(poll => poll.creator))];
                    const authorResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/user/getUserNames`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userIds: authorIds, token }),
                    });
                    const authorsData = await authorResponse.json();
                    const authorsMap = authorsData.reduce((acc, author) => {
                        acc[author.idUser] = author.name;
                        return acc;
                    }, {});
                    setAuthors(authorsMap);
                } else {
                    if(response.status === 404){
                        setError('');
                    } else{
                        setError('Problém získať hlasovania');
                    }
                    
                }
            } catch (error) {
                setError('Problém získať hlasovania');
            } finally {
                setIsLoading(false);
            }
        };

        fetchPolls();
    }, []);

    useEffect(() => {
        const lowercasedFilter = searchTerm.toLowerCase();
        const filteredData = polls.filter(poll => {
            return (
                poll.name.toLowerCase().includes(lowercasedFilter) ||
                (authors[poll.creator]?.toLowerCase().includes(lowercasedFilter))
            );
        });
        setFilteredPolls(filteredData);
    }, [searchTerm, polls, authors]);

    const handleVote = (pollId) => {
        const encodedPollId = customEncode(pollId);
        navigate(`/votePoll/${encodedPollId}`);
    };

    return (
        <div className="poll-list-wrapper">
            <div className="poll-container">
                <h2 className="poll-list-title">Zoznam dostupných hlasovaní</h2>
                {error && <p className="error-message">{error}</p>}
                <input
                    type="text"
                    placeholder="Hľadať podľa názvu alebo autora..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input"
                />
                {polls.length === 0 && !isLoading &&
                    <div style={{ marginTop: "0px" }}>
                        <div style={{ marginTop: "-210px" }}>
                            <div className="centered-container">
                                <h2 className="centered-text">Žiadne dostupné hlasovania</h2>
                            </div>
                            <div className="centered-container-img" style={{ marginBottom: "90px" }}>
                                <img src={image} style={{ height: "120px", width: "120px" }} alt="No votes available" />
                            </div>
                        </div>
                    </div>
                }
                <ul className="poll-list">
                    {isLoading && <div className="loading-spinner-component"><div className='spinner'></div></div>}
                    {filteredPolls.map(poll => (
                        <li key={poll.idPoll} className="poll-item">
                            <div className="poll-info">
                                <h3 className="poll-title">{poll.name}</h3>
                                <span className="poll-author">Autor: {authors[poll.creator]}</span>
                                <div className="poll-actions">
                                    <FaVoteYea className="vote-icon" onClick={() => handleVote(poll.idPoll)} />
                                    <FaPlus className="plus-icon" onClick={() => handleVote(poll.idPoll)} />
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default PollList;
