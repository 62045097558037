import React, { useState, useEffect, useRef } from 'react';
import { FaChevronLeft, FaChevronRight, FaCaretDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './StudyProgramForm.css';
import image from "../../assets/images/codeNotValid.png";
import defaultUserImage from '../../assets/images/profile.png';
import MessageModal from '../MessageModal/MessageModal';

const StudyProgramForm = () => {

    const navigate = useNavigate();
    const [accessDenied, setAccessDenied] = useState(false);  
    const [step, setStep] = useState(1);
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [steps, setSteps] = useState(9);
    const [lastStep, setLastStep] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        programType: '',
        teacherType: '',
        facultyName: '',
        profileSubjectEnsurers: [{ name: '', email: '' }, { name: '', email: '' }, { name: '', email: '' }, { name: '', email: '' }, { name: '', email: '' }],
        teacherBasicEnsurers: [{ name: '', email: '' }, { name: '', email: '' }, { name: '', email: '' }],
        graduateEnsurer: { name: '', email: '' },
        employerEnsurer: { name: '', email: '' },
        studentRepresentative: { name: '', email: '' },
        guarantor: { name: '', email: '' }
    });

    const [errors, setErrors] = useState({
        name: '',
        programType: '',
        teacherType: '',
        facultyName: '',
        profileSubjectEnsurers: '',
        teacherBasicEnsurers: '', 
        graduateEnsurer: '',
        employerEnsurer: '',
        studentRepresentative: '',
        guarantor: ''
    });

    const [faculties, setFaculties] = useState([]);
    const [users, setUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [userImages, setUserImages] = useState({});
    const [searchResultsProfile, setSearchResultsProfile] = useState([[], [], [], [], []]); 
    const [searchResultsTeacher, setSearchResultsTeacher] = useState([[], [], []]); 
    const [searchInputs, setSearchInputs] = useState(['', '', '', '', '', '', '', '']); 
    const [graduateEnsurer, setGraduateEnsurer] = useState({ name: '', email: '' });
    const [searchInputGraduate, setSearchInputGraduate] = useState({ name: '', email: '' });
    const [searchResultsGraduate, setSearchResultsGraduate] = useState([]);
    const [employerEnsurer, setEmployerEnsurer] = useState({ name: '', email: '' });
    const [searchInputEmployer, setSearchInputEmployer] = useState({ name: '', email: '' });
    const [searchResultsEmployer, setSearchResultsEmployer] = useState([]);
    const [studentRepresentative, setStudentRepresentative] = useState({ name: '', email: '' });
    const [searchResultsStudent, setSearchResultsStudent] = useState([]);
    const [searchInputStudent, setSearchInputStudent] = useState({ name: '', email: '' });
    const [guarantor, setGuarantor] = useState({ name: '', email: '' });
    const [searchResultsGuarantor, setSearchResultsGuarantor] = useState([]);
    const [searchInputGuarantor, setSearchInputGuarantor] = useState({ name: '', email: '' });
    
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);
    const [isAdminOrRector, setIsAdminOrRector] = useState(false);
    const [isDean, setIsDean] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("token");
        const userData = JSON.parse(localStorage.getItem("user"));
        if (!token || !userData) {
            setAccessDenied(true);
        } else {
            const { admin, rector, idFaculty } = userData;
            setIsAdminOrRector(admin === 1 || rector === 1);

            if (!(admin === 1 || rector === 1) && (!idFaculty || idFaculty.length === 0)) {
            setAccessDenied(true);
            } else if ((admin === 1 || rector === 1) === false) {
                setIsDean(true);
            }
        }
    }, [isDean]);
    

    useEffect(() => {
        const token = localStorage.getItem("token");
        const userData = JSON.parse(localStorage.getItem("user"));
        const fetchFaculties = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/faculty/getFacults`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token }),
                });
                const data = await response.json();
                
                if (isDean && userData.idFaculty) {
                    const deanFaculties = data.filter(faculty => userData.idFaculty.includes(faculty.idFaculty));
                    setFaculties(deanFaculties);
                } else {
                    setFaculties(data);
                }
            } catch (error) {
                console.error('Error fetching faculties:', error);
            }
        };

        const fetchUsers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/auth/getAllUsers`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token }),
                });
                const data = await response.json();
                setUsers(data);
                setAllUsers(data);
                convertUserImages(data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchFaculties();
        fetchUsers();
    }, [isDean]);

    const convertUserImages = (users) => {
        const images = {};
        users.forEach(user => {
            if (user.picture && user.picture.data) {
                const isPng = user.picture.toString('hex', 0, 4) === '89504e47';
                const isJpeg = user.picture.toString('hex', 0, 2) === 'ffd8';
                const isWebp = user.picture.toString('hex', 0, 4) === '52494646' && user.picture.toString('hex', 8, 12) === '57454250';

                let mimeType = 'image/png'; // default to PNG
                if (isJpeg) mimeType = 'image/jpeg';
                if (isWebp) mimeType = 'image/webp';

                const blob = new Blob([new Uint8Array(user.picture.data)], { type: mimeType });
                const url = URL.createObjectURL(blob);
                images[user.email] = url;
            }
        });
        setUserImages(images);
    };

    useEffect(() => {
        return () => {
            Object.values(userImages).forEach(url => URL.revokeObjectURL(url));
        };
    }, [userImages]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(false);
                setSearchResultsProfile([[], [], [], [], [], [], []]);
                setSearchResultsTeacher([[], [], [], [], [], [], []]);
                setSearchResultsGraduate([]);
                setSearchResultsStudent([]);
                setSearchResultsEmployer([]);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if(formData.teacherType === "Combine"){
            setSteps(10);
        }
        else{
            setSteps(9);
        }
        const resetMembers = () => {
            const allEnsurers = [
                ...formData.profileSubjectEnsurers, 
                ...formData.teacherBasicEnsurers, 
                formData.graduateEnsurer, 
                formData.employerEnsurer,
                formData.studentRepresentative,
                formData.guarantor
            ];
    
            const updatedUsers = [...users];
            allEnsurers.forEach(ensurer => {
                if (ensurer.email && !updatedUsers.find(user => user.email === ensurer.email)) {
                    const userObj = allUsers.find(user => user.email === ensurer.email);
                    if (userObj) {
                        updatedUsers.push(userObj);
                    }
                }
            });
    
            setUsers(updatedUsers);
            setFormData(prevFormData => ({
                ...prevFormData,
                profileSubjectEnsurers: [{ name: '', email: '' }, { name: '', email: '' }, { name: '', email: '' }, { name: '', email: '' }, { name: '', email: '' }],
                teacherBasicEnsurers: [{ name: '', email: '' }, { name: '', email: '' }, { name: '', email: '' }],
                graduateEnsurer: { name: '', email: '' },
                employerEnsurer: { name: '', email: '' },
                studentRepresentative: { name: '', email: '' },
                guarantor: { name: '', email: '' }
            }));
            setSearchInputs(['', '', '', '', '', '', '', '']);
        };
    
        resetMembers();
    }, [formData.teacherType]);
    
    if (accessDenied) {
        return (
            <div style={{marginTop: "-60px"}}>
                 <div className="centered-container">
                    <h2 className="centered-text">Nemáte povolenie do tejto časti</h2>
                </div>
                <div className="centered-container-img">
                    <img src={image} alt="Invalid code illustration" />       
                </div>
            </div>
        );
    }
    
    const handleNextStep = () => {
        let validationErrors = {};
        if (step === 1 && formData.name.trim() === '') {
            validationErrors.name = 'Názov je povinný';
        }
        if (step === 2 && formData.programType === '') {
            validationErrors.programType = 'Typ programu je povinný';
        }
        if (step === 3 && formData.teacherType.trim() === '') {
            validationErrors.teacherType = 'Typ členenia členov v rade je povinný';
        }
        if (step === 4 && formData.facultyName.trim() === '') {
            validationErrors.facultyName = 'Výber fakulty je povinný';
        }
        if (step === 5) {
            if (formData.teacherType === 'Klasik' && formData.profileSubjectEnsurers.filter(person => person.email.trim() !== '').length < 4) {
                validationErrors.profileSubjectEnsurers = 'Musí byť vybrané štyri osôby pre klasický program.';
            } else if (formData.teacherType === 'Combine' && formData.profileSubjectEnsurers.filter(person => person.email.trim() !== '').length < 2) {
                validationErrors.profileSubjectEnsurers = 'Musia byť vybrané dve osoby pre kombinovaný program.';
            }
        }
        if (step === 6 && formData.teacherType === 'Combine') {
            if (formData.teacherBasicEnsurers.filter(person => person.email.trim() !== '').length < 3) {
                validationErrors.teacherBasicEnsurers = 'Musia byť vybrané tri osoby zodpovedné za učiteľský základ.';
            }
        }
        if(step === 6 && formData.teacherType !== 'Combine'){
            if (formData.graduateEnsurer.email.trim() === '') {
                validationErrors.graduateEnsurer = 'Musi byť vybraný absolvent študijného programu.';
            }
        }
        if(step === 7 && formData.teacherType === 'Combine'){
            if (formData.graduateEnsurer.email.trim() === '') {
                validationErrors.graduateEnsurer = 'Musi byť vybraný absolvent študijného programu.';
            }
        }
        if(step === 7 && formData.teacherType !== 'Combine'){
            if (formData.employerEnsurer.email.trim() === '') {
                validationErrors.employerEnsurer = 'Musi byť vybraný zastúpca združení študijného programu.';
            }
        }
        if(step === 8 && formData.teacherType === 'Combine'){
            if (formData.employerEnsurer.email.trim() === '') {
                validationErrors.employerEnsurer = 'Musi byť vybraný zastúpca združení študijného programu.';
            }
        }
        if(step === 8 && formData.teacherType !== 'Combine'){
            if (formData.studentRepresentative.email.trim() === '') {
                validationErrors.studentRepresentative = 'Musi byť vybraný zastúpca študentov študijného programu.';
            }
        }
        if(step === 9 && formData.teacherType === 'Combine'){
            if (formData.studentRepresentative.email.trim() === '') {
                validationErrors.studentRepresentative = 'Musi byť vybraný zastúpca študentov študijného programu.';
            }
        }
        if(step === 9 && formData.teacherType !== 'Combine'){
            if (formData.guarantor.email.trim() === '') {
                validationErrors.guarantor = 'Musi byť vybraný garant študijného programu.';
            }
        }
        if(step === 10 && formData.teacherType === 'Combine'){
            if (formData.guarantor.email.trim() === '') {
                validationErrors.guarantor = 'Musi byť vybraný garant študijného programu.';
            }
        }

        
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
       
        if (formData.programType !== 'facultyProgram' && step === 3) {
            setStep(step + 2);
        } else{
            setStep(step + 1);
        }

        if (step === steps) {
            setLastStep(true);
        } else {
            setLastStep(false);
        }
    };

    const handlePreviousStep = () => {
        if (formData.programType !== 'facultyProgram' && step === 5) {
            setStep(step - 2);
        } else{
            setStep(step - 1);
        }
        setLastStep(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: '',
        });
        setDropdownVisible(false);
    };

    const handleProfileEnsurerChange = (e, index) => {
        const { value } = e.target;
        const newSearchInputs = [...searchInputs];
        newSearchInputs[index] = { name: value, email: '' };
        setSearchInputs(newSearchInputs);
        setErrors(prevErrors => ({
            ...prevErrors,
            profileSubjectEnsurers: ''
        }));
        const filteredUsers = users.filter(user => user.name.toLowerCase().includes(value.toLowerCase()));
        const newSearchResults = [...searchResultsProfile];
        newSearchResults[index] = filteredUsers;
        setSearchResultsProfile(newSearchResults);
    };

    const handleTeacherEnsurerChange = (e, index) => {
        const { value } = e.target;
        const newSearchInputs = [...searchInputs];
        newSearchInputs[index + 5] = { name: value, email: '' };
        setSearchInputs(newSearchInputs);
        setErrors(prevErrors => ({
            ...prevErrors,
            teacherBasicEnsurers: ''
        }));
        const filteredUsers = users.filter(user => user.name.toLowerCase().includes(value.toLowerCase()));
        const newSearchResults = [...searchResultsTeacher];
        newSearchResults[index] = filteredUsers;
        setSearchResultsTeacher(newSearchResults);
    };

    const handleEnsurerSelect = (user, index, type) => {
        if (type === 'profile') {
            const newProfileSubjectEnsurers = [...formData.profileSubjectEnsurers];
            newProfileSubjectEnsurers[index] = { name: user.name, email: user.email };
            setFormData({
                ...formData,
                profileSubjectEnsurers: newProfileSubjectEnsurers,
            });
        } else if (type === 'teacher') {
            const newTeacherBasicEnsurers = [...formData.teacherBasicEnsurers];
            newTeacherBasicEnsurers[index] = { name: user.name, email: user.email };
            setFormData({
                ...formData,
                teacherBasicEnsurers: newTeacherBasicEnsurers,
            });
        }

        const newSearchInputs = [...searchInputs];
        newSearchInputs[index] = { name: user.name, email: user.email };
        setSearchInputs(newSearchInputs);

        const updatedUsers = users.filter(u => u.email !== user.email);
        setUsers(updatedUsers);

        const newSearchResults = type === 'profile' ? [...searchResultsProfile] : [...searchResultsTeacher];
        newSearchResults[index] = [];
        if (type === 'profile') setSearchResultsProfile(newSearchResults);
        else setSearchResultsTeacher(newSearchResults);
        setDropdownVisible(false);
    };

    const handleEnsurerRemove = (index, type) => {
        if (type === 'profile') {
            const userEmailToRemove = formData.profileSubjectEnsurers[index].email;

           
            const userObj = allUsers.find(user => user.email === userEmailToRemove);

           
            if (userObj && !users.find(user => user.email === userObj.email)) {
                const updatedUsers = [...users, userObj];
                setUsers(updatedUsers);
            }

            const newProfileSubjectEnsurers = [...formData.profileSubjectEnsurers];
            newProfileSubjectEnsurers[index] = { name: '', email: '' };
            setFormData({
                ...formData,
                profileSubjectEnsurers: newProfileSubjectEnsurers,
            });

            const newSearchInputs = [...searchInputs];
            newSearchInputs[index] = { name: '', email: '' };
            setSearchInputs(newSearchInputs);
        } else if (type === 'teacher') {
            const userEmailToRemove = formData.teacherBasicEnsurers[index].email;

           
            const userObj = allUsers.find(user => user.email === userEmailToRemove);

           
            if (userObj && !users.find(user => user.email === userObj.email)) {
                const updatedUsers = [...users, userObj];
                setUsers(updatedUsers);
            }

            const newTeacherBasicEnsurers = [...formData.teacherBasicEnsurers];
            newTeacherBasicEnsurers[index] = { name: '', email: '' };
            setFormData({
                ...formData,
                teacherBasicEnsurers: newTeacherBasicEnsurers,
            });

            const newSearchInputs = [...searchInputs];
            newSearchInputs[index + 5] = { name: '', email: '' };
            setSearchInputs(newSearchInputs);
        }
    };


    const handleGraduateChange = (e) => {
        const { value } = e.target;
        setSearchInputGraduate({ name: value, email: '' });
        setErrors(prevErrors => ({
            ...prevErrors,
            graduateEnsurer: ''
        }));
        const filteredUsers = users.filter(user => user.name.toLowerCase().includes(value.toLowerCase()));
        setSearchResultsGraduate(filteredUsers);
    };
    
    const handleGraduateSelect = (user) => {
        setGraduateEnsurer({ name: user.name, email: user.email });
        setFormData(prevFormData => ({
            ...prevFormData,
            graduateEnsurer: { name: user.name, email: user.email }
        }));
        const updatedUsers = users.filter(u => u.email !== user.email);
        setUsers(updatedUsers);
    
        setSearchResultsGraduate([]);
        setDropdownVisible(false);
    };
    
    const handleGraduateRemove = () => {
        const userEmailToRemove = graduateEnsurer.email;
        
        const userObj = allUsers.find(user => user.email === userEmailToRemove);
        
        if (userObj && !users.find(user => user.email === userObj.email)) {
            const updatedUsers = [...users, userObj];
            setUsers(updatedUsers);
        }


        setGraduateEnsurer({ name: '', email: '' });
        setSearchInputGraduate({ name: '', email: '' });
        setFormData(prevFormData => ({
            ...prevFormData,
            graduateEnsurer: { name: '', email: '' }
        }));
    };
    
    const handleEmployerChange = (e) => {
        const { value } = e.target;
        setSearchInputEmployer({ name: value, email: '' });
        setErrors(prevErrors => ({
            ...prevErrors,
            employerEnsurer: ''
        }));
        const filteredUsers = users.filter(user => user.name.toLowerCase().includes(value.toLowerCase()));
        setSearchResultsEmployer(filteredUsers);
    };
    
    const handleEmployerSelect = (user) => {
        setEmployerEnsurer({ name: user.name, email: user.email });
    
        
        setFormData(prevFormData => ({
            ...prevFormData,
            employerEnsurer: { name: user.name, email: user.email }
        }));
    
        const updatedUsers = users.filter(u => u.email !== user.email);
        setUsers(updatedUsers);
    
        setSearchResultsEmployer([]);
        setDropdownVisible(false);
    };
    
    const handleEmployerRemove = () => {
        const userEmailToRemove = employerEnsurer.email;
    
        const userObj = allUsers.find(user => user.email === userEmailToRemove);
    
        if (userObj && !users.find(user => user.email === userObj.email)) {
            const updatedUsers = [...users, userObj];
            setUsers(updatedUsers);
        }
    
        setEmployerEnsurer({ name: '', email: '' });
    
        setSearchInputEmployer({ name: '', email: '' });
    
        setFormData(prevFormData => ({
            ...prevFormData,
            employerEnsurer: { name: '', email: '' }
        }));
    };

    const handleStudentRepresentativeChange = (e) => {
        const { value } = e.target;
        setSearchInputStudent({ name: value, email: '' });
        setErrors(prevErrors => ({
            ...prevErrors,
            studentRepresentative: ''
        }));
        const filteredUsers = users.filter(user => user.name.toLowerCase().includes(value.toLowerCase()));
        setSearchResultsStudent(filteredUsers);
    };
    
    const handleStudentRepresentativeSelect = (user) => {
        setStudentRepresentative({ name: user.name, email: user.email });
        setFormData(prevFormData => ({
            ...prevFormData,
            studentRepresentative: { name: user.name, email: user.email }
        }));

        const updatedUsers = users.filter(u => u.email !== user.email);
        setUsers(updatedUsers);
    
        setSearchResultsStudent([]);
        setDropdownVisible(false);
    };
    
    const handleStudentRepresentativeRemove = () => {
        const userEmailToRemove = studentRepresentative.email;
    
        const userObj = allUsers.find(user => user.email === userEmailToRemove);
    
        if (userObj && !users.find(user => user.email === userObj.email)) {
            const updatedUsers = [...users, userObj];
            setUsers(updatedUsers);
        }
    
        setFormData(prevFormData => ({
            ...prevFormData,
            studentRepresentative: { name: '', email: '' }
        }));
        setStudentRepresentative({ name: '', email: '' });
        setSearchInputStudent({ name: '', email: '' });
    };
    
    const handleGuarantorChange = (e) => {
        const { value } = e.target;
        setSearchInputGuarantor({ name: value, email: '' });
        setErrors(prevErrors => ({
            ...prevErrors,
            guarantor: ''
        }));
        const filteredUsers = users.filter(user => user.name.toLowerCase().includes(value.toLowerCase()));
        setSearchResultsGuarantor(filteredUsers);
    };
    
    const handleGuarantorSelect = (user) => {
        setGuarantor({ name: user.name, email: user.email });
        setFormData(prevFormData => ({
            ...prevFormData,
            guarantor: { name: user.name, email: user.email }
        }));
        const updatedUsers = users.filter(u => u.email !== user.email);
        setUsers(updatedUsers);
    
        setSearchResultsGuarantor([]);
        setDropdownVisible(false);
    };
    
    const handleGuarantorRemove = () => {
        const userEmailToRemove = guarantor.email;
    
        const userObj = allUsers.find(user => user.email === userEmailToRemove);
    
        if (userObj && !users.find(user => user.email === userObj.email)) {
            const updatedUsers = [...users, userObj];
            setUsers(updatedUsers);
        }
    
        setFormData(prevFormData => ({
            ...prevFormData,
            guarantor: { name: '', email: '' }
        }));
        setGuarantor({ name: '', email: '' });
        setSearchInputGuarantor({ name: '', email: '' });
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const token = localStorage.getItem("token");
        const dataToSend = { ...formData, token };
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/createRsp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend),
            });
            
            if (response.ok) {
                setLoading(false);
                setIsVisibleModal(true);
            } else {
                setLoading(false);
                alert('Chyba pri vytváraní rady Študijného Programu');
            }
        } catch (error) {
            setLoading(false);
            alert('Chyba pri vytváraní rady Študijného Programu');
        }
    };

    const getProgressWidth = () => {
        return `${(step) * (100 / steps)}%`;
    };

    const handleDropdownToggle = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const succesRegistration = () => {
        navigate("/");
    }

    return (
        <div className='block'>
            {loading === true && <div className="loading-spinner"/> }
            <MessageModal isVisible={isVisibleModal}  message={"Rada bola úspešne vytvorená"} buttonFunction={succesRegistration}></MessageModal>
            <div className="study-program-form-container-outer">
                <h2>Tvorba Rady Študijného Programu</h2>
                <div className="study-program-form-container-inner">
                    <form onSubmit={handleSubmit} onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                        }
                        }}>
                        {step === 1 && (
                            <div className="form-step">
                                <label>Názov: <span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder='Zadajte názov študijného programu'
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.name && <span className="error">{errors.name}</span>}
                                <div className="step-button-container">
                                    <button className="step-button" type="button" onClick={handleNextStep}>Ďalej  <FaChevronRight className='arrow-right' /></button>
                                </div>
                            </div>
                        )}

                        {step === 2 && (
                            <div className="form-step">
                                <label>Typ študijného programu: <span style={{ color: "red" }}>*</span></label>
                                <div className="radio-group">
                                    {isAdminOrRector === true && (
                                        <label>
                                            <input
                                                type="radio"
                                                name="programType"
                                                value="universityWideProgram"
                                                checked={formData.programType === 'universityWideProgram'}
                                                onChange={handleChange}
                                            />
                                            <span> Celouniverzitný študijný program</span>
                                        </label>
                                    )}
                                    <label>
                                        <input
                                            type="radio"
                                            name="programType"
                                            value="facultyProgram"
                                            checked={formData.programType === 'facultyProgram'}
                                            onChange={handleChange}
                                        />
                                        <span> Fakultný študijný program </span>
                                    </label>
                                </div>
                                {errors.programType && <span className="error">{errors.programType}</span>}
                                <div className="button-row">
                                    <div className="step-button-container2">
                                        <button className="step-button" type="button" onClick={handlePreviousStep}> <FaChevronLeft className='arrow-left' /> Späť</button>
                                    </div>
                                    <div className="step-button-container">
                                        <button className="step-button" type="button" onClick={handleNextStep}>Ďalej <FaChevronRight className='arrow-right' /> </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {step === 3 && (
                            <div className="form-step">
                                <label>Typ členenia členov rady študijného programu: <span style={{ color: "red" }}>*</span></label>
                                <div className="radio-group">
                                    <label>
                                        <input
                                            type="radio"
                                            name="teacherType"
                                            value="Combine"
                                            checked={formData.teacherType === 'Combine'}
                                            onChange={handleChange}
                                        />
                                        <span> Kombinovaný učiteľský program </span>
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="teacherType"
                                            value="Klasik"
                                            checked={formData.teacherType === 'Klasik'}
                                            onChange={handleChange}
                                        />
                                        <span> Študijný program </span>
                                    </label>
                                </div>
                                {errors.teacherType && <span className="error">{errors.teacherType}</span>}
                                <div className="button-row">
                                    <div className="step-button-container2">
                                        <button className="step-button" type="button" onClick={handlePreviousStep}> <FaChevronLeft className='arrow-left' /> Späť</button>
                                    </div>
                                    <div className="step-button-container">
                                        <button className="step-button" type="button" onClick={handleNextStep}>Ďalej <FaChevronRight className='arrow-right' /> </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {step === 4 && (
                            <div className="form-step">
                                <label>Fakulta: <span style={{ color: "red" }}>*</span></label>
                                <div className="faculty-dropdown-container">
                                    <button className="dropdown-button" type="button" onClick={handleDropdownToggle}>
                                        {formData.facultyName || 'Vyberte fakultu'} <FaCaretDown />
                                    </button>
                                    {dropdownVisible && (
                                        <div className="faculty-dropdown" ref={dropdownRef}>
                                            {faculties.map(faculty => (
                                                <div
                                                    key={faculty.idFaculty}
                                                    className="dropdown-item2"
                                                    onClick={() => handleChange({ target: { name: 'facultyName', value: faculty.facultyName } })}
                                                >
                                                    {faculty.facultyName}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                {errors.facultyName && <span className="error">{errors.facultyName}</span>}
                                <div className="button-row">
                                    <div className="step-button-container2">
                                        <button className="step-button" type="button" onClick={handlePreviousStep}>
                                            <FaChevronLeft className='arrow-left' /> Späť
                                        </button>
                                    </div>
                                    <div className="step-button-container">
                                        <button className="step-button" type="button" onClick={handleNextStep}>
                                            Ďalej <FaChevronRight className='arrow-right' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {step === 5 && (
                            <div className="form-step">
                                <label>Výber osôb zabezpečujúcich profilové predmety: <span style={{ color: "red" }}>*</span></label>
                                {[...Array(formData.teacherType === 'Combine' ? 2 : 4)].map((_, index) => (
                                    <div key={index} className="search-field">
                                        {formData.profileSubjectEnsurers[index].email ? (
                                            <div className="selected-user">
                                                <img src={userImages[formData.profileSubjectEnsurers[index].email] || defaultUserImage} alt={formData.profileSubjectEnsurers[index].email} className="user-image" />
                                                <div className="user-info">
                                                    <span className="user-name">{formData.profileSubjectEnsurers[index].name}</span>
                                                    <span className="user-email">{formData.profileSubjectEnsurers[index].email}</span>
                                                </div>
                                                <button type="button" className="remove-button" onClick={() => handleEnsurerRemove(index, 'profile')}>X</button>
                                            </div>
                                        ) : (
                                            <>
                                                <input
                                                    type="text"
                                                    placeholder="Vyhľadávanie osoby"
                                                    value={searchInputs[index].name}
                                                    onChange={(e) => handleProfileEnsurerChange(e, index)}
                                                    required
                                                />
                                                {searchResultsProfile[index].length > 0 && (
                                                    <div className="search-results" ref={dropdownRef}>
                                                        {searchResultsProfile[index].map(user => (
                                                            <div
                                                                key={user.id}
                                                                className={`search-result-item ${user.countMembership > 2 || user.isGraduateEnsurer || user.isStudentRepresentative ? 'disabled' : ''}`}
                                                                onClick={() => user.countMembership <= 2 && !user.isGraduateEnsurer && !user.isStudentRepresentative && handleEnsurerSelect(user, index, 'profile')}
                                                            >
                                                                <img src={userImages[user.email] || defaultUserImage} alt={user.name} className="user-image" />
                                                                <div className="user-info">
                                                                    <span className="user-name">{user.name}</span>
                                                                    <span className="user-email">{user.email}</span>
                                                                    {user.countMembership > 2 && <span className="user-limit-reached">Nemožne dalšie členstvo pre tohto použivateľa na tejto pozicií</span>}
                                                                    {user.isGraduateEnsurer && <span className="user-limit-reached">Použivateľ je absolvent, nemožno ho vybrať na túto pozíciu</span>}
                                                                    {user.isStudentRepresentative && <span className="user-limit-reached">Použivateľ je zastupca študentov, nemožno ho vybrať na túto pozíciu</span>}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                ))}
                                {errors.profileSubjectEnsurers && <span className="error">{errors.profileSubjectEnsurers}</span>}
                                <div className="button-row">
                                    <div className="step-button-container2">
                                        <button className="step-button" type="button" onClick={handlePreviousStep}>
                                            <FaChevronLeft className='arrow-left' /> Späť
                                        </button>
                                    </div>
                                    <div className="step-button-container">
                                        <button className="step-button" type="button" onClick={handleNextStep}>
                                            Ďalej <FaChevronRight className='arrow-right' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(step === 6 && formData.teacherType === "Combine") && (
                            <div className="form-step">
                                <label>Výber osôb zodpovedných za učiteľský základ: <span style={{ color: "red" }}>*</span></label>
                                {[...Array(3)].map((_, index) => (
                                    <div key={index} className="search-field">
                                        {formData.teacherBasicEnsurers[index].email ? (
                                            <div className="selected-user">
                                                <img src={userImages[formData.teacherBasicEnsurers[index].email] || defaultUserImage} alt={formData.teacherBasicEnsurers[index].email} className="user-image" />
                                                <div className="user-info">
                                                    <span className="user-name">{formData.teacherBasicEnsurers[index].name}</span>
                                                    <span className="user-email">{formData.teacherBasicEnsurers[index].email}</span>
                                                </div>
                                                <button type="button" className="remove-button" onClick={() => handleEnsurerRemove(index, 'teacher')}>X</button>
                                            </div>
                                        ) : (
                                            <>
                                                <input
                                                    type="text"
                                                    placeholder="Vyhľadávanie osoby"
                                                    value={searchInputs[index + 5].name} 
                                                    onChange={(e) => handleTeacherEnsurerChange(e, index)}
                                                    required
                                                />
                                                {searchResultsTeacher[index].length > 0 && (
                                                    <div className="search-results" ref={dropdownRef}>
                                                        {searchResultsTeacher[index].map(user => (
                                                            <div
                                                                key={user.id}
                                                                className={`search-result-item ${user.countMembership > 2 || user.isGraduateEnsurer || user.isStudentRepresentative ? 'disabled' : ''}`}
                                                                 onClick={() => user.countMembership <= 2 && !user.isGraduateEnsurer && !user.isStudentRepresentative && handleEnsurerSelect(user, index, 'teacher')}
                                                            >
                                                                <img src={userImages[user.email] || defaultUserImage} alt={user.name} className="user-image" />
                                                                <div className="user-info">
                                                                    <span className="user-name">{user.name}</span>
                                                                    <span className="user-email">{user.email}</span>
                                                                    {user.countMembership > 2 && <span className="user-limit-reached">Nemožne dalšie členstvo pre tohto použivateľa na tejto pozicií</span>}
                                                                    {user.isGraduateEnsurer && <span className="user-limit-reached">Použivateľ je absolvent, nemožno ho vybrať na túto pozíciu</span>}
                                                                    {user.isStudentRepresentative && <span className="user-limit-reached">Použivateľ je zastupca študentov, nemožno ho vybrať na túto pozíciu</span>}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                ))}
                                {errors.teacherBasicEnsurers && <span className="error">{errors.teacherBasicEnsurers}</span>}
                                <div className="button-row">
                                    <div className="step-button-container2">
                                        <button className="step-button" type="button" onClick={handlePreviousStep}>
                                            <FaChevronLeft className='arrow-left' /> Späť
                                        </button>
                                    </div>
                                    <div className="step-button-container">
                                        <button className="step-button" type="button" onClick={handleNextStep}>Ďalej <FaChevronRight className='arrow-right' /> </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(step === 6 && formData.teacherType !== "Combine") && (
                            <div className="form-step">
                                <label>Výber absolventa študijneho programu: <span style={{ color: "red" }}>*</span></label>
                                <div className="search-field">
                                    {graduateEnsurer.email ? (
                                        <div className="selected-user">
                                            <img src={userImages[graduateEnsurer.email] || defaultUserImage} alt={graduateEnsurer.email} className="user-image" />
                                            <div className="user-info">
                                                <span className="user-name">{graduateEnsurer.name}</span>
                                                <span className="user-email">{graduateEnsurer.email}</span>
                                            </div>
                                            <button type="button" className="remove-button" onClick={handleGraduateRemove}>X</button>
                                        </div>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                placeholder="Vyhľadávanie osoby"
                                                value={searchInputGraduate.name}
                                                onChange={handleGraduateChange}
                                                required
                                            />
                                           {searchResultsGraduate.length > 0 && (
                                                <div className="search-results" ref={dropdownRef}>
                                                    {searchResultsGraduate.map(user => (
                                                        <div
                                                            key={user.id}
                                                            className={`search-result-item ${user.countMembership > 0 ? 'disabled' : ''}`}
                                                            onClick={() => user.countMembership <= 0 && handleGraduateSelect(user)}
                                                        >
                                                            <img src={userImages[user.email] || defaultUserImage} alt={user.name} className="user-image" />
                                                            <div className="user-info">
                                                                <span className="user-name">{user.name}</span>
                                                                <span className="user-email">{user.email}</span>
                                                                {user.countMembership > 0 && <span className="user-limit-reached">Nemožne dalšie členstvo pre tohto použivateľa na tejto pozicií</span>}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {errors.graduateEnsurer && <span className="error">{errors.graduateEnsurer}</span>}
                                <div className="button-row">
                                    <div className="step-button-container2">
                                        <button className="step-button" type="button" onClick={handlePreviousStep}>
                                            <FaChevronLeft className='arrow-left' /> Späť
                                        </button>
                                    </div>
                                    <div className="step-button-container">
                                        <button className="step-button" type="button" onClick={handleNextStep}>Ďalej <FaChevronRight className='arrow-right' /> </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(step === 7 && formData.teacherType === "Combine") && (
                            <div className="form-step">
                                <label>Výber absolventa študijneho programu: <span style={{ color: "red" }}>*</span></label>
                                <div className="search-field">
                                    {graduateEnsurer.email ? (
                                        <div className="selected-user">
                                            <img src={userImages[graduateEnsurer.email] || defaultUserImage} alt={graduateEnsurer.email} className="user-image" />
                                            <div className="user-info">
                                                <span className="user-name">{graduateEnsurer.name}</span>
                                                <span className="user-email">{graduateEnsurer.email}</span>
                                            </div>
                                            <button type="button" className="remove-button" onClick={handleGraduateRemove}>X</button>
                                        </div>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                placeholder="Vyhľadávanie osoby"
                                                value={searchInputGraduate.name}
                                                onChange={handleGraduateChange}
                                                required
                                            />
                                            {searchResultsGraduate.length > 0 && (
                                                <div className="search-results" ref={dropdownRef}>
                                                    {searchResultsGraduate.map(user => (
                                                        <div
                                                            key={user.id}
                                                            className={`search-result-item ${user.countMembership > 0 ? 'disabled' : ''}`}
                                                            onClick={() => user.countMembership <= 0 && handleGraduateSelect(user)}
                                                        >
                                                            <img src={userImages[user.email] || defaultUserImage} alt={user.name} className="user-image" />
                                                            <div className="user-info">
                                                                <span className="user-name">{user.name}</span>
                                                                <span className="user-email">{user.email}</span>
                                                                {user.countMembership > 0 && <span className="user-limit-reached">Nemožne dalšie členstvo pre tohto použivateľa na tejto pozicií</span>}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {errors.graduateEnsurer && <span className="error">{errors.graduateEnsurer}</span>}
                                <div className="button-row">
                                    <div className="step-button-container2">
                                        <button className="step-button" type="button" onClick={handlePreviousStep}>
                                            <FaChevronLeft className='arrow-left' /> Späť
                                        </button>
                                    </div>
                                    <div className="step-button-container">
                                        <button className="step-button" type="button" onClick={handleNextStep}>Ďalej <FaChevronRight className='arrow-right' /> </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(step === 7 && formData.teacherType !== "Combine") && (
                            <div className="form-step">
                                <label>Výber zástupcu združení zamestnávateľov <span style={{ color: "red" }}>*</span></label>
                                <div className="search-field">
                                    {employerEnsurer.email ? (
                                        <div className="selected-user">
                                            <img src={userImages[employerEnsurer.email] || defaultUserImage} alt={employerEnsurer.email} className="user-image" />
                                            <div className="user-info">
                                                <span className="user-name">{employerEnsurer.name}</span>
                                                <span className="user-email">{employerEnsurer.email}</span>
                                            </div>
                                            <button type="button" className="remove-button" onClick={handleEmployerRemove}>X</button>
                                        </div>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                placeholder="Vyhľadávanie osoby"
                                                value={searchInputEmployer.name}
                                                onChange={handleEmployerChange}
                                                required
                                            />
                                            {searchResultsEmployer.length > 0 && (
                                                <div className="search-results" ref={dropdownRef}>
                                                    {searchResultsEmployer.map(user => (
                                                        <div
                                                            key={user.id}
                                                            className={`search-result-item ${user.countMembership > 2 || user.isGraduateEnsurer || user.isStudentRepresentative ? 'disabled' : ''}`}
                                                            onClick={() => user.countMembership <= 2 && !user.isGraduateEnsurer && !user.isStudentRepresentative && handleEmployerSelect(user)}
                                                        >
                                                            <img src={userImages[user.email] || defaultUserImage} alt={user.name} className="user-image" />
                                                            <div className="user-info">
                                                                <span className="user-name">{user.name}</span>
                                                                <span className="user-email">{user.email}</span>
                                                                {user.countMembership > 2 && <span className="user-limit-reached">Nemožné ďalšie členstvo pre tohto používateľa na tejto pozicií</span>}
                                                                {user.isGraduateEnsurer && <span className="user-limit-reached">Použivateľ je absolvent, nemožno ho vybrať na túto pozíciu</span>}
                                                                {user.isStudentRepresentative && <span className="user-limit-reached">Použivateľ je zastupca študentov, nemožno ho vybrať na túto pozíciu</span>}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {errors.employerEnsurer && <span className="error">{errors.employerEnsurer}</span>}
                                <div className="button-row">
                                    <div className="step-button-container2">
                                        <button className="step-button" type="button" onClick={handlePreviousStep}>
                                            <FaChevronLeft className='arrow-left' /> Späť
                                        </button>
                                    </div>
                                    <div className="step-button-container">
                                        <button className="step-button" type="button" onClick={handleNextStep}>Ďalej <FaChevronRight className='arrow-right' /> </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(step === 8 && formData.teacherType === "Combine") && (
                            <div className="form-step">
                                <label>Výber zástupcu združení zamestnávateľov <span style={{ color: "red" }}>*</span></label>
                                <div className="search-field">
                                    {employerEnsurer.email ? (
                                        <div className="selected-user">
                                            <img src={userImages[employerEnsurer.email] || defaultUserImage} alt={employerEnsurer.email} className="user-image" />
                                            <div className="user-info">
                                                <span className="user-name">{employerEnsurer.name}</span>
                                                <span className="user-email">{employerEnsurer.email}</span>
                                            </div>
                                            <button type="button" className="remove-button" onClick={handleEmployerRemove}>X</button>
                                        </div>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                placeholder="Vyhľadávanie osoby"
                                                value={searchInputEmployer.name}
                                                onChange={handleEmployerChange}
                                                required
                                            />
                                             {searchResultsEmployer.length > 0 && (
                                                <div className="search-results" ref={dropdownRef}>
                                                {searchResultsEmployer.map(user => (
                                                    <div
                                                        key={user.id}
                                                        className={`search-result-item ${user.countMembership > 2 || user.isGraduateEnsurer || user.isStudentRepresentative ? 'disabled' : ''}`}
                                                        onClick={() => user.countMembership <= 2 && !user.isGraduateEnsurer && !user.isStudentRepresentative && handleEmployerSelect(user)}
                                                    >
                                                        <img src={userImages[user.email] || defaultUserImage} alt={user.name} className="user-image" />
                                                        <div className="user-info">
                                                            <span className="user-name">{user.name}</span>
                                                            <span className="user-email">{user.email}</span>
                                                            {user.countMembership > 2 && <span className="user-limit-reached">Nemožné ďalšie členstvo pre tohto používateľa na tejto pozicií</span>}
                                                            {user.isGraduateEnsurer && <span className="user-limit-reached">Použivateľ je absolvent, nemožno ho vybrať na túto pozíciu</span>}
                                                            {user.isStudentRepresentative && <span className="user-limit-reached">Použivateľ je zastupca študentov, nemožno ho vybrať na túto pozíciu</span>}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {errors.employerEnsurer && <span className="error">{errors.employerEnsurer}</span>}
                                <div className="button-row">
                                    <div className="step-button-container2">
                                        <button className="step-button" type="button" onClick={handlePreviousStep}>
                                            <FaChevronLeft className='arrow-left' /> Späť
                                        </button>
                                    </div>
                                    <div className="step-button-container">
                                        <button className="step-button" type="button" onClick={handleNextStep}>Ďalej <FaChevronRight className='arrow-right' /> </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(step === 8 && formData.teacherType !== "Combine") && (
                            <div className="form-step">
                                <label>Výber zástupcu študentov: <span style={{ color: "red" }}>*</span></label>
                                <div className="search-field">
                                    {studentRepresentative.email ? (
                                        <div className="selected-user">
                                            <img src={userImages[studentRepresentative.email] || defaultUserImage} alt={studentRepresentative.email} className="user-image" />
                                            <div className="user-info">
                                                <span className="user-name">{studentRepresentative.name}</span>
                                                <span className="user-email">{studentRepresentative.email}</span>
                                            </div>
                                            <button type="button" className="remove-button" onClick={handleStudentRepresentativeRemove}>X</button>
                                        </div>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                placeholder="Vyhľadávanie osoby"
                                                value={searchInputStudent.name}
                                                onChange={handleStudentRepresentativeChange}
                                                required
                                            />
                                            {searchResultsStudent.length > 0 && (
                                                <div className="search-results" ref={dropdownRef}>
                                                    {searchResultsStudent.map(user => (
                                                        <div
                                                            key={user.id}
                                                            className={`search-result-item ${user.countMembership > 0 ? 'disabled' : ''}`}
                                                            onClick={() => user.countMembership <= 0 && handleStudentRepresentativeSelect(user)}
                                                        >
                                                            <img src={userImages[user.email] || defaultUserImage} alt={user.name} className="user-image" />
                                                            <div className="user-info">
                                                                <span className="user-name">{user.name}</span>
                                                                <span className="user-email">{user.email}</span>
                                                                {user.countMembership > 0 && <span className="user-limit-reached">Nemožne dalšie členstvo pre tohto použivateľa na tejto pozicií</span>}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {errors.studentRepresentative && <span className="error">{errors.studentRepresentative}</span>}
                                <div className="button-row">
                                    <div className="step-button-container2">
                                        <button className="step-button" type="button" onClick={handlePreviousStep}>
                                            <FaChevronLeft className='arrow-left' /> Späť
                                        </button>
                                    </div>
                                    <div className="step-button-container">
                                        <button className="step-button" type="button" onClick={handleNextStep}>Ďalej <FaChevronRight className='arrow-right' /> </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(step === 9 && formData.teacherType === "Combine") && (
                            <div className="form-step">
                                <label>Výber zástupcu študentov: <span style={{ color: "red" }}>*</span></label>
                                <div className="search-field">
                                    {studentRepresentative.email ? (
                                        <div className="selected-user">
                                            <img src={userImages[studentRepresentative.email] || defaultUserImage} alt={studentRepresentative.email} className="user-image" />
                                            <div className="user-info">
                                                <span className="user-name">{studentRepresentative.name}</span>
                                                <span className="user-email">{studentRepresentative.email}</span>
                                            </div>
                                            <button type="button" className="remove-button" onClick={handleStudentRepresentativeRemove}>X</button>
                                        </div>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                placeholder="Vyhľadávanie osoby"
                                                value={searchInputStudent.name}
                                                onChange={handleStudentRepresentativeChange}
                                                required
                                            />
                                            {searchResultsStudent.length > 0 && (
                                                <div className="search-results" ref={dropdownRef}>
                                                    {searchResultsStudent.map(user => (
                                                        <div
                                                            key={user.id}
                                                            className={`search-result-item ${user.countMembership > 0 ? 'disabled' : ''}`}
                                                            onClick={() => user.countMembership <= 0 && handleStudentRepresentativeSelect(user)}
                                                        >
                                                            <img src={userImages[user.email] || defaultUserImage} alt={user.name} className="user-image" />
                                                            <div className="user-info">
                                                                <span className="user-name">{user.name}</span>
                                                                <span className="user-email">{user.email}</span>
                                                                {user.countMembership > 0 && <span className="user-limit-reached">Nemožne dalšie členstvo pre tohto použivateľa na tejto pozicií</span>}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {errors.studentRepresentative && <span className="error">{errors.studentRepresentative}</span>}
                                <div className="button-row">
                                    <div className="step-button-container2">
                                        <button className="step-button" type="button" onClick={handlePreviousStep}>
                                            <FaChevronLeft className='arrow-left' /> Späť
                                        </button>
                                    </div>
                                    <div className="step-button-container">
                                        <button className="step-button" type="button" onClick={handleNextStep}>Ďalej <FaChevronRight className='arrow-right' /> </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(step === 9 && formData.teacherType !== "Combine") && (
                            <div className="form-step">
                                <label>Výber garanta: <span style={{ color: "red" }}>*</span></label>
                                <div className="search-field">
                                    {guarantor.email ? (
                                        <div className="selected-user">
                                            <img src={userImages[guarantor.email] || defaultUserImage} alt={guarantor.email} className="user-image" />
                                            <div className="user-info">
                                                <span className="user-name">{guarantor.name}</span>
                                                <span className="user-email">{guarantor.email}</span>
                                            </div>
                                            <button type="button" className="remove-button" onClick={handleGuarantorRemove}>X</button>
                                        </div>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                placeholder="Vyhľadávanie osoby"
                                                value={searchInputGuarantor.name}
                                                onChange={handleGuarantorChange}
                                                required
                                            />
                                            {searchResultsGuarantor.length > 0 && (
                                                 <div className="search-results" ref={dropdownRef}>
                                                 {searchResultsGuarantor.map(user => (
                                                     <div
                                                         key={user.id}
                                                         className={`search-result-item ${user.countMembership > 2 || user.isGraduateEnsurer || user.isStudentRepresentative ? 'disabled' : ''}`}
                                                         onClick={() => user.countMembership <= 2 && !user.isGraduateEnsurer && !user.isStudentRepresentative && handleGuarantorSelect(user)}
                                                     >
                                                         <img src={userImages[user.email] || defaultUserImage} alt={user.name} className="user-image" />
                                                         <div className="user-info">
                                                             <span className="user-name">{user.name}</span>
                                                             <span className="user-email">{user.email}</span>
                                                             {user.countMembership > 2 && <span className="user-limit-reached">Nemožne dalšie členstvo pre tohto použivateľa na tejto pozicií</span>}
                                                             {user.isGraduateEnsurer && <span className="user-limit-reached">Použivateľ je absolvent, nemožno ho vybrať na túto pozíciu</span>}
                                                             {user.isStudentRepresentative && <span className="user-limit-reached">Použivateľ je zastupca študentov, nemožno ho vybrať na túto pozíciu</span>}
                                                         </div>
                                                     </div>
                                                 ))}
                                             </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {errors.guarantor && <span className="error">{errors.guarantor}</span>}
                                <div className="button-row">
                                    <div className="step-button-container2">
                                        <button className="step-button" type="button" onClick={handlePreviousStep}>
                                            <FaChevronLeft className='arrow-left' /> Späť
                                        </button>
                                    </div>
                                    <div className="step-button-container">
                                        <button className="step-button" type="button" onClick={handleNextStep}>Ďalej <FaChevronRight className='arrow-right' /> </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(step === 10 && formData.teacherType === "Combine") && (
                            <div className="form-step">
                                <label>Výber garanta: <span style={{ color: "red" }}>*</span></label>
                                <div className="search-field">
                                    {guarantor.email ? (
                                        <div className="selected-user">
                                            <img src={userImages[guarantor.email] || defaultUserImage} alt={guarantor.email} className="user-image" />
                                            <div className="user-info">
                                                <span className="user-name">{guarantor.name}</span>
                                                <span className="user-email">{guarantor.email}</span>
                                            </div>
                                            <button type="button" className="remove-button" onClick={handleGuarantorRemove}>X</button>
                                        </div>
                                    ) : (
                                        <>
                                            <input
                                                type="text"
                                                placeholder="Vyhľadávanie osoby"
                                                value={searchInputGuarantor.name}
                                                onChange={handleGuarantorChange}
                                                required
                                            />
                                            {searchResultsGuarantor.length > 0 && (
                                               <div className="search-results" ref={dropdownRef}>
                                                    {searchResultsGuarantor.map(user => (
                                                        <div
                                                            key={user.id}
                                                            className={`search-result-item ${user.countMembership > 2 || user.isGraduateEnsurer || user.isStudentRepresentative ? 'disabled' : ''}`}
                                                            onClick={() => user.countMembership <= 2 && !user.isGraduateEnsurer && !user.isStudentRepresentative && handleGuarantorSelect(user)}
                                                        >
                                                            <img src={userImages[user.email] || defaultUserImage} alt={user.name} className="user-image" />
                                                            <div className="user-info">
                                                                <span className="user-name">{user.name}</span>
                                                                <span className="user-email">{user.email}</span>
                                                                {user.countMembership > 2 && <span className="user-limit-reached">Nemožne dalšie členstvo pre tohto použivateľa na tejto pozicií</span>}
                                                                {user.isGraduateEnsurer && <span className="user-limit-reached">Použivateľ je absolvent, nemožno ho vybrať na túto pozíciu</span>}
                                                                {user.isStudentRepresentative && <span className="user-limit-reached">Použivateľ je zastupca študentov, nemožno ho vybrať na túto pozíciu</span>}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                                {errors.guarantor && <span className="error">{errors.guarantor}</span>}
                                <div className="button-row">
                                    <div className="step-button-container2">
                                        <button className="step-button" type="button" onClick={handlePreviousStep}>
                                            <FaChevronLeft className='arrow-left' /> Späť
                                        </button>
                                    </div>
                                    <div className="step-button-container">
                                        <button className="step-button" type="button" onClick={handleNextStep}>Ďalej <FaChevronRight className='arrow-right' /> </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        {(step === 10 && formData.teacherType !== "Combine") && (
                            <div className="form-step">
                            <h3 className='summary-header'>Zhrnutie</h3>
                            <div className="summary-item">
                                <strong>Názov programu:</strong> {formData.name}
                            </div>
                            <div className="summary-item">
                                <strong>Typ študijného programu:</strong> {formData.programType === 'facultyProgram' ? 'Fakultný študijný program' : 'Celouniverzitný študijný program'}
                            </div>
                            <div className="summary-item">
                                <strong>Typ členenia členov rady:</strong> {formData.teacherType === 'Combine' ? 'Kombinovaný učiteľský program' : 'Klasický učiteľský program'}
                            </div>
                            <div className="summary-item">
                                {formData.programType === "facultyProgram" && <strong>Pracovisko: {formData.facultyName}</strong>}
                                {formData.programType !== "facultyProgram" && <strong>Pracovisko: Žilinská univerzita v Žiline</strong>}
                            </div>
                            <div className="summary-item">
                                <strong>Osoby zabezpečujúce profilové predmety:</strong>
                                {formData.profileSubjectEnsurers
                                    .slice(0, formData.teacherType === 'Combine' ? 2 : 4)
                                    .map((ensurer, index) => (
                                        <div key={index} className="summary-member">
                                            <img src={userImages[ensurer.email] || defaultUserImage} alt={ensurer.name} className="summary-member-image" />
                                            {ensurer.name} ({ensurer.email})
                                        </div>
                                ))}
                            </div>
                            {formData.teacherType === "Combine" && (
                                <div className="summary-item">
                                    <strong>Osoby zodpovedné za učiteľský základ:</strong>
                                        {formData.teacherBasicEnsurers.map((ensurer, index) => (
                                            <div key={index} className="summary-member">
                                                <img src={userImages[ensurer.email] || defaultUserImage} alt={ensurer.name} className="summary-member-image" />
                                                {ensurer.name} ({ensurer.email})
                                            </div>
                                        ))}
                                </div>
                            )}
                            {graduateEnsurer.email && (
                                <div className="summary-item">
                                    <strong>Absolvent študijného programu:</strong> 
                                    <div className="summary-member">
                                        <img src={userImages[graduateEnsurer.email] || defaultUserImage} alt={graduateEnsurer.name} className="summary-member-image" />
                                        {graduateEnsurer.name} ({graduateEnsurer.email})
                                    </div>
                                </div>
                            )}
                            {employerEnsurer.email && (
                                <div className="summary-item">
                                    <strong>Zástupca združení zamestnávateľov:</strong> 
                                    <div className="summary-member">
                                        <img src={userImages[employerEnsurer.email] || defaultUserImage} alt={employerEnsurer.name} className="summary-member-image" />
                                        {employerEnsurer.name} ({employerEnsurer.email})
                                    </div>
                                </div>
                            )}
                            {studentRepresentative.email && (
                                <div className="summary-item">
                                    <strong>Zástupca študentov:</strong> 
                                    <div className="summary-member">
                                        <img src={userImages[studentRepresentative.email] || defaultUserImage} alt={studentRepresentative.name} className="summary-member-image" />
                                        {studentRepresentative.name} ({studentRepresentative.email})
                                    </div>
                                </div>
                            )}
                            {guarantor.email && (
                                <div className="summary-item">
                                    <strong>Garant:</strong> 
                                    <div className="summary-member">
                                        <img src={userImages[guarantor.email] || defaultUserImage} alt={guarantor.name} className="summary-member-image" />
                                        {guarantor.name} ({guarantor.email})
                                    </div>
                                </div>
                            )}
                            <div className="button-row">
                                <div className="step-button-container2">
                                    <button className="step-button" type="button" onClick={handlePreviousStep}>
                                        <FaChevronLeft className='arrow-left' /> Späť
                                    </button>
                                </div>
                                <div className="step-button-container">
                                    <button className="step-button" type="submit">
                                        Vytvoriť radu
                                    </button>
                                </div>
                            </div>
                        </div>
                        )}

                        {(step === 11 && formData.teacherType === "Combine") && (
                            <div className="form-step">
                            <h3 className='summary-header'>Zhrnutie</h3>
                            <div className="summary-item">
                                <strong>Názov programu:</strong> {formData.name}
                            </div>
                            <div className="summary-item">
                                <strong>Typ študijného programu:</strong> {formData.programType === 'facultyProgram' ? 'Fakultný študijný program' : 'Celouniverzitný študijný program'}
                            </div>
                            <div className="summary-item">
                                <strong>Typ členenia členov rady:</strong> {formData.teacherType === 'Combine' ? 'Kombinovaný učiteľský program' : 'Klasický učiteľský program'}
                            </div>
                            <div className="summary-item">
                                {formData.programType === "facultyProgram" && <strong>Pracovisko: {formData.facultyName}</strong>}
                                {formData.programType !== "facultyProgram" && <strong>Pracovisko: Žilinská univerzita v Žiline</strong>}
                            </div>
                            <div className="summary-item">
                                <strong>Osoby zabezpečujúce profilové predmety:</strong>
                                {formData.profileSubjectEnsurers
                                    .slice(0, formData.teacherType === 'Combine' ? 2 : 4)
                                    .map((ensurer, index) => (
                                        <div key={index} className="summary-member">
                                            <img src={userImages[ensurer.email] || defaultUserImage} alt={ensurer.name} className="summary-member-image" />
                                            {ensurer.name} ({ensurer.email})
                                        </div>
                                ))}
                            </div>
                            {formData.teacherType === "Combine" && (
                                <div className="summary-item">
                                    <strong>Osoby zodpovedné za učiteľský základ:</strong>
                                        {formData.teacherBasicEnsurers.map((ensurer, index) => (
                                            <div key={index} className="summary-member">
                                                <img src={userImages[ensurer.email] || defaultUserImage} alt={ensurer.name} className="summary-member-image" />
                                                {ensurer.name} ({ensurer.email})
                                            </div>
                                        ))}
                                </div>
                            )}
                            {graduateEnsurer.email && (
                                <div className="summary-item">
                                    <strong>Absolvent študijného programu:</strong> 
                                    <div className="summary-member">
                                        <img src={userImages[graduateEnsurer.email] || defaultUserImage} alt={graduateEnsurer.name} className="summary-member-image" />
                                        {graduateEnsurer.name} ({graduateEnsurer.email})
                                    </div>
                                </div>
                            )}
                            {employerEnsurer.email && (
                                <div className="summary-item">
                                    <strong>Zástupca združení zamestnávateľov:</strong> 
                                    <div className="summary-member">
                                        <img src={userImages[employerEnsurer.email] || defaultUserImage} alt={employerEnsurer.name} className="summary-member-image" />
                                        {employerEnsurer.name} ({employerEnsurer.email})
                                    </div>
                                </div>
                            )}
                            {studentRepresentative.email && (
                                <div className="summary-item">
                                    <strong>Zástupca študentov:</strong> 
                                    <div className="summary-member">
                                        <img src={userImages[studentRepresentative.email] || defaultUserImage} alt={studentRepresentative.name} className="summary-member-image" />
                                        {studentRepresentative.name} ({studentRepresentative.email})
                                    </div>
                                </div>
                            )}
                            {guarantor.email && (
                                <div className="summary-item">
                                    <strong>Garant:</strong> 
                                    <div className="summary-member">
                                        <img src={userImages[guarantor.email] || defaultUserImage} alt={guarantor.name} className="summary-member-image" />
                                        {guarantor.name} ({guarantor.email})
                                    </div>
                                </div>
                            )}
                            <div className="button-row">
                                <div className="step-button-container2">
                                    <button className="step-button" type="button" onClick={handlePreviousStep}>
                                        <FaChevronLeft className='arrow-left' /> Späť
                                    </button>
                                </div>
                                <div className="step-button-container">
                                    <button className="step-button" type="submit">
                                        Vytvoriť radu
                                    </button>
                                </div>
                            </div>
                        </div>
                        )}
                    </form>

                    <div className="step-indicator" style={{marginBottom: "10px"}}>
                        {lastStep && <span className='step-text'>Zhrnutie</span>}
                        {!lastStep && <span className='step-text'>Krok {step} z {steps}</span>}
                    </div>
                </div>

                <div className='progress-div'>
                    <div className="progress-container">
                        <div className="progress-bar">
                            <div className="progress-bar-fill" style={{ width: getProgressWidth() }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudyProgramForm;
