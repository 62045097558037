import React, { useState, useEffect, useContext } from 'react';
import { ProgramContext } from '../../Contexts/ProgramContext';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import MemberManagement from '../../Components/MemberManagment/MemberManagment';

const MemberManagementPage = () => {
    return (
        <div>
           <div>
            <Navbar></Navbar>
           </div>
           <div style={{  marginTop: "140px" }}>
                <MemberManagement></MemberManagement>
           </div>
           <div style={{marginTop: "180px"}}>
            <Footer></Footer>
           </div>
        </div>
    )
}


export default MemberManagementPage;