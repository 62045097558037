import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import './PollResults.css';
import { useParams } from 'react-router';
import CryptoJS from 'crypto-js';
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const PollResults = () => {
    const { pollId } = useParams();
    const [pollResults, setPollResults] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const customDecode = (encoded) => {
        const decodedBase64 = atob(encoded);
        const strippedPrefix = decodedBase64.replace('customPrefix', '');
        const reversedBase64 = strippedPrefix.split('').reverse().join('');
        const parsedWordArray = CryptoJS.enc.Base64.parse(reversedBase64);
        const decoded = CryptoJS.enc.Utf8.stringify(parsedWordArray);
        return decoded;
    };

    const decodedPollId = customDecode(pollId);

    useEffect(() => {
        const fetchPollResults = async () => {
            setLoading(true);
            const token = localStorage.getItem('token');

            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/polls/${decodedPollId}/results`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                    setPollResults(data);
                } else {
                    setMessage('Problém s načítaním výsledkov hlasovania');
                }
            } catch (error) {
                setMessage('Problém s načítaním výsledkov hlasovania');
            } finally {
                setLoading(false);
            }
        };

        fetchPollResults();
    }, [pollId]);

    const renderBarChart = (options) => {
        const labels = options.map(option => option.option);
        const data = options.map(option => option.voteCount);

        const chartData = {
            labels,
            datasets: [
                {
                    label: 'Počet hlasov',
                    data,
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1
                }
            ]
        };

        const chartOptions = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top'
                },
                title: {
                    display: true,
                    text: 'Výsledky hlasovania'
                }
            }
        };

        return <Bar data={chartData} options={chartOptions} style={{ maxHeight: "350px" }} />;
    };

    const getVoteText = (count) => {
        if (count === 1) return "hlas";
        if (count >= 2 && count <= 4) return "hlasy";
        return "hlasov";
    };

    return (
        <div className="poll-results-container">
            {loading && <div className="loading-spinner-component"><div className="spinner"></div></div>}
            {message && <p className="message">{message}</p>}
            {pollResults && (
                <>
                    <h2> Výsledky hlasovania: {pollResults.pollName}</h2>
                    {pollResults.questions.map((question, qIndex) => (
                        <div key={qIndex} className="question-block2">
                            <h3>{question.question}</h3>
                            {question.options.map((option, oIndex) => (
                                <div key={oIndex} className="option-result">
                                    <span className="option-text">{option.option}</span>
                                    <span className="option-votes">{option.voteCount} {getVoteText(option.voteCount)}</span>
                                </div>
                            ))}
                            {renderBarChart(question.options)}
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default PollResults;
