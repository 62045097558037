import React, { useContext, useState, useEffect } from 'react';
import unizaLogo from '../../assets/images/uniza-logo.png'; 
import { Link, Navigate,  useLocation } from 'react-router-dom';
import "../Navbar/Navbar.css"


const HelpNavbar = () => {
    
   return (
    <div className='row-container'>
        <nav className="navbar navbar-expand-lg navbar-light " id="navbar">
            {/* Left part */}
            <div className='navbar-nav mr-auto'> 
                <Link to="https://uniza.sk/" className="navbar-brand" style={ {marginLeft: "-5px"} }>
                    <img className='logo'
                    src={unizaLogo}
                    height="80"
                    width="132"
                    alt="Žilinská Univerzita v Žiline"
                    />
                <span className='text' style={{ color: 'white'}}>Žilinská Univerzita v Žiline</span>
                </Link>
            </div>
            
            {/* Middle part */}
            <div className="mx-auto" id="navbarNav">
            </div>
            <div className="ml-auto" id="navbarNav">
                <h2 style={{ color: "white", fontSize: "17px", marginRight: "35px", marginTop: "15px", fontFamily: "Arial, Helvetica, sans-serif" }}>
                    REGISTRÁCIA NA STUDY PATH
                </h2>
            </div>

        </nav>
    </div>
  );
};

export default HelpNavbar;