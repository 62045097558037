import React, { useState, useEffect, useContext, useRef } from 'react';
import { ProgramContext } from '../../Contexts/ProgramContext';
import image from '../../assets/images/codeNotValid.png';
import './AddDocumentMeeting.css';
import MessageModal from '../MessageModal/MessageModal';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import image2 from '../../assets/images/meeting.png';

const AddDocumentMeeting = () => {
    const { selectedProgram } = useContext(ProgramContext);
    const [meetings, setMeetings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredMeetings, setFilteredMeetings] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState({});
    const [existingFiles, setExistingFiles] = useState({});
    const [isVisible, setIsVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [visibleDocuments, setVisibleDocuments] = useState({});
    const [documentHeights, setDocumentHeights] = useState({});
    const documentRefs = useRef({});

    useEffect(() => {
        const fetchMeetings = async () => {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            const selectedProgramId = localStorage.getItem('selectedProgramId');

            if (!token || !selectedProgramId) {
                setError('Prístup zamietnutý: nezvolené ID programu alebo nemáte povolenie pridávať dokumenty k zasadnutiam v tejto rade.');
                setIsLoading(false);
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getMeetingsByProgram`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ rspId: selectedProgramId, token }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setMeetings(data);
                    setFilteredMeetings(data);

                    data.forEach(meeting => {
                        fetchExistingFiles(meeting.idMeeting, token);
                        setVisibleDocuments(prevVisible => ({
                            ...prevVisible,
                            [meeting.idMeeting]: false
                        }));
                        setDocumentHeights(prevHeights => ({
                            ...prevHeights,
                            [meeting.idMeeting]: '0px'
                        }));
                    });
                } else {
                    setError('Problém získať zasadnutia');
                }
            } catch (error) {
                setError('Problém získať zasadnutia');
            } finally {
                setIsLoading(false);
            }
        };

        fetchMeetings();
    }, [selectedProgram]);

    const fetchExistingFiles = async (meetingId, token) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getFilesByMeetingId`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ meetingId, token }),
            });

            if (response.ok) {
                const data = await response.json();
                setExistingFiles(prevFiles => ({ ...prevFiles, [meetingId]: data }));
            } else {
                setError('Problém načítať súbory');
            }
        } catch (error) {
            setError('Problém načítať súbory');
        }
    };

    if (localStorage.getItem('token')) {
        const userData = JSON.parse(localStorage.getItem("user"));
        if (userData.admin !== 1 && localStorage.getItem('role') === null && userData.rector !== 1 && !(userData.idFacutly.length > 0)) {
            return (
                <div style={{ marginTop: "-60px" }}>
                    <div className="centered-container">
                        <h2 className="centered-text">Nemáte povolenie pridávať dokumenty k zasadnutiam</h2>
                    </div>
                    <div className="centered-container-img">
                        <img src={image} alt="Invalid code illustration" />
                    </div>
                </div>
            );
        }
    }

    const handleAddDocument = async (meetingId, index) => {
        if (!selectedFiles[meetingId] || selectedFiles[meetingId].length === 0) {
            setError(`Neboli zvolene žiadne súbory k zasadnutiu ${index + 1}`);
            return;
        }

        setIsLoading(true);
        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem("user"));
        const formData = new FormData();
        selectedFiles[meetingId].forEach(file => {
            formData.append('files', file);
        });
        formData.append('meetingId', meetingId);
        formData.append('token', token);
        formData.append('authorFile', userData.name);

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/addDocumentsToMeeting`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                setMessage(`Dokumenty k zasadnutiu ${index + 1} boli úspešne pridané`);
                setIsVisible(true);
                setSelectedFiles(prevFiles => ({ ...prevFiles, [meetingId]: [] }));
                fetchExistingFiles(meetingId, token); 
                setVisibleDocuments(prevVisible => ({
                    ...prevVisible,
                    [meetingId]: !prevVisible[meetingId]
                }));
                setVisibleDocuments(prevVisible => ({
                    ...prevVisible,
                    [meetingId]: !prevVisible[meetingId]
                }));
            } else {
                setError('Nastal problém pridať dokumenty');
            }
        } catch (error) {
            setError('Nastal problém pridať dokumenty');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
        setFilteredMeetings(meetings.filter(meeting => 
            meeting.place.toLowerCase().includes(term) || 
            new Date(meeting.dateStart).toLocaleString('sk-SK').replace(/\s/g, '').includes(term.replace(/\s/g, ''))
        ));
    };

    const handleFileChange = (e, meetingId) => {
        const newFiles = Array.from(e.target.files);
        setSelectedFiles(prevFiles => ({
            ...prevFiles,
            [meetingId]: prevFiles[meetingId] ? [...prevFiles[meetingId], ...newFiles] : newFiles
        }));
    };

    const handleRemoveFile = (meetingId, index) => {
        setSelectedFiles(prevFiles => ({
            ...prevFiles,
            [meetingId]: prevFiles[meetingId].filter((_, i) => i !== index)
        }));
    };

    const handleCloseModal = () => {
        setIsVisible(false);
    }

    const toggleDocumentsVisibility = (meetingId) => {
        const element = documentRefs.current[meetingId];
        const isVisible = visibleDocuments[meetingId];
    
        const numberOfDocuments = existingFiles[meetingId]?.length || 0;
        
        const transitionDuration = isVisible ? '1.0s' : '1.0s';
        
        element.style.transition = `max-height ${transitionDuration} ease`;
    
        setVisibleDocuments(prevVisible => ({
            ...prevVisible,
            [meetingId]: !prevVisible[meetingId]
        }));
    
        setTimeout(() => {
            if (isVisible) {
                var individualDelay = 0.2;
                if(numberOfDocuments <= 2){
                    individualDelay = 0.8; 
                } 
                const totalDelay = numberOfDocuments * individualDelay;
    
                element.style.transition = `max-height ${totalDelay}s ease`;
                setDocumentHeights(prevHeights => ({
                    ...prevHeights,
                    [meetingId]: '0px'
                }));
            } else {
                const scrollHeight = element?.scrollHeight;
                var individualDelay = 0.4;
                if(numberOfDocuments <= 2){
                    individualDelay = 0.8; 
                } 
                const totalDelay = numberOfDocuments * individualDelay;
    
                element.style.transition = `max-height ${totalDelay}s ease`;
    
                setDocumentHeights(prevHeights => ({
                    ...prevHeights,
                    [meetingId]: `${scrollHeight}px`
                }));
            }
        }, 10);
    };

    const handleFileClick = (fileId) => {
        const token = localStorage.getItem('token');
        window.open(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getFile/${fileId}/${token}`, '_blank');
    };

    if (meetings.length === 0) {
        return (
            <div style={{ marginTop: "-60px" }}>
                <div className="centered-container">
                    <h2 className="centered-text">Zatiaľ žiadne zasadnutia rady</h2>
                </div>
                <div className="centered-container-img">
                    <img src={image2} alt="No meetings available" />
                </div>
            </div>
        );
    }

    return (
        <div className="add-document-wrapper">
            <div className="add-document-container">
                <MessageModal isVisible={isVisible} buttonFunction={handleCloseModal} message={message}></MessageModal>
                <h2 className='text2'>Pridať Dokumenty k Zasadnutiu</h2>
                {isLoading && <div className="loading-spinner-component"><div className="spinner"></div></div>}
                {error && <p className="error-message">{error}</p>}
                <input
                    type="text"
                    placeholder="Hľadať podľa dátumu alebo miesta..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="search-input"
                />
                <ul className="meeting-list">
                    {filteredMeetings.map(meeting => {
                        const index = meetings.findIndex(m => m.idMeeting === meeting.idMeeting);
                        return (
                            <li key={meeting.idMeeting} className="meeting-item">
                                <h3 style={{ fontSize: "25px" }}>Zasadnutie {index + 1}</h3>
                                <div>
                                    <strong>Program:</strong> {meeting.programName}
                                </div>
                                <div>
                                    <strong>Miesto stretnutia:</strong> {meeting.place}
                                </div>
                                <div>
                                    <strong>Dátum konania:</strong> {new Date(meeting.dateStart).toLocaleString('sk-SK')}
                                </div>
                                {existingFiles[meeting.idMeeting]?.length > 0 && (
                                    <div className="existing-files-wrapper">
                                        <div className="toggle-container" onClick={() => toggleDocumentsVisibility(meeting.idMeeting)}>
                                            <span>{visibleDocuments[meeting.idMeeting] ? 'Skryť existujúce dokumenty' : 'Zobraziť existujúce dokumenty'}</span>
                                            {visibleDocuments[meeting.idMeeting] ? <FaChevronUp className='chevron' /> : <FaChevronDown className='chevron' />}
                                        </div>
                                        <div
                                            className="existing-files-container"
                                            ref={el => documentRefs.current[meeting.idMeeting] = el}
                                            style={{ maxHeight: documentHeights[meeting.idMeeting], overflowY: 'auto', transition: 'max-height 0.6s ease' }}
                                        >
                                            <ul className="existing-files-list">
                                                {existingFiles[meeting.idMeeting].map((file, fileIndex) => (
                                                    <li key={fileIndex} className="existing-file-item">
                                                        <div className="file-info2">
                                                            <i className="fas fa-file file-icon"></i>
                                                            <span className="file-name" onClick={() => handleFileClick(file.idFile)}>{file.fileName}</span>
                                                            <span className="file-author">Autor: {file.fileAuthor}</span>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                <div className="file-input-container">
                                    <label className="file-input-label">
                                        {selectedFiles[meeting.idMeeting]?.length > 0 ? 'Pridať ďalšie súbory' : 'Vybrať súbor(y)'}
                                        <input type="file" multiple onChange={(e) => handleFileChange(e, meeting.idMeeting)} className="file-input" />
                                    </label>
                                </div>
                                {selectedFiles[meeting.idMeeting]?.length > 0 && (
                                    <ul className="selected-files-list">
                                        {selectedFiles[meeting.idMeeting].map((file, fileIndex) => (
                                            <li key={fileIndex} className="selected-file-item">
                                                {file.name}
                                                <button
                                                    className="remove-file-button"
                                                    onClick={() => handleRemoveFile(meeting.idMeeting, fileIndex)}
                                                >
                                                    X
                                                </button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {selectedFiles[meeting.idMeeting]?.length > 0 ? (
                                    <button className="add-button" onClick={() => handleAddDocument(meeting.idMeeting, index)}>
                                        {selectedFiles[meeting.idMeeting]?.length > 1 ? "Pridať dokumenty" : "Pridať dokument"}</button>
                                ) : (
                                    <button className="add-button-empty" onClick={() => handleAddDocument(meeting.idMeeting, index)}>Pridať dokument</button>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default AddDocumentMeeting;
