import React, { useState, useEffect, useRef, useContext } from 'react';
import { FaChevronDown, FaChevronUp, FaUser, FaEnvelope, FaUserShield, FaUniversity, FaUserSlash, FaInfoCircle, FaQuestionCircle } from 'react-icons/fa';
import "./UserMembershipInfo.css"; 
import defaultUserImage from '../../assets/images/profile.png';
import { ProgramContext } from '../../Contexts/ProgramContext';
import RightsModal from '../RightsModal/RightsModal';

const UserMembershipInfo = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { selectedProgram } = useContext(ProgramContext);
    const containerRef = useRef(null);
    const [contentHeight, setContentHeight] = useState('auto');
    const [pictureUrl, setPictureUrl] = useState(null);
    const userData = JSON.parse(localStorage.getItem('user'));

    const roleLabels = {
        1: 'Osoba zabezpečujúca profilové predmety',
        2: 'Osoba zodpovedná za učiteľský základ',
        3: 'Absolvent študijného programu',
        4: 'Zástupca združení zamestnávateľov',
        5: 'Zástupca študentov',
        6: 'Garant'
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        const fetchUserInfo = async () => {
            const token = localStorage.getItem('token');

            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/user/getUserMemberInfo`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ programId: selectedProgram, token })
                });

                if (response.ok) {
                    const data = await response.json();
                    setUserInfo(data);
                    if (data.picture) {
                        const isPng = data.picture.toString('hex', 0, 4) === '89504e47';
                        const isJpeg = data.picture.toString('hex', 0, 2) === 'ffd8';
                        const isWebp = data.picture.toString('hex', 0, 4) === '52494646' && data.picture.toString('hex', 8, 12) === '57454250';

                        let mimeType = 'image/png';
                        if (isJpeg) mimeType = 'image/jpeg';
                        if (isWebp) mimeType = 'image/webp';

                        const blob = new Blob([new Uint8Array(data.picture.data)], { type: mimeType });
                        const url = URL.createObjectURL(blob);
                        setPictureUrl(url);

                        return () => {
                            URL.revokeObjectURL(url);
                        };
                    }
                    setIsVisible(true); // Make the content visible after fetching data
                    setTimeout(() => {
                        setContentHeight(`${containerRef.current.scrollHeight}px`);
                    }, 0);
                } else {
                    
                }
            } catch (error) {
                
            }
        };

        if (selectedProgram) {
            fetchUserInfo();
        }

    }, [selectedProgram]);

    useEffect(() => {
        // Auto-expand content when userInfo is set
        if (userInfo) {
            setContentHeight(`${containerRef.current.scrollHeight}px`);
            setIsVisible(true);
        }
    }, [userInfo]);

    const toggleVisibility = () => {
        if (isVisible) {
            setContentHeight(`${containerRef.current.scrollHeight}px`);
            setTimeout(() => {
                setContentHeight('0px');
            }, 10);
        } else {
            setContentHeight(`${containerRef.current.scrollHeight}px`);
            setTimeout(() => {
                setContentHeight('auto');
            }, 500);
        }
        setIsVisible(!isVisible);
    };

    return (
        <div className="user-membership-info-container">
            <div className="row header2">
                <div className='col-lg-9 col-md-9 col-sm-9'>
                    <h2 className="title" style={{textAlign: "left"}}>Informácie o členstve</h2>
                </div>
                <div id='arrow' className='col-lg-3 col-md-3 col-sm-3'>
                    {isVisible ? <FaChevronUp className="toggle-icon" onClick={toggleVisibility} /> : <FaChevronDown className="toggle-icon" onClick={toggleVisibility} />}
                </div>
            </div>
            <hr id="line3"/>
            <div ref={containerRef} className={`content ${isVisible ? 'open' : 'closed'}`}
                style={{ maxHeight: contentHeight, overflow: 'hidden', transition: 'max-height 0.8s ease-in-out' }}>
                {userInfo ? (
                    <div className="info-container">
                        <div className='info-container-help'>
                            <div className="user-label">  <span style={{ marginLeft: "3px" }}>Údaje o použivateľovy a členstve</span></div> {/* Label */}
                            <div className="user-rights">
                                <span className="rights-text"  onClick={openModal}>Práva používateľa</span>
                                <FaQuestionCircle className="toggle-icon" onClick={openModal} />
                            </div>
                        </div>
                       <div className="user-details2">
                            <img
                                src={pictureUrl || defaultUserImage}
                                alt={userInfo.name}
                                className="profile-image"
                            />
                            <div className="user-info-container">
                                <div className="user-info">
                                    <p><strong>Meno: </strong> <span style={{marginLeft: "5px"}}> {userInfo.name} </span></p>
                                    <p className="email3" style={{color: "#919191"}}><strong>Email: </strong> <span style={{marginLeft: "5px"}}> {userInfo.email}</span></p>
                                </div>
                                <div className="user-roles">
                                    {userData.admin === 1 && <p><FaUserShield className="icon" /><strong>Admin</strong></p>}
                                    {userData.rector === 1 && <p><FaUniversity className="icon" /><strong>Rektor</strong></p>}
                                    {userInfo.isDeanOfFaculty && <p><FaUserShield className="icon" /><strong>Dekan fakulty</strong></p>}
                                </div>
                            </div>
                        </div>
                        {userInfo.membership && userInfo.membership.idRole ? (
                            <div className="membership-details">
                                <h4 style={{ marginBottom: "15px" }}><FaInfoCircle className="icon" style={{ marginTop: "-3px" }} />Informácie o členstve</h4>
                                <p><FaUserShield className="icon" style={{ marginTop: "-5px" }} /><strong>Rola v rade: </strong>{roleLabels[userInfo.membership.idRole]}</p>
                                <p><FaUser className="icon" style={{ marginTop: "-5px" }} /><strong>Začiatok platnosti členstva: </strong>{new Date(userInfo.membership.membershipStart).toLocaleDateString()}</p>
                                <p><FaUser className="icon" style={{ marginTop: "-5px" }} /><strong>Koniec platnosti členstva: </strong>{new Date(userInfo.membership.membershipEnd).toLocaleDateString()}</p>
                            </div>
                        ) : (
                            <div className="membership-details no-membership">
                                <FaUserSlash className="no-membership-icon" />
                                <h4>Bez členstva</h4>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="loading-container">
                        <p>Načítavam údaje...</p>
                        <div className='loading-spinner-component2'>
                            <div className='spinner'></div>
                        </div>
                    </div>
                )}
            </div>

            <RightsModal
                isOpen={isModalOpen}
                onClose={closeModal}
                isDean={userInfo?.isDeanOfFaculty}
                role={userInfo?.membership?.idRole}
                admin={userData.admin}
                rector={userData.rector}
            />
        </div>
    );
};

export default UserMembershipInfo;
