import React, { useState, useEffect, useContext } from 'react';
import { ProgramContext } from '../../Contexts/ProgramContext';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import PollResults from '../../Components/PollResults/PollResults';

const PollResultsPage = () => {
    return (
        <div>
           <div>
            <Navbar></Navbar>
           </div>
           <div style={{  marginTop: "120px" }}>
                <PollResults></PollResults>
           </div>
           <div style={{marginTop: "130px"}}>
            <Footer></Footer>
           </div>
        </div>
    )
}


export default PollResultsPage;