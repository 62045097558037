import React, { useState, useEffect } from 'react';
import './EvaluationList.css';
import image from '../../assets/images/errorDocument.png';
import { FaFileAlt, FaTrashAlt } from 'react-icons/fa';
import ConfirmModal from '../ConfrimModal/ConfirmModal';
import MessageModal from '../MessageModal/MessageModal';

const EvaluationList = () => {
    const [evaluations, setEvaluations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredEvaluations, setFilteredEvaluations] = useState([]);
    const [userData, setUserData] = useState({});
    const [authors, setAuthors] = useState({});
    const [isConfirmVisible, setIsConfirmVisible] = useState(false);
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [evaluationToDelete, setEvaluationToDelete] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchEvaluations = async () => {
            setIsLoading(true);
            const rspId = localStorage.getItem('selectedProgramId');
            const token = localStorage.getItem('token');
            const user = JSON.parse(localStorage.getItem('user'));

            setUserData(user);

            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getEvaluations`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ rspId, token }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setEvaluations(data);
                    setFilteredEvaluations(data);

                    // Fetch author names
                    const authorIds = [...new Set(data.map(evaluation => evaluation.idUser))];
                    const authorResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/user/getUserNames`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userIds: authorIds, token }),
                    });
                    const authorsData = await authorResponse.json();
                    const authorsMap = authorsData.reduce((acc, author) => {
                        acc[author.idUser] = author.name;
                        return acc;
                    }, {});
                    setAuthors(authorsMap);

                } else if (response.status !== 404) {
                    setError('Problém získať záznamy evaluácie');
                }
            } catch (error) {
                setError('Problém získať záznamy evaluácie');
            } finally {
                setIsLoading(false);
            }
        };

        fetchEvaluations();
    }, []);

    useEffect(() => {
        const lowercasedFilter = searchTerm.toLowerCase().replace(/\s+/g, ''); 
        const filteredData = evaluations.filter(item => {
            const titleMatch = item.title.toLowerCase().includes(lowercasedFilter);
            const descriptionMatch = item.description && item.description.toLowerCase().includes(lowercasedFilter);
            const fileNameMatch = item.fileName && item.fileName.toLowerCase().includes(lowercasedFilter);
            const subjectNameMatch = item.subjectName && item.subjectName.toLowerCase().includes(lowercasedFilter);
            const dateString = item.dateStart && new Date(item.dateStart).toLocaleDateString().replace(/\s+/g, '');
            const dateMatch = dateString && dateString.includes(lowercasedFilter);
            const authorMatch = authors[item.idUser]?.toLowerCase().includes(lowercasedFilter);
            
            return titleMatch || descriptionMatch || fileNameMatch || subjectNameMatch || dateMatch || authorMatch;
        });
        setFilteredEvaluations(filteredData);
    }, [searchTerm, evaluations, authors]);

    const handleFileClick = (fileId) => {
        const token = localStorage.getItem('token');
        window.open(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getEvaluationFile/${fileId}/${token}`, '_blank');
    };

    const handleEvaluationDelete = (evaluationId, title) => {
        setEvaluationToDelete({ evaluationId, title });
        setIsConfirmVisible(true);
        setMessage(`Naozaj si prajete vymazať hodnotenie: ${title}?`);
    };

    const confirmDeleteEvaluation = async () => {
        if (evaluationToDelete) {
            const token = localStorage.getItem('token');
            const rspId = localStorage.getItem('selectedProgramId');
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/deleteEvaluation`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ evaluationId: evaluationToDelete.evaluationId, token, rspId }),
                });

                if (response.ok) {
                    setEvaluations(evaluations.filter(evaluation => evaluation.id !== evaluationToDelete.evaluationId));
                    setFilteredEvaluations(filteredEvaluations.filter(evaluation => evaluation.id !== evaluationToDelete.evaluationId));
                    setIsMessageVisible(true);
                    setMessage(`Hodnotenie: ${evaluationToDelete.title} bolo úspešne odstránené.`);
                } else {
                    setMessage(`Hodnotenie: ${evaluationToDelete.title} sa nepodarilo odstrániť.`);
                    setIsMessageVisible(true);
                }
            } catch (error) {
                setMessage(`Hodnotenie: ${evaluationToDelete.title} sa nepodarilo odstrániť.`);
                setIsMessageVisible(true);
            } finally {
                setIsConfirmVisible(false);
                setEvaluationToDelete(null);
            }
        }
    };

    const handleLeftButtonFunction = () => {
        setEvaluationToDelete(null);
        setIsConfirmVisible(false);
    };

    const handleRightButtonFunction = () => {
        confirmDeleteEvaluation();
    };

    const messageModalButtonFunction = () => {
        setIsMessageVisible(false);
    };

    return (
        <div className="evaluation-list-wrapper">
            <ConfirmModal
                isVisible={isConfirmVisible}
                message={message}
                leftButtonText="Nie"
                rightButtonText="Áno"
                leftButtonFunction={handleLeftButtonFunction}
                rightButtonFunction={handleRightButtonFunction}
            />
            <MessageModal
                isVisible={isMessageVisible}
                message={message}
                buttonFunction={messageModalButtonFunction}
            />
            <h2 className="evaluation-list-title">Zoznam evaluácií</h2>
            {isLoading && <div className="loading-spinner-component"><div className="spinner"></div></div>}
            {error && <p className="error-message">{error}</p>}
            {evaluations.length === 0 && !isLoading &&
                <div style={{ marginTop: "-210px" }}>
                    <div className="centered-container">
                        <h2 className="centered-text">Zatiaľ žiadne evaluácie</h2>
                    </div>
                    <div className="centered-container-img" style={{ marginBottom: "90px" }}>
                        <img src={image} alt="No evaluations available" />
                    </div>
                </div>
            }
            {evaluations.length > 0 && !isLoading &&
                <input
                    type="text"
                    placeholder="Hľadať podľa názvu, popisu, názvu súboru, dátumu alebo predmetu..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input2"
                />
            }
           <ul className="evaluation-list">
                {filteredEvaluations.map(evaluation => (
                    <li key={evaluation.id} className="evaluation-item">
                        <FaFileAlt className="file-icon2" />
                        <div className="evaluation-info">
                            <div className="evaluation-title-container">
                                <span className="evaluation-title">Evaluácia: {evaluation.title}</span>
                                {(userData.id === evaluation.idUser || localStorage.getItem("role") === '6' || userData.isAdmin === 1) && (
                                    <FaTrashAlt className="delete-icon" onClick={() => handleEvaluationDelete(evaluation.id, evaluation.title)} />
                                )}
                            </div>
                            <p className="evaluation-subject">Predmet: {evaluation.subjectName}</p>
                            <p className="evaluation-author">Autor: {authors[evaluation.idUser]}</p>
                            <p className="evaluation-description">Popis: {evaluation.description}</p>
                            <span className="evaluation-date">Dátum pridania: {new Date(evaluation.dateStart).toLocaleDateString()}</span>
                            {evaluation.fileName ? (
                                <>
                                    <span className="evaluation-fileName" onClick={() => handleFileClick(evaluation.id)}>Názov súboru: {evaluation.fileName}</span>
                                    <div className="evaluation-actions">
                                        <button className="file-download-button" onClick={() => handleFileClick(evaluation.id)}>Stiahnuť súbor</button>
                                    </div>
                                </>
                            ) : (
                                <span className="evaluation-no-file">Bez súboru</span>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default EvaluationList;
