import React, { useState, useEffect, useRef } from 'react';
import { FaFile, FaTimes, FaSearch } from 'react-icons/fa'; // Import icons from react-icons
import "./FeedbackForm.css";

const MEDIUM_BLOB_SIZE = 4 * 1024 * 1024; 
const DESCRIPTION_MAX_LENGTH = 800; 

const FeedbackForm = ({ isVisible, onClose }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const [selectedCouncil, setSelectedCouncil] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [councils, setCouncils] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showClass, setShowClass] = useState('');
    const [blinkClass, setBlinkClass] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [successSend, setSuccessSend] = useState(false);

    const dropdownRef = useRef(null);

    const uniqueId = useRef(`fileInput_${Math.random().toString(36).substr(2, 9)}`);

    useEffect(() => {
        if (isVisible) {
            setShowClass('show');
            fetchCouncils();
        } else {
            setShowClass('');
            setSuccessSend(false);
        }
    }, [isVisible]);

    const fetchCouncils = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/allRspDetails`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json', 
                }
            });
    
            if (response.ok) {
                const data = await response.json(); 
                setCouncils(data); 
            } else {
                setMessage('Problém pri načítaní študíjnych programov');
            }
        } catch (error) {
            setMessage('Problém pri načítaní študíjnych programov');
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.size > MEDIUM_BLOB_SIZE) {
            setMessage(`Súbor je príliš veľký. Maximálna veľkosť súboru je ${MEDIUM_BLOB_SIZE / (1024 * 1024)} MB.`);
            setFile(null);
        } else {
            setFile(selectedFile);
            setMessage(null);
        }
    };

    const handleFileRemove = () => {
        setFile(null);
    };

    const handleDescriptionChange = (e) => {
        const newDescription = e.target.value;
        if (newDescription.length <= DESCRIPTION_MAX_LENGTH) {
            setDescription(newDescription);
        }
    };

    const handleFeedbackSubmit = async (e) => {
        e.preventDefault();
        if (!description && !file) {
            setMessage('Musí byť vybraný súbor alebo zadaný popis.');
            return;
        }

        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('description', description);
            formData.append('rspId', selectedCouncil);
            formData.append('file', file);

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/addFeedback`, {
                method: 'POST',
                body: formData,
            });

            setLoading(false);
            if (response.ok) {
                setMessage(null);
                setTitle('');
                setDescription('');
                setFile(null);
                setSelectedCouncil('');
                setSearchInput('');
                setSuccessSend(true);
            } else {
                const result = await response.json();
                setMessage(result.message || 'Problém pri odosielaní podnetu');
            }
        } catch (error) {
            setMessage('Problém pri odosielaní podnetu');
            setLoading(false);
        }
    };

    const handleOverlayClick = () => {
        setBlinkClass('blink');
        setTimeout(() => {
            setBlinkClass('');
        }, 300);
    };

    const handleCouncilSelect = (councilId) => {
        const selectedCouncil = councils.find(council => council.idRSP === councilId);
        if (selectedCouncil) {
            setSelectedCouncil(councilId);
            setSearchInput(`${selectedCouncil.name} - ${selectedCouncil.facultyName || "Žilinská univerzita v Žiline"}`);
        } else {
            setSelectedCouncil('');
            setSearchInput('');
        }
        setShowDropdown(false);
    };
    

    const filteredCouncils = searchInput
    ? (Array.isArray(councils) ? councils.filter((council) =>
        `${council.name} ${council.facultyName}`.toLowerCase().includes(searchInput.toLowerCase())
      ) : [])
    : (Array.isArray(councils) ? councils : []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`modal-overlay ${showClass} ${blinkClass}`} onClick={handleOverlayClick}>
            {loading && <div className="loading-spinner"></div>}
            <div className={`modal-content2 ${showClass} ${blinkClass}`} onClick={(e) => e.stopPropagation()}>
                {!successSend && (
                    <>
                        <button className="close-button" onClick={onClose}>&times;</button>
                        <h2 id="titleModal" style={{ marginBottom: "5px" }}>Podať Podnet</h2>
                        <form onSubmit={handleFeedbackSubmit}>
                            <input
                                type="text"
                                className="input-field"
                                placeholder="Názov podnetu"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                            />
                            <textarea
                                className="textarea-field"
                                placeholder="Popis podnetu (nepovinné)"
                                value={description}
                                onChange={handleDescriptionChange}
                                maxLength={DESCRIPTION_MAX_LENGTH}
                            />
                            <div className="char-count">
                                {description.length}/{DESCRIPTION_MAX_LENGTH} znakov
                            </div>

                            {/* Searchable Dropdown */}
                            <div className="input-field3-container" ref={dropdownRef}>
                                <div className="input-wrapper">
                                    <input
                                        type="text"
                                        className="input-field3"
                                        placeholder="Vyhľadajte študíjny program alebo fakultu"
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                        onFocus={() => setShowDropdown(true)}
                                        style={{paddingRight: "30px"}}
                                    />
                                    <div>
                                        <FaSearch className="input-icon3" />
                                    </div>
                                </div>
                                {showDropdown && filteredCouncils && filteredCouncils.length > 0 && (
                                    <ul className="dropdown-list3">
                                        {filteredCouncils.map((council) => (
                                            <li
                                                key={council.idRSP}
                                                onClick={() => handleCouncilSelect(council.idRSP)}
                                                className="dropdown-item3"
                                            >
                                                {council.name} - {council.facultyName ? council.facultyName : "Žilinská univerzita v Žiline"}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>

                            {/* Display selected council */}
                            {selectedCouncil && (
                                <div className="selected-council shadow-box2">
                                    Vybraný študíjny program: {councils.find(c => c.idRSP === selectedCouncil)?.name}
                                </div>
                            )}

                            {/* Custom File Input */}
                            <span className='file-text-label3'><FaFile className="file-icon6" />Výber súboru</span>
                            <label className="file-input-label3" htmlFor={uniqueId.current}>
                                Vyberte súbor (nepovinné)
                            </label>
                            <input
                                id={uniqueId.current}
                                type="file"
                                className="file-input3"
                                onChange={handleFileChange}
                            />
                            <div className="file-details5 shadow-box" style={{ height: "25px" }}>
                                {file ? (
                                    <>
                                        <FaFile className="file-icon5" />
                                        <span className="file-name5">{file.name}</span>
                                        <FaTimes className="remove-icon5" onClick={handleFileRemove} />
                                    </>
                                ) : (
                                    <span className="no-file-selected">Nie je vybraný žiadny súbor</span>
                                )}
                            </div>

                            <span className="error-message">{message}</span>
                            <button type="submit" className="submit-button-feedback">Odoslať podnet</button>
                        </form>
                    </>
                )}
                {successSend && (
                    <>
                        <button className="close-button" onClick={onClose}>&times;</button>
                        <h2 id="titleModal">Ďakujeme, váš podnet bol úspešne odoslaný</h2>
                        <div className="ok-button-container3">
                            <button className="ok-button3" onClick={onClose}>Ok</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default FeedbackForm;
