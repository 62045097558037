import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../../Components/Navbar/Navbar.jsx';
import ProposalList from '../../Components/ProposalList/ProposalList.jsx';
import Footer from '../../Components/Footer/Footer.jsx';


const ProposalPage = () => {

    useEffect(() => {
    
        const scrollToMiddle = () => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        };
    
        scrollToMiddle();
      }, []);
      
    return (
        <div>
            <div>
                <Navbar></Navbar>
            </div> 
            <div style={{marginTop: "120px"}}>
                <ProposalList></ProposalList>
            </div>
            <div style={{marginTop: "150px"}}>
                <Footer></Footer>
            </div>
        </div>
    );
};

export default ProposalPage;
