import React, { useState, useRef, useEffect, useContext } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './EventCalendar.css';
import { FaChevronDown, FaChevronUp, FaSquare } from 'react-icons/fa';
import { ProgramContext } from '../../Contexts/ProgramContext';
import { CalendarContext } from '../../Contexts/CalendarContext';
import {  formatInTimeZone, toZonedTime } from 'date-fns-tz';

const EventCalendar = () => {
  const { selectedProgram } = useContext(ProgramContext);
  const  {shouldResetCalendar } = useContext(CalendarContext);
  const [date, setDate] = useState(new Date());
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);
  const [nearestEvents, setNearestEvents] = useState([]);
  const [events, setEvents] = useState([]);
  const [isCalendarVisible, setCalendarVisible] = useState(true);
  const [calendarHeight, setCalendarHeight] = useState('auto');
  const [isEventsVisible, setIsEventsVisible] = useState(true);
  const [isNearEventsVisible, setIsNearEventsVisible] = useState(true);
  const [eventsHeight, setEventsHeight] = useState('auto');
  const [nearEventsHeight, setNearEventsHeight] = useState('auto');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const calendarRef = useRef(null);
  const eventsRef = useRef(null);
  const nearEventsRef = useRef(null);

  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const selectedProgramId = localStorage.getItem('selectedProgramId');

      if (!token || !selectedProgramId) {
        setIsLoading(false);
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getMeetingsByProgram`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ rspId: selectedProgramId, token }),
        });

        if (response.ok) {
          const data = await response.json();
          const formattedEvents = data.map(event => {
            const utcDate = new Date(event.dateStart);
            const localDate = toZonedTime(utcDate, 'Europe/Bratislava'); // Musim určiť časovú zónu
            return {
              date: localDate,
              description: `Zasadnutie RŠP na Mieste: ${event.place}`,
              subject: event.subject,
              time: formatInTimeZone(localDate, 'Europe/Bratislava', 'HH:mm'), // Formátovanie na lokálny čas
            };
          });
          setEvents(formattedEvents);

        } else {
         
        }
      } catch (error) {
        
      }
      setIsLoading(false);
    };
    
    if(shouldResetCalendar === false){
      setIsTransitioning(true);
      setTimeout(() => {
        fetchEvents();
        setIsTransitioning(false);
      }, 0); 
    }

  }, [selectedProgram, shouldResetCalendar]);

  useEffect(() => {
    if (events.length > 0) {
      const eventsForDate = events.filter(event => event.date.toDateString() === date.toDateString());
      setSelectedDateEvents(eventsForDate);

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const upcomingEvents = events
        .filter(event => event.date.setHours(0, 0, 0, 0) > today)
        .sort((a, b) => a.date - b.date)
        .slice(0, 4);

      setNearestEvents(upcomingEvents);
    } else {
      setSelectedDateEvents([]);
      setNearestEvents([]);
    }
  }, [date, events]);

  useEffect(() => {
    if (isEventsVisible) {
      const updateEventsHeight = () => {
        setEventsHeight(`${eventsRef.current.scrollHeight}px`);
        setTimeout(() => {
          setEventsHeight('auto');
        }, 500);
      };
      updateEventsHeight();
    } else {
      setEventsHeight('0px');
    }
  }, [selectedDateEvents, isEventsVisible]);

  useEffect(() => {
    if (isNearEventsVisible) {
      const updateNearEventsHeight = () => {
        setNearEventsHeight(`${nearEventsRef.current.scrollHeight}px`);
        setTimeout(() => {
          setNearEventsHeight('auto');
        }, 500);
      };
      updateNearEventsHeight();
    } else {
      setNearEventsHeight('0px');
    }
  }, [nearestEvents, isNearEventsVisible]);

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const tileContent = ({ date, view }) => {
    if (view === 'month') {
      const eventsForDate = events.filter(event => event.date.toDateString() === date.toDateString());
      return (
        <div className="dots-container">
          {eventsForDate.map((event, index) => (
            <div key={index} className={`dot dot-${index % 4}`}></div>
          ))}
        </div>
      );
    }
  };

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      const eventsForDate = events.filter(event => event.date.toDateString() === date.toDateString());
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);

      const isPastDate = date < today;
      return [
        eventsForDate.length > 0 ? 'highlight' : '',
        isPastDate ? 'past-date' : ''
      ].join(' ');
    }
  };

  const toggleCalendarVisibility = () => {
    if (isCalendarVisible) {
      setCalendarHeight(`${calendarRef.current.scrollHeight}px`);
      setTimeout(() => {
        setCalendarHeight('0px');
      }, 10);
    } else {
      setCalendarHeight(`${calendarRef.current.scrollHeight}px`);
      setTimeout(() => {
        setCalendarHeight('auto');
      }, 500);
    }
    setCalendarVisible(!isCalendarVisible);
  };

  const formatDate = (date) => {
    const today = new Date();
    if (date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()) {
      return "dnes";
    } else {
      const options = { weekday: 'long', day: '2-digit', month: '2-digit', year: 'numeric' };
      let formattedDate = date.toLocaleDateString('sk-SK', options);
      formattedDate = formattedDate.replace("nedeľa", 'nedeľu');
      formattedDate = formattedDate.replace("sobota", 'sobotu');
      formattedDate = formattedDate.replace("streda", 'stredu');
      return formattedDate;
    }
  };

  const toggleEventsVisibility = () => {
    if (isEventsVisible) {
      setEventsHeight(`${eventsRef.current.scrollHeight}px`);
      setTimeout(() => {
        setEventsHeight('0px');
      }, 10);
    } else {
      setEventsHeight(`${eventsRef.current.scrollHeight}px`);
      setTimeout(() => {
        setEventsHeight('auto');
      }, 500);
    }
    setIsEventsVisible(!isEventsVisible);
  };

  const toggleNearEventsVisibility = () => {
    if (isNearEventsVisible) {
      setNearEventsHeight(`${nearEventsRef.current.scrollHeight}px`);
      setTimeout(() => {
        setNearEventsHeight('0px');
      }, 10);
    } else {
      setNearEventsHeight(`${nearEventsRef.current.scrollHeight}px`);
      setTimeout(() => {
        setNearEventsHeight('auto');
      }, 500);
    }
    setIsNearEventsVisible(!isNearEventsVisible);
  };

  return (
    <div className={`calendar-container ${isTransitioning ? 'hidden' : ''}`}>
       {/* Calendar */}
        <div className='row align-items-center'>
          <div className='col-lg-10 col-md-10 col-sm-10'>
            <h3 id="name">KALENDÁR</h3>
          </div>
          {isLoading && (
            <div className="loading-spinner-component">
              <div className="spinner"></div>
            </div>
          )}
          <div className='col-lg-2 col-md-2 col-sm-2 text-right'>
            {isCalendarVisible ? (
              <FaChevronUp onClick={toggleCalendarVisibility} style={{ cursor: 'pointer' }} />
            ) : (
              <FaChevronDown onClick={toggleCalendarVisibility} style={{ cursor: 'pointer' }} />
            )}
          </div>
        </div>
        <div className="calendar-with-border"></div>
        <div
          ref={calendarRef}
          className={`calendar-content ${isCalendarVisible ? 'open' : 'closed'}`}
          style={{ maxHeight: calendarHeight, overflow: 'hidden', transition: 'max-height 0.8s ease-in-out' }}>
          <Calendar
            onChange={handleDateChange}
            value={date}
            tileContent={tileContent}
            tileClassName={tileClassName}/>
      </div>

      {/* events */}
      <div className='events-container'>

        {/* choose events by date */}
        <div className='row'>
          <div className='col-lg-10 col-md-10 col-sm-10'>
            <h2 className="events-title">Akcie na {formatDate(date)}</h2>
          </div>
          <div className='col-lg-2 col-md-2 col-sm-2'>
            {isEventsVisible ? (
              <FaChevronUp onClick={toggleEventsVisibility} style={{ cursor: 'pointer' }} />
            ) : (
              <FaChevronDown onClick={toggleEventsVisibility} style={{ cursor: 'pointer' }} />
            )}
          </div>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            <hr style={{ color: "white", borderWidth: "3px", marginTop: "0px" }} />
          </div>
        </div>
        <div ref={eventsRef} className={`events-content ${isEventsVisible ? 'open' : 'closed'}`}
          style={{ maxHeight: eventsHeight, overflow: 'hidden', transition: 'max-height 0.8s ease-in-out' }}>
          {selectedDateEvents.length > 0 ? (
            <ul className="events-list">
              {selectedDateEvents.map((event, index) => (
                <li key={index} className="event-item">
                  <FaSquare className={`event-icon-${index % 4}`} />
                  <span style={{ marginTop: "-5px", textAlign: "left" }}>{event.description} {event.subject} o {event.time}</span>
                </li>
              ))}
            </ul>
          ) : (
            <p className="no-events" style={{textAlign: "left", paddingLeft: "8px"}}>Žiadne akcie</p>
          )}
        </div>

        {/* near events */}
        <div>
          <div className='row' style={{ marginTop: "30px" }}>
            <div className='col-lg-10 col-md-10 col-sm-10'>
              <h2 className="events-title" style={{color: "#FFD700"}}>Najbližšie akcie</h2>
            </div>
            <div className='col-lg-2 col-md-2 col-sm-2'>
              {isNearEventsVisible ? (
                <FaChevronUp onClick={toggleNearEventsVisibility} style={{ cursor: 'pointer', color: "#FFD700" }} />
              ) : (
                <FaChevronDown onClick={toggleNearEventsVisibility} style={{ cursor: 'pointer', color: "#FFD700" }} />
              )}
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12'>
              <hr style={{ color: "yellow", borderWidth: "3px", marginTop: "0px" }} />
            </div>
          </div>
          <div ref={nearEventsRef} className={`events-content ${isNearEventsVisible ? 'open' : 'closed'}`}
            style={{ maxHeight: nearEventsHeight, overflow: 'hidden', transition: 'max-height 0.8s ease-in-out' }}>
            {nearestEvents.length > 0 ? (
              <ul className="events-list">
                {nearestEvents.map((event, index) => (
                  <li key={index} className="event-item">
                    <FaSquare className={`event-icon-${index % 4}`} />
                    <span style={{ marginTop: "-5px", textAlign: "left" }}>{event.description} {event.subject} v {formatDate(event.date)} o {event.time}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="no-events" style={{textAlign: "left", paddingLeft: "8px"}}>Žiadne najbližšie akcie</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCalendar;
