import React, { useState, useRef, useEffect, useContext } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import './ProgramBar.css';
import { ProgramContext } from '../../Contexts/ProgramContext';

const ProgramBar = () => {
    const { selectedProgram, setSelectedProgram } = useContext(ProgramContext);
    const [programs, setPrograms] = useState([]);
    const [filteredPrograms, setFilteredPrograms] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const fetchPrograms = async () => {
            const token = localStorage.getItem('token');
            const userData = JSON.parse(localStorage.getItem('user'));
            if (!token || !userData) {
                return;
            }

            try {
                let programList;
                if (userData.admin === 1 || userData.rector === 1) {
                    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getAllRsp`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ token }),
                    });
                    programList = await response.json();
                } else {
                    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getUserRsp`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ userId: userData.id, token }),
                    });
                    programList = await response.json();
                }
                setPrograms(programList);
                setFilteredPrograms(programList);

                const savedProgramId = localStorage.getItem('selectedProgramId');
                if (savedProgramId) {
                    const savedProgram = programList.find(program => program.idRSP === parseInt(savedProgramId, 10));
                    if (savedProgram) {
                        setSelectedProgram(savedProgram.idRSP);
                    } else {
                        setSelectedProgram(programList[0]?.idRSP || null);
                    }
                } else if (programList.length > 0) {
                    setSelectedProgram(programList[0]?.idRSP || null);
                    localStorage.setItem('selectedProgramId', programList[0]?.idRSP || '');
                } else {
                    setSelectedProgram(null);
                }
            } catch (error) {
                setSelectedProgram(null);
            }
        };

        fetchPrograms();
    }, [setSelectedProgram]);

    const handleSelectChange = (program) => {
        setSelectedProgram(program.idRSP);
        localStorage.setItem('selectedProgramId', program.idRSP);
        setIsDropdownOpen(false);
        setTimeout(() => {
            setSearchTerm('');
            setFilteredPrograms(programs);
        }, 700); 
    };

    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        setFilteredPrograms(programs.filter(program => program.name.toLowerCase().includes(term.toLowerCase())));
    };

    const toggleDropdown = () => {
        if (programs.length > 0) {
            setIsDropdownOpen(!isDropdownOpen);
            if (isDropdownOpen) {
                setTimeout(() => {
                    setSearchTerm('');
                    setFilteredPrograms(programs);
                }, 700); 
            }
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
            setTimeout(() => {
                setSearchTerm('');
                setFilteredPrograms(programs);
            }, 700); 
        }
    };

    useEffect(() => {
        if (isDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDropdownOpen]);

    return (
        <div className="container-fluid row-container" style={{ paddingLeft: "12px", paddingRight: "12px"}}>
            <hr id='line2' />
            <div className='row'>
                <div className='col-lg-8 col-md-7 col-sm-12 d-flex align-items-center'>
                    <div className='name-container'>
                        {programs.length > 0 ? (
                            <h2 className='name'>Zvolený študíjny program: {programs.find(program => program.idRSP === selectedProgram)?.name || 'Žiadne dostupné programy'}</h2>
                        ) : (
                            <h2 className='name'>Nemáte žiadne členstvá</h2>
                        )}
                    </div>
                </div>
                <div className='col-lg-4 col-md-5 col-sm-12'>
                    <div className={`dropdown2 ${programs.length === 0 ? 'disabled' : ''}`} ref={dropdownRef}>
                        <div className="dropdown-header" onClick={toggleDropdown}>
                            <span className="dropdown-title">{programs.find(program => program.idRSP === selectedProgram)?.name || 'Vyberte program'}</span>
                            {isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
                        </div>
                        <div className={`dropdown-menu2 ${isDropdownOpen ? 'show' : ''}`}>
                            <input
                                type="text"
                                placeholder="Hľadať program..."
                                value={searchTerm}
                                onChange={handleSearchChange}
                                className="search-input2"
                                disabled={programs.length === 0}
                            />
                            {filteredPrograms.map((program, index) => (
                                <div key={index} className="dropdown2-item" onClick={() => handleSelectChange(program)}>
                                    <div className="program-name">{program.name}</div>
                                    <div className="faculty-name">
                                        {program.facultyName ? program.facultyName : 'Žilinská univerzita v Žiline'}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgramBar;
