import React, { useState, useEffect, useContext } from 'react';
import { ProgramContext } from '../../Contexts/ProgramContext';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import EvaluationList from '../../Components/EvaluationList/EvaluationList';

const EvaluationListPage = () => {
    return (
        <div>
           <div>
            <Navbar></Navbar>
           </div>
           <div style={{  marginTop: "120px" }}>
                <EvaluationList></EvaluationList>
           </div>
           <div style={{marginTop: "130px"}}>
            <Footer></Footer>
           </div>
        </div>
    )
}


export default EvaluationListPage;