import React from 'react';
import { useNavigate } from 'react-router';
import './CreateRSP.css';
import { useState, useEffect } from 'react';
import Navbar from '../../Components/Navbar/Navbar.jsx';
import Footer from '../../Components/Footer/Footer.jsx';
import StudyProgramForm from '../../Components/StudyProgramForm/StudyProgramForm.jsx';

const CreateRSP = () => {
 
  return (
    <div>
      <Navbar isVisibleProgramBar={false}></Navbar>
        <div style={{marginTop: "120px"}}>
          <StudyProgramForm/>
        </div>
        <div style={{ marginTop: '100px' }}>
        <Footer />
        </div>
    </div>
  );
};

export default CreateRSP;
