import React, { useState, useEffect, useRef } from 'react';
import './Home.css';
import { Button  } from 'react-bootstrap';
import Navbar from '../../Components/Navbar/Navbar.jsx';
import Footer from '../../Components/Footer/Footer.jsx';
import RSPBar from '../../Components/RSPBar/RSPBar.jsx';
import EventCalendar from '../../Components/EventCalendar/EventCalendar.jsx';
import UserMembershipInfo from '../../Components/UserMembershipInfo/UserMembershipInfo.jsx';
import picture from '../../assets/images/noLogin.png';
import LoginModal from '../../Components/LoginModal/LoginModal';
import Landing from '../../Components/Landing/Landing.jsx';

const Home = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [isLoginModalVisible, setLoginModalVisible] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const noTokenSectionRef = useRef(null);

  const handleLoginClick = () => {
    setLoginModalVisible(true);
  };

  const handleCloseModal = () => {
    setLoginModalVisible(false);
  };

  const handleLogin = () => {
    if (localStorage.getItem('token') !== null) {
      handleCloseModal();
      window.location.reload();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.unobserve(entry.target); // Stop observing once the animation is triggered
          }
        });
      },
      { threshold: 0.5 } // Adjust this value to control when the animation triggers
    );
  
    if (noTokenSectionRef.current) {
      observer.observe(noTokenSectionRef.current);
    }
  
    return () => {
      if (noTokenSectionRef.current) {
        observer.unobserve(noTokenSectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <LoginModal
        isVisible={isLoginModalVisible}
        onClose={handleCloseModal}
        onLogin={handleLogin}
        logout={null}
      />
      <Navbar isVisibleProgramBar={true}></Navbar>
      {token === null && (
        <div>
          <Landing />
          <div className={`container5 mt-5 shadow-box ${isInView ? 'animate' : ''}`} ref={noTokenSectionRef}>
            <div className="row align-items-center">
              <div className="col-md-6 order-md-2 request-text">
                <h1 id="text" style={{ marginTop: '0px', marginBottom: '20px' }}>
                  PRE DAĽŠIE INTERAKCIE JE POTREBNÉ PRIHLÁSIŤ SA
                </h1>
                <p>
                  Táto aplikácia je primárne určená pre vnútorné použitie. Na získanie plného prístupu k jej funkcionalitám je nevyhnutné prihlásiť sa.
                  Používanie aplikácie je obmedzené na autorizovaných užívateľov, aby sme zabezpečili ochranu a bezpečnosť interných údajov. Ak už máte účet, prosím, prihláste sa kliknutím na tlačidlo nižšie.
                </p>
                <Button
                  className="button-request" variant="primary"
                  style={{padding: "5px", fontSize: "18px", borderRadius : "4px", width: "100%", marginTop: "25px" }}
                  onClick={handleLoginClick}>
                  PRIHLÁSIŤ SA
                </Button>
              </div>
              <div className="col-md-6 order-md-1 request-image">
                <img
                  src={picture}
                  id="pic1"
                  className="img-fluid"
                  style={{
                    marginTop: '20px',
                    borderRadius: '5px',
                    maxWidth: '100%',
                    height: 'auto',
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)'
                  }}
                  alt="Login Required"
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: '150px' }}>
            <Footer />
          </div>
        </div>
      )}
      {token !== null && (
        <div>
          <div
            className="container"
            style={{
              marginTop: '50px',
              textAlign: 'center',
            }}
          >
            <div className="row">
              <div
                id=""
                className="col-xl-8 col-lg-8 col-md-7 col-sm-12 mb-4"
                style={{ marginTop: '5px' }}
              >
                <RSPBar></RSPBar>
                <div style={{ marginTop: '30px' }}>
                  <UserMembershipInfo></UserMembershipInfo>
                </div>
              </div>
              <div
                className="col-xl-4 col-lg-4 col-md-5 col-sm-12 mb-4"
                style={{ marginLeft: '0px' }}
              >
                <EventCalendar/>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '50px' }}>
            <Footer />
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
