import React from 'react';
import './Footer.css';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div>
      <hr id="navLine" style={{ border: "2px solid blue", marginBottom: "-20px", zIndex: "-1", position: "relative" }} />
      <footer className="text-center text-white" id="footer">
        <div className="container p-4">
          <section className="mb-4" style={{ marginLeft: "20px" }}>
            <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button"
            ><i className="fab fa-facebook-f"></i
            ></a>
            <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button"
            ><i className="fab fa-twitter"></i
            ></a>
            <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button"
            ><i className="fab fa-google"></i
            ></a>
            <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button"
            ><i className="fab fa-instagram"></i
            ></a>
            <a className="btn btn-outline-light btn-floating m-1" href="#!" role="button"
            ><i className="fab fa-linkedin-in"></i
            ></a>
          </section>

          <section className="mb-4">
            <p>
              {/* Přidat text, pokud je potřeba */}
            </p>
          </section>
          <section className="">
            <div className="row">
              <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase">LINKY</h5>
                <ul className="list-unstyled mb-0">
                  <li>
                    <a href='' className="text-white" onClick={() => navigate('/')}>Domov</a>
                  </li>
                  <li>
                    <a href='' className="text-white" onClick={() => navigate('/registration')}>Registrácia</a>
                  </li>
                  <li>
                    <a href='' className="text-white" onClick={() => navigate('/login')}>Prihlásiť sa</a>
                  </li>
                  <li>
                    <a href="#!" className="text-white">About</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase">KONTAKTNÉ ÚDAJE</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a href="#!" className="text-white">Kontakty</a>
                  </li>
                  <li>
                    <a href="#!" className="text-white">Zákaznicka linka</a>
                  </li>
                  <li>
                    <a href="#!" className="text-white">Zmluvné podmienky</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase">UNIZA NA SOCIALNÝCH SIEŤACH</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a href="#!" className="text-white">Facebook</a>
                  </li>
                  <li>
                    <a href="#!" className="text-white">Instagram</a>
                  </li>
                  <li>
                    <a href="#!" className="text-white">Twitter</a>
                  </li>
                  <li>
                    <a href="#!" className="text-white">LinkedIn</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                <h5 className="text-uppercase">Podpora</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a href="#!" className="text-white">Podpora </a>
                  </li>

                </ul>
              </div>

            </div>
          </section>
        </div>

        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }} className="text-center p-3">
          © 2024 Copyright:   
          <a className="text-white" href="#"> Daniel Dziaba</a>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
