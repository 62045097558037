import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './MeetingList.css';
import CryptoJS from 'crypto-js';
import image from '../../assets/images/meeting.png';

const MeetingList = () => {
    const [meetings, setMeetings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredMeetings, setFilteredMeetings] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMeetings = async () => {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            const selectedProgramId = localStorage.getItem('selectedProgramId');
            const userData = JSON.parse(localStorage.getItem('user'));

            if (!token || !selectedProgramId || !userData) {
                setError('Prístup zamietnutý: nezvolené ID programu alebo nemáte povolenie prezerať zasadnutia a jej dokumenty v tejto rade.');
                setIsLoading(false);
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getMeetingsByProgram`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ rspId: selectedProgramId, token }),
                });

                if (response.ok) {
                    const data = await response.json();
                    const indexedData = data.map((meeting, index) => ({
                        ...meeting,
                        index: index + 1
                    }));
                    setMeetings(indexedData);
                    setFilteredMeetings(indexedData);
                } else {
                    setError('Error fetching meetings');
                }
            } catch (error) {
                setError('Error fetching meetings');
            } finally {
                setIsLoading(false);
            }
        };

        fetchMeetings();
    }, []);

    const handleSearch = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
        setFilteredMeetings(meetings.filter(meeting =>
            (meeting.place.toLowerCase().includes(term) ||
            new Date(meeting.dateStart).toLocaleString('sk-SK').replace(/\s/g, '').includes(term.replace(/\s/g, '')))
        ));
    };

    const customEncode = (input) => {
        const wordArray = CryptoJS.enc.Utf8.parse(input);
        const base64 = CryptoJS.enc.Base64.stringify(wordArray);
        const reversedBase64 = base64.split('').reverse().join(''); 
        const extendedEncoded = btoa(reversedBase64 + 'customPrefix');
        return extendedEncoded;
    };

    const handleMeetingClick = (meetingId, index) => {
        const encodedMeetingId = customEncode(meetingId.toString());
        const encodedIndex = customEncode(index.toString());
        navigate(`/meetingDocuments/${encodedMeetingId}/${encodedIndex}`);
    };

    if (meetings.length === 0 && !isLoading) {
        return (
            <div style={{ marginTop: "-60px" }}>
                <div className="centered-container">
                    <h2 className="centered-text">Zatiaľ žiadne zasadnutia rady</h2>
                </div>
                <div className="centered-container-img">
                    <img src={image} alt="No meetings available" />
                </div>
            </div>
        );
    }

    return (
        <div className="meeting-list-wrapper">
            <div className="meeting-list-container">
                <h2>Zoznam Zasadnutí</h2>
                {isLoading && <div className="loading-spinner-component"><div className="spinner"></div></div>}
                {error && <p className="error-message">{error}</p>}
                <input
                    type="text"
                    placeholder="Hľadať podľa dátumu alebo miesta..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="search-input"
                />
                <ul className="meeting-list">
                    {filteredMeetings.map(meeting => (
                        <li key={meeting.idMeeting} className="meeting-item" onClick={() => handleMeetingClick(meeting.idMeeting, meeting.index)}>
                            <h3 style={{ fontSize: "25px" }}>Zasadnutie {meeting.index}</h3>
                            <div><strong>Program:</strong> {meeting.programName}</div>
                            <div><strong>Miesto stretnutia:</strong> {meeting.place}</div>
                            <div><strong>Dátum konania:</strong> {new Date(meeting.dateStart).toLocaleString('sk-SK')}</div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default MeetingList;
