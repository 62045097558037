import React, { useState, useEffect } from 'react';
import "./LoginModal.css"
import { Link } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const LoginModal = ({ isVisible, onClose, onLogin }) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showClass, setShowClass] = useState('');
    const [succesLoginMessage, setSuccesLoginMessage] = useState(null);
    const serverURL =  process.env.REACT_APP_SERVER_URL;
    const [isLoggedOut, setIsLoggedOut] = useState(localStorage.getItem('LogOut') !== null);
    const [blinkClass, setBlinkClass] = useState('');
    const [againLogin, setAgainLogin] = useState(localStorage.getItem('againLogin') !== null);

    useEffect(() => {
        const handleStorageChange = () => {
            if (localStorage.getItem('LogOut') !== null) {
                setIsLoggedOut(true);
            } else {
                setIsLoggedOut(false);
            }
        };

        handleStorageChange();
    }, [isVisible]);

    useEffect(() => {
        const handleStorageChange2 = () => {
            if (localStorage.getItem('againLogin') !== null) {
                setAgainLogin(true);
            } else {
                setAgainLogin(false);
            }
        };

        handleStorageChange2();
    }, [isVisible]);

    useEffect(() => {
        if (isVisible) {
            setShowClass('show');
        } else {
            setShowClass('');
        }
    }, [isVisible]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${serverURL}/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();
            if (response.ok) {
                onLogin(data);
                const issuedAt = Date.now();
                localStorage.setItem("tokenIssuedAt", issuedAt.toString());
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', JSON.stringify(data.user));
                if(localStorage.getItem("LogOut") !== null){
                    localStorage.removeItem("LogOut");
                }
                window.location.reload();

            } else {
                setSuccesLoginMessage("Nesprávne prihlasovacie údaje");
            }
        } catch (error) {
            alert('Prihlásenie zlyhalo. Skúste to znova neskôr.');
            setSuccesLoginMessage("Zlyhanie na strane servera")
            console.log(error);
        }
    };

    const handleEmailChange = (e) => {
        setSuccesLoginMessage(null);
        setEmail(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setSuccesLoginMessage(null);
        setPassword(e.target.value);
    }

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleOverlayClick = () => {
        setBlinkClass('blink');
        setTimeout(() => {
            setBlinkClass('');
        }, 300); 
    };

    return (
        <div className={`modal-overlay ${showClass} ${blinkClass}`} onClick={handleOverlayClick}>
            <div className={`modal-content ${showClass} ${blinkClass}`} onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>&times;</button>
                <h2>Prihlásenie</h2>
                {isLoggedOut === true &&<span style={{ color: "red", fontSize: "16px"}}>Boli ste automaticky odhlásený z dôvodu dlhšej nečinnosti. Prosím, prihláste sa znova.</span>}
                {againLogin === true &&<span style={{ color: "red", fontSize: "17px", textAlign: "left", marginBottom: "15px" }}>Je vyžadované opätovné prihlásenie</span>}
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                    />
                    <div className="password-input-container">
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Heslo"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                        <button
                            type="button"
                            className="toggle-password-button"
                            onClick={toggleShowPassword}
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                    </div>
                    <span style={{ color: "red", marginBottom: "15px", textAlign: "left", fontSize: "16px" }}>{succesLoginMessage}</span>
                    <button type="submit" className="login-button">Prihlásiť sa</button>
                </form>
                <p>Nemáte členstvo? <Link to="">Požiadať o registráciu</Link></p>
                <p>Zabudnuté heslo? <Link to="">Obnoviť heslo</Link></p>
            </div>
        </div>
    );
};

export default LoginModal;
