import React, { useState, useEffect } from 'react';
import './ProposalList.css';
import image from '../../assets/images/errorDocument.png';
import { FaFile, FaTrashAlt } from 'react-icons/fa';
import ConfirmModal from '../ConfrimModal/ConfirmModal';
import MessageModal from '../MessageModal/MessageModal';

const ProposalList = () => {
    const [requests, setRequests] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [userData, setUserData] = useState({});
    const [isConfirmVisible, setIsConfirmVisible] = useState(false);
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchRequests = async () => {
            setIsLoading(true);
            const rspId = localStorage.getItem('selectedProgramId');
            const token = localStorage.getItem('token');
            const user = JSON.parse(localStorage.getItem('user'));

            setUserData(user);

            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getRequests`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ rspId, token }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setRequests(data);
                    setFilteredRequests(data);
                } else {
                    setError('Problém získať súbory');
                }
            } catch (error) {
                setError('Problém získať súbory');
            } finally {
                setIsLoading(false);
            }
        };

        fetchRequests();
    }, []);

    useEffect(() => {
        const lowercasedFilter = searchTerm.toLowerCase();
        const filteredData = requests.filter(item => {
            return (
                item.title.toLowerCase().includes(lowercasedFilter) ||
                item.fileAuthor.toLowerCase().includes(lowercasedFilter)
            );
        });
        setFilteredRequests(filteredData);
    }, [searchTerm, requests]);

    const handleFileClick = (fileId) => {
        const token = localStorage.getItem('token');
        window.open(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getFileProposal/${fileId}/${token}`, '_blank');
    };

    const handleFileDelete = (fileId, fileName) => {
        setFileToDelete({ fileId, fileName });
        setIsConfirmVisible(true);
        setMessage(`Naozaj si prajete vymazať súbor: ${fileName}?`);
    };

    const confirmDeleteFile = async () => {
        if (fileToDelete) {
            const token = localStorage.getItem('token');
            const rspId = localStorage.getItem('selectedProgramId');
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/deleteFileProposal`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ fileId: fileToDelete.fileId, token, rspId }),
                });

                if (response.ok) {
                    setRequests(requests.filter(request => request.idFile !== fileToDelete.fileId));
                    setFilteredRequests(filteredRequests.filter(request => request.idFile !== fileToDelete.fileId));
                    setIsMessageVisible(true);
                    setMessage(`Súbor: ${fileToDelete.fileName} bol úspešne odstránený.`);
                } else {
                    setError('Problém pri odstraňovaní súboru');
                }
            } catch (error) {
                setError('Problém pri odstraňovaní súboru');
            } finally {
                setIsConfirmVisible(false);
                setFileToDelete(null);
            }
        }
    };

    const handleLeftButtonFunction = () => {
        setFileToDelete(null);
        setIsConfirmVisible(false);
    };

    const handleRightButtonFunction = () => {
        confirmDeleteFile();
    };

    const messageModalButtonFunction = () => {
        setIsMessageVisible(false);
    };

    return (
        <div className="proposal-list-wrapper">
            <ConfirmModal
                isVisible={isConfirmVisible}
                message={message}
                leftButtonText="Nie"
                rightButtonText="Áno"
                leftButtonFunction={handleLeftButtonFunction}
                rightButtonFunction={handleRightButtonFunction}
            />
            <MessageModal
                isVisible={isMessageVisible}
                message={message}
                buttonFunction={messageModalButtonFunction}
            />
            <h2 className="proposal-list-title">Žiadosti/Návrhy</h2>
            {isLoading && <div className="loading-spinner-component"><div className="spinner"></div></div>}
            {error && <p className="error-message">{error}</p>}
            {requests.length === 0 && !isLoading &&
                <div style={{ marginTop: "-210px" }}>
                    <div className="centered-container">
                        <h2 className="centered-text">Zatiaľ žiadne žiadosti alebo návrhy rady</h2>
                    </div>
                    <div className="centered-container-img" style={{ marginBottom: "90px" }}>
                        <img src={image} alt="No proposals available" />
                    </div>
                </div>
            }
            {requests.length > 0 && !isLoading &&
                <input
                    type="text"
                    placeholder="Hľadať podľa názvu alebo autora..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="search-input2"
                />
            }
           <ul className="proposal-list">
                {filteredRequests.map(request => (
                    <li key={request.idFile} className="proposal-item">
                        <FaFile className="file-icon2" />
                        <div className="proposal-info">
                            <div className="proposal-title-container">
                                
                                <span className="proposal-title">Žiadosť/Návrh: {request.title}</span>
                            
                                
                                {(userData.id === request.idUser || localStorage.getItem("role") === '6' || userData.isAdmin === 1) && (
                                    <FaTrashAlt className="delete-icon" onClick={() => handleFileDelete(request.idFile, request.fileName)} />
                                )}
                            
                            </div>
                            <span className="proposal-fileName" onClick={() => handleFileClick(request.idFile)}>Názov súboru: {request.fileName}</span>
                            <p className="proposal-description">Popis: {request.description}</p>
                            <span className="proposal-author">Autor: {request.fileAuthor}</span>
                            <div className="proposal-actions">
                                <button className="file-download-button" onClick={() => handleFileClick(request.idFile)}>Stiahnuť súbor</button>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ProposalList;
