import React, { useState, useEffect, useContext } from 'react';
import { ProgramContext } from '../../Contexts/ProgramContext';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import VotePoll from '../../Components/VotePoll/VotePoll';

const VotePollPage = () => {
    return (
        <div>
           <div>
            <Navbar></Navbar>
           </div>
           <div style={{  marginTop: "120px" }}>
                <VotePoll></VotePoll>
           </div>
           <div style={{marginTop: "130px"}}>
            <Footer></Footer>
           </div>
        </div>
    )
}


export default VotePollPage;