import React, { useState, useEffect } from 'react';
import "./MessageModal.css";
import "../LoginModal/LoginModal.css";
import { Link } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const MessageModal = ({ isVisible, message, buttonFunction }) => {
    const [showClass, setShowClass] = useState(''); 
    const [blinkClass, setBlinkClass] = useState('');

    useEffect(() => {
        if (isVisible) {
            setShowClass('show');
        } else {
            setShowClass('');
        }
    }, [isVisible]);


    const handleOverlayClick = () => {
        setBlinkClass('blink');
        setTimeout(() => {
            setBlinkClass('');
        }, 300); 
    };

    return (
        <div className={`modal-overlay ${showClass} ${blinkClass}`} onClick={handleOverlayClick}>
            <div className={`modal-content2 ${showClass} ${blinkClass}`} onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={buttonFunction}>&times;</button>
                <h2 className="text-message">{message}</h2>
                <div className="button-container">
                    <button className="function-button" onClick={buttonFunction}>Dobre</button>
                </div>
            </div>
        </div>
    );
};

export default MessageModal;
