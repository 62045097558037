import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import "./Registration.css";
import "../../index.css";
import HelpNavbar from '../../Components/HelpNavbar/HelpNavbar';
import image from "../../assets/images/codeNotValid.png";
import Footer from '../../Components/Footer/Footer';
import RegistrationForm from '../../Components/RegistrationForm/RegistrationForm';

const Registration = () => {
    const { registrationCode } = useParams();
    const navigate = useNavigate();
    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isUsed, setIsUsed] = useState(false);

    useEffect(() => {
        const verifyRegistrationCode = async () => {
            const serverUrl = process.env.REACT_APP_SERVER_URL;
            try {
                const response = await fetch(`${serverUrl}/api/auth/verifyRegistrationCode`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ registrationCode }),
                });
                const data = await response.json();
                if (response.status === 200) {
                    setIsValid(true);
                } else if (response.status === 401) {
                    setIsUsed(true);
                    setIsValid(null);
                } else {
                    setIsValid(false);
                }

            } catch (error) {
                setIsValid(false);
            } finally {
                setLoading(false);
            }
        };

        verifyRegistrationCode();
    }, [registrationCode]);

    if (loading) {
        return (
            <div>   
                <HelpNavbar></HelpNavbar>
                <div className="loading-spinner"></div>
                <div style={{ marginTop: "700px"}}>
                    <Footer></Footer>
                </div>
            </div>

        );
    }

    if (isValid === false) {
        return (
            <div>
                <HelpNavbar></HelpNavbar>
                <div className="centered-container">
                    <h2 className="centered-text">Registračný kód nie je valídny</h2>
                </div>
                <div className="centered-container-img">
                    <img src={image} alt="Invalid code illustration" />       
                </div>
                <div style={{ marginTop: "200px"}}>
                <Footer></Footer>
            </div>
            </div>
        );
    }

    if(isUsed){
        return (
            <div>
                <HelpNavbar></HelpNavbar>
                <div className="centered-container">
                    <h2 className="centered-text">Registračný kód už bol použitý</h2>
                </div>
                <div className="centered-container-img">
                    <img src={image} alt="Invalid code illustration" />       
                </div>
                <div style={{ marginTop: "200px"}}>
                <Footer></Footer>
            </div>
            </div>
        );
    }

    return (
        <div>
            <div>
                <HelpNavbar></HelpNavbar>
            </div>
            <div className="reg-form" >
                    <RegistrationForm registrationCode={registrationCode}></RegistrationForm>
            </div>
            <div className='reg-footer'>
                <Footer></Footer>
            </div>
        </div>
    );
};

export default Registration;
