import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './RegistrationForm.css';
import MessageModal from '../MessageModal/MessageModal.jsx';

const RegistrationForm = ({ registrationCode }) => {

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [photo, setPhoto] = useState(null);
    const [photoPreview, setPhotoPreview] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordStrengthError, setPasswordStrengthError] = useState('');
    const [errorEmail, setErrorEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const navigate = useNavigate();


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Heslá sa nezhodujú');
            return;
        }
        if (!isStrongPassword(password)) {
            setPasswordStrengthError('Heslo musí obsahovať aspoň 8 znakov, jedno veľké písmeno, jedno malé písmeno, jedno číslo a jeden špeciálny znak.');
            return;
        }

        setLoading(true);
        const emailExists = await handleEmailExistence();
        if (emailExists) {
            return;
        }

        const formData = new FormData();
        formData.append('registrationCode', registrationCode);
        formData.append('email', email);
        formData.append('name', name);
        formData.append('password', password);
        formData.append('photo', photo);

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/auth/register`, {
                method: 'POST',
                body: formData,
            });
            const data = await response.json();

            if (response.ok) {
                setSuccess('Registrácia úspešná');
                setError('');
                try {
                    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/auth/login`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ email, password }),
                    });
        
                    const data = await response.json();
                    setLoading(false);
                    if (response.ok) {
                        const issuedAt = Date.now();
                        localStorage.setItem("tokenIssuedAt", issuedAt.toString());
                        localStorage.setItem('token', data.token);
                        localStorage.setItem('user', JSON.stringify(data.user));
                        if(localStorage.getItem("LogOut") !== null){
                            localStorage.removeItem("LogOut");
                        }
                        setIsVisible(true);
                    }
                } catch (error) {
                    setLoading(false);
                }
                
            } else {
                setError(data.message || 'Registrácia zlyhala');
                setSuccess('');
                setLoading(false);
            }
        } catch (error) {
            setError('Chyba pri registrácii');
            setSuccess('');
            setLoading(false);
        }
    };

    const handleEmailExistence = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/user/verifyEmail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.status === 200) {
                setErrorEmail('');
                return false;
            } else if (response.status === 401) {
                setErrorEmail("Email je už registrovaný");
                setLoading(false);
                return true;
            } else {
                setErrorEmail('Chyba pri overení emailu');
                setLoading(false);
                return true;
            }
        } catch (error) {
            setErrorEmail('Chyba pri overení emailu');
            setLoading(false);
            return true;
        }
    };

    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        setPhoto(file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setPhotoPreview(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const handleRemovePhoto = () => {
        setPhoto(null);
        setPhotoPreview(null);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (confirmPassword && e.target.value !== confirmPassword) {
            setError('Heslá sa nezhodujú');
        } else {
            setError('');
        }
        if (passwordStrengthError && isStrongPassword(e.target.value)) {
            setPasswordStrengthError('');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (password && e.target.value !== password) {
            setError('Heslá sa nezhodujú');
        } else {
            setError('');
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const isStrongPassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };

    const handleSuccesRegistration = () => { 
        setIsVisible(false);
        navigate("/");
    };

    return (
        <div className="registration-form-container">
            {loading === true &&  <div className="loading-spinner"></div>}
             <MessageModal isVisible={isVisible} message={"Registrácia prebehla uspešne"} buttonFunction={handleSuccesRegistration}/>
            <form onSubmit={handleSubmit} className="registration-form">
                <h2>Registrácia</h2>
                {success && <p className="success-message">{success}</p>}
                <div className="form-group">
                    <label>Email: <span className="required">*</span></label>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                {errorEmail && <p className="error-message">{errorEmail}</p>}
                <div className="form-group">
                    <label>Celé meno: <span className="required">*</span></label>
                    <input
                        type="text"
                        placeholder="Celé meno"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Heslo: <span className="required">*</span></label>
                    <div className="password-input-container">
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Heslo"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                        <button
                            type="button"
                            className="toggle-password-button2"
                            onClick={toggleShowPassword}
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                    </div>
                </div>
                <div className="form-group">
                    <label>Potvrďte heslo: <span className="required">*</span></label>
                    <div className="password-input-container">
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Potvrďte heslo"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                        />
                        <button
                            type="button"
                            className="toggle-password-button2"
                            onClick={toggleShowConfirmPassword}
                        >
                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                    </div>
                </div>
                {error && <p className="error-message">{error}</p>}
                {passwordStrengthError && <p className="error-message">{passwordStrengthError}</p>}
                <div className="form-group">
                    <label>Fotka:</label>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handlePhotoChange}
                    />
                    {photoPreview && (
                        <div className="photo-preview" style={{ backgroundColor: "rgb(26, 50, 95)" }}>
                            <span style={{ color: "white" }}> Náhľad fotky v profile</span>
                             <div  style={{ display: "flex", color: "white", alignItems: "center", marginTop: "8px" }}>
                                <img src={photoPreview} id='profilePicture' alt="Profile" style={{ borderRadius: "50%", marginRight: "5px", height: "130px", width: "130px"}} />
                            </div>
                            <button type="button" onClick={handleRemovePhoto}>Odstrániť fotku</button>
                        </div>
                    )}
                </div>
                <button className="reg-button" type="submit">Registrovať sa</button>
            </form>
        </div>
    );
};

export default RegistrationForm;
