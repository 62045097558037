import React, { useState } from 'react';
import './CreatePoll.css';
import MessageModal from '../MessageModal/MessageModal';

const CreatePoll = () => {
    const [pollName, setPollName] = useState('');
    const [questions, setQuestions] = useState([]);
    const [newQuestion, setNewQuestion] = useState('');
    const [newOption, setNewOption] = useState('');
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
    const [expirationDate, setExpirationDate] = useState('');
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [messageModal, setMessageModal] = useState('');

    const handleAddQuestion = () => {
        if (newQuestion.trim() === '') {
            setMessage('Otázka nemôže byť prázdna.');
            return;
        }
        setQuestions([...questions, { question: newQuestion, options: [] }]);
        setNewQuestion('');
        setMessage(null);
    };

    const handleAddOption = () => {
        if (newOption.trim() === '' || selectedQuestionIndex === null) {
            setMessage('Možnosť nemôže byť prázdna.');
            return;
        }
        const updatedQuestions = questions.map((q, index) => {
            if (index === selectedQuestionIndex) {
                return { ...q, options: [...q.options, newOption] };
            }
            return q;
        });
        setQuestions(updatedQuestions);
        setNewOption('');
        setMessage(null);
    };

    const handleCreatePoll = async () => {
        if (pollName.trim() === '') {
            setMessage('Názov hlasovania nemôže byť prázdny.');
            return;
        }
        if (questions.length === 0) {
            setMessage('Musíte pridať aspoň jednu otázku.');
            return;
        }
        for (const question of questions) {
            if (question.options.length < 2) {
                setMessage('Každá otázka musí mať aspoň dve možnosti.');
                return;
            }
        }

        const currentDateTime = new Date();
        const selectedDateTime = new Date(expirationDate);

        if (selectedDateTime <= currentDateTime) {
            setMessage('Dátum a čas platnosti hlasovania musí byť v budúcnosti.');
            return;
        }

        setLoading(true);
        const token = localStorage.getItem('token');
        const selectedProgramId = localStorage.getItem('selectedProgramId');

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/polls/createPoll`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ pollName, questions, expirationDate, token, selectedProgramId }),
            });

            const result = await response.json();

            if (response.ok) {
                setLoading(false);
                setMessage('');
                setMessageModal(`Hlasovanie s názvom ${pollName} bolo úspešne vytvorené`)
                setIsVisible(true);
                setPollName('');
                setQuestions([]);
                setExpirationDate('');
            } else {
                setMessage(result.message || 'Chyba pri vytváraní hlasovania');
                setLoading(false);
            }
        } catch (error) {
            setMessage('Chyba pri vytváraní hlasovania');
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const handleQuestionChange = (index, value) => {
        const updatedQuestions = questions.map((q, i) => {
            if (i === index) {
                return { ...q, question: value };
            }
            return q;
        });
        setQuestions(updatedQuestions);
    };

    const handleDeleteQuestion = (index) => {
        const updatedQuestions = questions.filter((_, i) => i !== index);
        setQuestions(updatedQuestions);
        setSelectedQuestionIndex(null);
    };

    const handleDeleteOption = (questionIndex, optionIndex) => {
        const updatedQuestions = questions.map((q, i) => {
            if (i === questionIndex) {
                return { ...q, options: q.options.filter((_, oi) => oi !== optionIndex) };
            }
            return q;
        });
        setQuestions(updatedQuestions);
    };

    const handleCloseModal = () => {
        setIsVisible(false);
    };

    return (
        <div className="create-poll-container">
            {loading && <div className='loading-spinner'/>}
            <MessageModal isVisible={isVisible} message={messageModal} buttonFunction={handleCloseModal} />
            <h2>Tvorba Hlasovania</h2>
            <input
                type="text"
                placeholder="Názov hlasovania"
                value={pollName}
                onChange={(e) => setPollName(e.target.value)}
            />
            <div className="questions-container">
                <input
                    type="text"
                    placeholder="Nová otázka"
                    value={newQuestion}
                    onChange={(e) => setNewQuestion(e.target.value)}
                />
                <button className="add-question-button2" onClick={handleAddQuestion}>Pridať otázku</button>
            </div>
            {questions.map((q, index) => (
                <div 
                    key={index} 
                    className={`question-block ${selectedQuestionIndex === index ? 'selected' : ''}`}
                    onClick={() => setSelectedQuestionIndex(index)}
                >
                    <div className="question-header">
                        <h3>Otázka {index + 1}</h3>
                        <button className="delete-question-button" onClick={() => handleDeleteQuestion(index)}>X</button>
                    </div>
                    <input
                        type="text"
                        value={q.question}
                        onChange={(e) => handleQuestionChange(index, e.target.value)}
                        className="question-input"
                    />
                    <div className="options-container">
                        {q.options.map((option, i) => (
                            <div key={i} className="option-label">
                                <input type="checkbox" disabled /> {option}
                                <button className="delete-option-button" onClick={() => handleDeleteOption(index, i)}>X</button>
                            </div>
                        ))}
                        {selectedQuestionIndex === index && (
                            <div className="add-option-container">
                                <input
                                    type="text"
                                    placeholder="Nová možnosť"
                                    value={newOption}
                                    onChange={(e) => setNewOption(e.target.value)}
                                />
                                <button className="add-option-button" onClick={handleAddOption}>Pridať možnosť</button>
                            </div>
                        )}
                    </div>
                </div>
            ))}
            <div className="expiration-date-container">
                <label>Platnosť hlasovania do:</label>
                <input
                    type="datetime-local"
                    value={expirationDate}
                    onChange={(e) => setExpirationDate(e.target.value)}
                />
            </div>
            {message && <p className="message">{message}</p>}
            <button className="create-poll-button" onClick={handleCreatePoll} disabled={loading}>
                {loading ? 'Vytváranie hlasovania...' : 'Vytvoriť hlasovanie'}
            </button>
        </div>
    );
};

export default CreatePoll;
