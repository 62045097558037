import React, { useState, useEffect, useRef } from 'react';
import unizaLogo from '../../assets/images/uniza-logo.png'; 
import profilePicture from "../../assets/images/profile.png";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "./Navbar.css";
import LoginModal from '../LoginModal/LoginModal.jsx';
import ProgramBar from '../ProgramBar/ProgramBar.jsx';
import FeedbackForm from '../FeddbackForm/FeedbackForm.jsx';

const Navbar = ({ isVisibleProgramBar }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isLoginModalVisible, setLoginModalVisible] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [pictureUrl, setPictureUrl] = useState(null);
    const userData = JSON.parse(localStorage.getItem("user"));
    const offcanvasRef = useRef(null);
    const toggleButtonRef = useRef(null); 
    const [forceReload, setForceReload] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isProfileDropdownVisible, setIsProfileDropdownVisible] = useState(false);
    const [showModalRequest, setShowModalRequest] = useState(false);

    const handleShowModalRequest = () => {
        closeOffcanvas();  // Close the sidebar when the modal is opened
        setShowModalRequest(true);
    };

    const handleCloseModalRequest = () => {
        setShowModalRequest(false);
        resetToggleButton();  // Ensure toggle button state is reset
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const offcanvasElement = document.getElementById('offcanvasNavbar');
        if (offcanvasElement && offcanvasElement.classList.contains('show')) {
            offcanvasElement.classList.remove('show');
            document.body.classList.remove('offcanvas-backdrop');
        }
        document.body.style.overflow = '';
    }, []);

    const handleLinkClick = (path) => {
        const offcanvasElement = document.getElementById('offcanvasNavbar');
        if (offcanvasElement) {
            offcanvasElement.classList.remove('show');
            document.body.classList.remove('offcanvas-backdrop');
            document.body.classList.remove('offcanvas-open');
            setTimeout(() => {
                navigate(path);
                setIsOpen(false);
                setForceReload(prev => !prev);
            }, 10);
        }
    };

    const handleToggleClick = () => {
        if (toggleButtonRef.current) {
            toggleButtonRef.current.click();
            if (document.body.classList.contains('offcanvas-open')) {
                document.body.classList.remove('offcanvas-open');
                document.body.style.overflowY = 'scroll'; 
            } else {
                document.body.classList.add('offcanvas-open');
                document.body.style.overflowY = 'scroll'; 
                document.body.style.paddingRight = '0px'; 
            }
        }
    };
    
    const closeOffcanvas = () => {
        const offcanvasElement = document.getElementById('offcanvasNavbar');
        const offcanvasBackdrop = document.querySelector('.offcanvas-backdrop');
        if (offcanvasElement && offcanvasElement.classList.contains('show')) {
            offcanvasElement.classList.remove('show');
            setIsOpen(false);
            document.body.classList.remove('offcanvas-open');
            if (offcanvasBackdrop) {
                offcanvasBackdrop.remove();
            }
            document.body.style.overflow = '';
            document.body.style.paddingRight = '0px';  
        }
    };

    const resetToggleButton = () => {
        if (isOpen) {
            toggleButtonRef.current?.click();
            setIsOpen(false);
        }
    };
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (offcanvasRef.current && !offcanvasRef.current.contains(event.target)) {
                closeOffcanvas();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [offcanvasRef]);

    useEffect(() => {
        if (userData && userData.picture && !pictureUrl) {
            const isPng = userData.picture.toString('hex', 0, 4) === '89504e47';
            const isJpeg = userData.picture.toString('hex', 0, 2) === 'ffd8';
            const isWebp = userData.picture.toString('hex', 0, 4) === '52494646' && userData.picture.toString('hex', 8, 12) === '57454250';

            let mimeType = 'image/png';
            if (isJpeg) mimeType = 'image/jpeg';
            if (isWebp) mimeType = 'image/webp';

            const blob = new Blob([new Uint8Array(userData.picture.data)], { type: mimeType });
            const url = URL.createObjectURL(blob);
            setPictureUrl(url);

            return () => {
                URL.revokeObjectURL(url);
            };
        }
    }, [userData, pictureUrl]);

    useEffect(() => {
        const handleShow = () => {
            document.body.style.paddingRight = '0px';
        };

        const offcanvas = document.getElementById('offcanvas');
        
        if (offcanvas) {
            offcanvas.addEventListener('shown.bs.offcanvas', handleShow);

            return () => {
                offcanvas.removeEventListener('shown.bs.offcanvas', handleShow);
            };
        }
    }, []);

    useEffect(() => {
        const showLoginModal = localStorage.getItem('showModalLogin');
        if (showLoginModal === "1") {
            closeOffcanvas(); 
            setLoginModalVisible(true);
            localStorage.removeItem('showModalLogin');
        }
    }, []);

    useEffect(() => {
       
        const pendingLogout = localStorage.getItem("pendingLogout");

        if (pendingLogout === "true") {
            localStorage.removeItem("tokenIssuedAt");
            localStorage.removeItem("user");
            localStorage.removeItem("selectedProgramId");
            localStorage.removeItem("pendingLogout"); 
            localStorage.setItem("showLoginModal", "true");
        }
    }, []);

    const handleLoginClick = () => {
        closeOffcanvas();  // Close the sidebar when the login modal is opened
        setLoginModalVisible(true);
    };
    
    const handleCloseModal = () => {
        setLoginModalVisible(false);
        resetToggleButton();  // Ensure toggle button state is reset
    };

    const handleLogin = () => {
        if (localStorage.getItem("token") !== null) {
            handleCloseModal();
            window.location.reload();
        }
    };

    const handleLogOut = () => {
        localStorage.removeItem("token");
        localStorage.setItem("pendingLogout", "true");
        window.location.reload();
    }

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='row-container'>
            <nav className="navbar navbar-expand-lg navbar-light" id="navbar">
                <div className='navbar-nav mr-auto'> 
                    <Link to="https://uniza.sk/" className="navbar-brand" style={{ marginLeft: "-5px" }}>
                        <img className='logo' src={unizaLogo} height="80" width="132" alt="Žilinská Univerzita v Žiline" />
                        <span className='text' style={{ color: 'white' }}>Žilinská Univerzita v Žiline</span>
                    </Link>
                </div>

                <button ref={toggleButtonRef} className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                   <span className="navbar-toggler-icon">
                       <span></span>
                       <span></span>
                       <span></span>
                   </span>
               </button>

                <div className="mx-auto" id="navbarNav"></div>

                {(token !== null && userData !== null) && (
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav">
                            {location.pathname !== "/" && (
                                <li className="nav-item">
                                    {windowWidth < 992 &&
                                    <a onClick={() => { handleToggleClick(); handleLinkClick('/'); }} style={{ textDecoration: "none", cursor: "pointer" }} id="img2">
                                        <span style={{ marginLeft: "0px", marginRight: "11px" }}>DOMOV</span>
                                        <i className="fas fa-home" style={{ marginRight: "35px", marginTop: "17px", fontSize: "24px" }}></i>
                                    </a>}
                                    {windowWidth > 991 &&
                                    <a onClick={() => { handleToggleClick(); handleLinkClick('/'); }} style={{ textDecoration: "none", cursor: "pointer" }}>
                                        <i className="fas fa-home" id="img2" style={{ marginRight: "45px", marginTop: "17px", fontSize: "29px" }}></i>
                                    </a>}
                                </li>
                            )}
                            <li className="nav-item">
                                {windowWidth < 992 &&
                                <a onClick={() => { handleToggleClick(); handleLinkClick('/notifications'); }} style={{ textDecoration: "none", cursor: "pointer" }} id="img2">
                                    <span style={{ marginLeft: "0px", marginRight: "11px" }}>SPRÁVY</span>
                                    <i className="fas fa-envelope" style={{ marginRight: "5px", marginTop: "17px", fontSize: "24px" }}></i>
                                    <span className="badge" style={{ position: "relative", top: "-15px", right: "4px", backgroundColor: "dodgerblue", color: "white", borderRadius: "50%", padding: "2px 6px" }}>0</span>
                                </a>}
                                {windowWidth > 991 &&
                                <a onClick={() => { handleToggleClick(); handleLinkClick('/notifications'); }} style={{ textDecoration: "none", cursor: "pointer" }}>
                                    <i className="fas fa-envelope" id="img2" style={{ marginRight: "5px", marginTop: "17px", fontSize: "29px" }}></i>
                                    <span className="badge" style={{ position: "relative", top: "-27px", right: "8px", backgroundColor: "dodgerblue", color: "white", borderRadius: "50%", padding: "2px 6px" }}>0</span>
                                </a>}
                            </li>
                            <li 
                                className="nav-item dropdown" 
                                style={{ marginTop: "10px", marginRight: "18px", color: "white" }} 
                                onMouseEnter={() => windowWidth > 991 && setIsDropdownVisible(true)} 
                                onMouseLeave={() => windowWidth > 991 && setIsDropdownVisible(false)}
                            >
                                <button 
                                    className="nav-link dropdown-toggle" 
                                    aria-controls="generalInfoCollapse"
                                    style={{ color: "white", background: "none", border: "none", cursor: "pointer" }}
                                >
                                    <span id='text2'>VŠEOBECNÉ INFORMÁCIE</span>
                                </button>
                                <div className={`dropdown-menu ${isDropdownVisible || isOpen ? 'show' : ''}`} aria-labelledby="navbarDropdown" id="navbarDropdownItem" style={{ marginTop: "1px", width: "280px" }}>
                                    <a className="dropdown-item" onClick={() => { handleToggleClick(); handleLinkClick('/action1'); }} style={{ cursor: "pointer" }}>Rady študíjného programu</a>
                                    <a className="dropdown-item" onClick={() => { handleToggleClick(); handleLinkClick('/action2'); }} style={{ cursor: "pointer" }}>Fakulty</a>
                                    <a className="dropdown-item" onClick={() => { handleToggleClick(); handleLinkClick('/something'); }} style={{ cursor: "pointer" }}>Členovia</a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" onClick={() => { handleToggleClick(); handleLinkClick('/separated-link'); }} style={{ cursor: "pointer" }}>Dotazníky</a>
                                </div>
                            </li>
                            <li 
                                className="nav-item dropdown" 
                                id="navbarDropdown"
                                onClick={() => windowWidth > 991 && setIsProfileDropdownVisible(true)}
                                onMouseEnter={() => windowWidth > 991 && setIsProfileDropdownVisible(true)} 
                                onMouseLeave={() => windowWidth > 991 && setIsProfileDropdownVisible(false)}
                            >
                                <div style={{ display: "flex", color: "white", alignItems: "center", marginTop: "8px" }}>
                                    {userData.picture === null && 
                                    <img src={profilePicture} id='profilePicture' alt="Profile" style={{ borderRadius: "50%", marginRight: "5px" }} />}
                                    {userData.picture !== null && 
                                    <img src={pictureUrl} id='profilePicture' alt="Profile" style={{ borderRadius: "50%", marginRight: "5px" }} />}
                                </div>
                                <div className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="text3">
                                    <span>{userData.name}</span>
                                </div>
                                <div className={`dropdown-menu ${isProfileDropdownVisible ? 'show' : ''}`} aria-labelledby="navbarDropdown" id="navbarDropdownItem" style={{ marginTop: "1px", width: "200px" }}>
                                    <a className="dropdown-item" onClick={() => { handleToggleClick(); handleLinkClick('/action1'); }} style={{ cursor: "pointer" }}>Môj profil</a>
                                    <a className="dropdown-item" onClick={() => { handleToggleClick(); handleLinkClick('/action2'); }} style={{ cursor: "pointer" }}>Moje členstvá</a>
                                    <div className="dropdown-divider"></div>
                                    <button onClick={handleLogOut} className="dropdown-item">Odhlásiť sa</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                )}

                {token === null && (
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav">
                            <li 
                                className="nav-item dropdown" 
                                style={{ marginTop: "10px", marginRight: "18px", color: "white" }} 
                                onMouseEnter={() => windowWidth > 991 && setIsDropdownVisible(true)} 
                                onMouseLeave={() => windowWidth > 991 && setIsDropdownVisible(false)}
                            >
                                <button 
                                    className="nav-link dropdown-toggle" 
                                    aria-controls="generalInfoCollapse"
                                    style={{ color: "white", background: "none", border: "none", cursor: "pointer" }}
                                >
                                    <span id='text2'>VŠEOBECNÉ INFORMÁCIE</span>
                                </button>
                                <div className={`dropdown-menu ${isDropdownVisible || isOpen ? 'show' : ''}`} aria-labelledby="navbarDropdown" id="navbarDropdownItem" style={{ marginTop: "1px", width: "280px" }}>
                                    <a className="dropdown-item" onClick={() => { handleToggleClick(); handleLinkClick('/action1'); }} style={{ cursor: "pointer" }}>Študíjne programy</a>
                                    <a className="dropdown-item" onClick={() => { handleToggleClick(); handleLinkClick('/action2'); }} style={{ cursor: "pointer" }}>Fakulty</a>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" onClick={() => { handleToggleClick(); handleLinkClick('/separated-link'); }} style={{ cursor: "pointer" }}>Dotazníky</a>
                                </div>
                            </li>

                            <li className="nav-item request-navbar-icon" >
                                <a 
                                    onClick={handleShowModalRequest} 
                                    className="nav-link " 
                                    style={{ textDecoration: "none", cursor: "pointer", marginTop: "9px", marginRight: "25px" }}
                                >
                                    <i className="fas fa-comment-dots" style={{ fontSize: "23px", marginRight: "10px" }}></i>
                                    <span>Podať podnet</span>
                                </a>
                            </li>

                            <li className="nav-item" id="logText">
                                <div 
                                    onClick={handleLoginClick} 
                                    style={{ position: "relative", cursor: "pointer", display: "flex", alignItems: "center" }}
                                >
                                    <span style={{ marginRight: "17px", fontSize: "19px" }}>Prihlásiť sa</span>
                                    <i className="fas fa-user" style={{ fontSize: "23px", marginRight: "25px"}}></i>
                                </div>
                            </li>
                        </ul>
                    </div>
                )}
            </nav>

           {token !== null && (
                <div className="offcanvas offcanvas-end" tabIndex="0" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" ref={offcanvasRef}>
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Navigácia</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                            {location.pathname !== "/" && (
                                <li className="nav-item">
                                    <a onClick={() => { handleToggleClick(); handleLinkClick('/'); }} className="nav-link active" aria-current="page" style={{ cursor: "pointer" }}>
                                        <i className="fas fa-home me-2"></i>Domov
                                    </a>
                                </li>
                            )}
                            <li className="nav-item">
                                <a onClick={() => { handleToggleClick(); handleLinkClick('/notifications'); }} className="nav-link" style={{ cursor: "pointer" }}>
                                    <i className="fas fa-envelope me-2"></i>Správy
                                    <span className="badge bg-primary ms-2">0</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link btn" data-bs-toggle="collapse" data-bs-target="#generalInfoCollapse" aria-expanded={isOpen} 
                                    aria-controls="generalInfoCollapse" onClick={handleToggle}>
                                    <i className="fas fa-info-circle me-2"></i>Všeobecné Informácie
                                    {!isOpen && <i className="fas fa-chevron-down ms-auto chevron-icon"></i>}
                                    {isOpen && <i className="fas fa-chevron-up ms-auto chevron-icon" style={{paddingLeft: "17px"}}></i>}
                                </button>
                                <div className="collapse" id="generalInfoCollapse">
                                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                        <li><a onClick={() => { handleToggleClick(); handleLinkClick('/action1'); }} className="nav-link2" style={{ cursor: "pointer" }}>Rady študíjného programu</a></li>
                                        <li><a onClick={() => { handleToggleClick(); handleLinkClick('/action2'); }} className="nav-link2" style={{ cursor: "pointer" }}>Fakulty</a></li>
                                        <li><a onClick={() => { handleToggleClick(); handleLinkClick('/something'); }} className="nav-link2" style={{ cursor: "pointer" }}>Členovia</a></li>
                                        <li><a onClick={() => { handleToggleClick(); handleLinkClick('/separated-link'); }} className="nav-link2" style={{ cursor: "pointer" }}>Dotazníky</a></li>
                                    </ul>
                                </div>
                            </li>
                            <div className="dropdown-divider"></div>
                            <li className="nav-item">
                                <a onClick={() => { handleToggleClick(); handleLinkClick('/action2'); }} className="nav-link" style={{ cursor: "pointer" }}>
                                    <i className="fas fa-user me-2"></i>Môj profil
                                </a>
                            </li>
                            <li className="nav-item">
                                <a onClick={() => { handleToggleClick(); handleLinkClick('/action2'); }} className="nav-link" style={{ cursor: "pointer" }}>
                                    <i className="fas fa-users me-2"></i>Moje členstvá
                                </a>
                            </li>
                            <div className="dropdown-divider"></div>
                            <li className="nav-item">
                                <button onClick={handleLogOut} className="nav-link btn">
                                    <i className="fas fa-sign-out-alt me-2"></i>Odhlásiť sa
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            )}
            {token === null && (
                <div className="offcanvas offcanvas-end" tabIndex="0" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" ref={offcanvasRef}>
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Navigácia</h5>
                        <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                            <li className="nav-item">
                                <button className="nav-link btn" data-bs-toggle="collapse" data-bs-target="#generalInfoCollapse" aria-expanded={isOpen} 
                                    aria-controls="generalInfoCollapse" onClick={handleToggle}>
                                    <i className="fas fa-info-circle me-2"></i>Všeobecné Informácie
                                    {!isOpen && <i className="fas fa-chevron-down ms-auto chevron-icon"></i>}
                                    {isOpen && <i className="fas fa-chevron-up ms-auto chevron-icon" style={{paddingLeft: "17px"}}></i>}
                                </button>
                                <div className="collapse" id="generalInfoCollapse">
                                    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                        <li><a onClick={() => { handleToggleClick(); handleLinkClick('/action1'); }} className="nav-link2" style={{ cursor: "pointer" }}>Študíjne programy</a></li>
                                        <li><a onClick={() => { handleToggleClick(); handleLinkClick('/action2'); }} className="nav-link2" style={{ cursor: "pointer" }}>Fakulty</a></li>
                                        <li><a onClick={() => { handleToggleClick(); handleLinkClick('/separated-link'); }} className="nav-link2" style={{ cursor: "pointer" }}>Dotazníky</a></li>
                                    </ul>
                                </div>
                            </li>
                            <div className="dropdown-divider"></div>
                            <li className="nav-item">
                                <a onClick={handleShowModalRequest} className="nav-link" style={{ cursor: "pointer" }}>
                                    <i className="fas fa-comment-dots me-2"></i>Podať podnet
                                </a>
                            </li>
                            <div className="dropdown-divider"></div>
                            <li className="nav-item">
                                <a onClick={handleLoginClick} className="nav-link" style={{ cursor: "pointer" }}>
                                    <i className="fas fa-user me-2"></i>Prihlásiť sa
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            )}


            {(token !== null && isVisibleProgramBar === true) && (
                <div className="program-container" style={{ marginTop: "100px", marginBottom: "25px" }}>
                    <ProgramBar></ProgramBar>
                </div>
            )}

            <LoginModal isVisible={isLoginModalVisible} onClose={handleCloseModal} onLogin={handleLogin} />
            <FeedbackForm isVisible={showModalRequest} onClose={handleCloseModalRequest} />
        </div>
    );
};

export default Navbar;
