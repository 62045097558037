import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../../Components/Navbar/Navbar.jsx';
import MeetingList from '../../Components/MeetingList/MeetingList.jsx';
import Footer from '../../Components/Footer/Footer.jsx';


const DocumentListMeetingPage = () => {

    return (
        <div>
            <div>
                <Navbar></Navbar>
            </div> 
            <div style={{marginTop: "120px"}}>
                <MeetingList></MeetingList>
            </div>
            <div style={{marginTop: "150px"}}>
                <Footer></Footer>
            </div>
        </div>
    );
};

export default DocumentListMeetingPage;
