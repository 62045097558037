import React, { useState, useEffect, useContext } from 'react';
import { ProgramContext } from '../../Contexts/ProgramContext';
import image from '../../assets/images/codeNotValid.png';
import './CancelMeeting.css';
import ConfirmModal from '../ConfrimModal/ConfirmModal';
import MessageModal from '../MessageModal/MessageModal';

const CancelMeeting = () => {
    const { selectedProgram } = useContext(ProgramContext);
    const [meetings, setMeetings] = useState([]);
    const [indexedMeetings, setIndexedMeetings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredMeetings, setFilteredMeetings] = useState([]);
    const [isConfirmVisible, setIsConfirmVisible] = useState(false);
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [meetingToCancel, setMeetingToCancel] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchMeetings = async () => {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            const selectedProgramId = localStorage.getItem('selectedProgramId');

            if (!token || !selectedProgramId) {
                setError('Prístup zamietnutý: nezvolené ID programu alebo nemáte povolenie rušiť zasadnutia v tejto rade.');
                setIsLoading(false);
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getMeetingsByProgram`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ rspId: selectedProgramId, token }),
                });

                if (response.ok) {
                    const data = await response.json();
                    const currentDate = new Date();

                    const indexedData = data.map((meeting, index) => ({
                        ...meeting,
                        index: index + 1
                    }));

                    const futureMeetings = indexedData.filter(meeting => new Date(meeting.dateStart) >= currentDate);
                    setMeetings(indexedData);
                    setFilteredMeetings(futureMeetings);
                    setIndexedMeetings(indexedData);
                } else {
                    setError('Error fetching meetings');
                }
            } catch (error) {
                setError('Error fetching meetings');
            } finally {
                setIsLoading(false);
            }
        };

        fetchMeetings();
    }, [selectedProgram]);

    if (localStorage.getItem('token')) {
        const userData = JSON.parse(localStorage.getItem("user"));
        if (userData.admin !== 1 && localStorage.getItem('role') !== '6') {
            return (
                <div style={{ marginTop: "-60px" }}>
                    <div className="centered-container">
                        <h2 className="centered-text">Nemáte povolenie rušiť zasadnutia</h2>
                    </div>
                    <div className="centered-container-img">
                        <img src={image} alt="Invalid code illustration" />
                    </div>
                </div>
            );
        }
    }

    const handleCancelMeeting = (meetingId, meetingOrder) => {
        setMeetingToCancel({ meetingId, meetingOrder });
        setIsConfirmVisible(true);
        setMessage(`Naozaj si prajete zrušiť zasadnutie: Zasadnutie ${meetingOrder}?`);
    };

    const confirmCancelMeeting = async () => {
        if (meetingToCancel) {
            setIsLoading(true);
            const token = localStorage.getItem('token');

            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/cancelMeeting`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ meetingId: meetingToCancel.meetingId, token }),
                });

                if (response.ok) {
                    setMeetings(prevMeetings => prevMeetings.filter(meeting => meeting.idMeeting !== meetingToCancel.meetingId));
                    setFilteredMeetings(prevMeetings => prevMeetings.filter(meeting => meeting.idMeeting !== meetingToCancel.meetingId));
                    setIsMessageVisible(true);
                    setMessage(`Zasadnutie: ${meetingToCancel.meetingOrder} bolo úspešne zrušené.`);
                } else {
                    setError('Nastal problém zrušiť zasadnutie');
                }
            } catch (error) {
                setError('Nastal problém zrušiť zasadnutie');
            } finally {
                setIsLoading(false);
                setIsConfirmVisible(false);
                setMeetingToCancel(null);
            }
        }
    };

    const handleLeftButtonFunction = () => {
        setMeetingToCancel(null);
        setIsConfirmVisible(false);
    };

    const handleRightButtonFunction = () => {
        confirmCancelMeeting();
    };

    const messageModalButtonFunction = () => {
        setIsMessageVisible(false);
    };

    const handleSearch = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
        const currentDate = new Date();
        setFilteredMeetings(meetings.filter(meeting =>
            (meeting.place.toLowerCase().includes(term) ||
            new Date(meeting.dateStart).toLocaleString('sk-SK').replace(/\s/g, '').includes(term.replace(/\s/g, ''))) &&
            new Date(meeting.dateStart) >= currentDate
        ));
    };

    return (
        <div className="cancel-meeting-wrapper">
            <ConfirmModal
                isVisible={isConfirmVisible}
                message={message}
                leftButtonText="Nie"
                rightButtonText="Áno"
                leftButtonFunction={handleLeftButtonFunction}
                rightButtonFunction={handleRightButtonFunction}
            />
            <MessageModal
                isVisible={isMessageVisible}
                message={message}
                buttonFunction={messageModalButtonFunction}
            />
            <div className="cancel-meeting-container">
                <h2 className='text'>Zrušiť Zasadnutie Rady</h2>
                {isLoading && <div className="loading-spinner-component"><div className="spinner"></div></div>}
                {error && <p className="error-message">{error}</p>}
                <input
                    type="text"
                    placeholder="Hľadať podľa dátumu alebo miesta..."
                    value={searchTerm}
                    onChange={handleSearch}
                    className="search-input"
                />
                <ul className="meeting-list">
                    {filteredMeetings.map(meeting => (
                        <li key={meeting.idMeeting} className="meeting-item">
                            <h3 className='text2' style={{ fontSize: "24px" }}>Zasadnutie {meeting.index} </h3>
                            <div>
                                <strong>Program:</strong> {meeting.programName}
                            </div>
                            <div>
                                <strong>Miesto stretnutia:</strong> {meeting.place}
                            </div>
                            <div>
                                <strong>Dátum konania:</strong> {new Date(meeting.dateStart).toLocaleString('sk-SK')}
                            </div>
                            <button className="cancel-button" onClick={() => handleCancelMeeting(meeting.idMeeting, meeting.index)}>Zrušiť zasadnutie</button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CancelMeeting;
