import React, { useEffect, useRef, useState } from 'react';
import { Carousel, Button  } from 'react-bootstrap';
import './Landing.css';
import carosuel1 from '../../assets/images/carousel11.avif';
import carosuel2 from '../../assets/images/carosuel12.avif';
import carosuel3 from '../../assets/images/carousel3.avif';
import about1 from '../../assets/images/about1.avif';
import about3 from '../../assets/images/about3.avif';
import FeedbackForm from '../FeddbackForm/FeedbackForm';

const Landing = () => {
  const [aboutInView, setAboutInView] = useState(false);
  const [featuresInView, setFeaturesInView] = useState(false);
  const [requestInView, setRequestInView] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const aboutRef = useRef(null);
  const featuresRef = useRef(null);
  const requestRef = useRef(null);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target === aboutRef.current) {
              setAboutInView(true);
            } else if (entry.target === featuresRef.current) {
              setFeaturesInView(true);
            } else if (entry.target === requestRef.current){
                setRequestInView(true);
            }
          }
        });
      },
      { threshold: 0.50 } 
    );

    if (aboutRef.current) {
      observer.observe(aboutRef.current);
    }

    if (featuresRef.current) {
      observer.observe(featuresRef.current);
    }

    if (requestRef.current){
      observer.observe(requestRef.current);
    }

    return () => {
      if (aboutRef.current) observer.unobserve(aboutRef.current);
      if (featuresRef.current) observer.unobserve(featuresRef.current);
      if (requestRef.current) observer.unobserve(requestRef.current);
    };
  }, []);

  return (
    <div>
      {/* Carousel Section */}
      <div className="custom-carousel-container">
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={carosuel1}
              alt="First slide"
            />
            <Carousel.Caption>
            <p className='carousel-text'>
              Zjednodušenie administrácie.
            </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={carosuel2}
              alt="Second slide"
            />
            <Carousel.Caption>
              <p className='carousel-text'> Podpora archívacie údajov </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={carosuel3}
              alt="Third slide"
            />
            <Carousel.Caption>
            <p className='carousel-text'>Plánovanie zasadnutí</p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      {/* About Section */}
      <div className={`container5 mt-5 shadow-box ${aboutInView ? 'animate' : ''}`} ref={aboutRef}>
        <div className="row align-items-center">
          <div className="col-md-6 order-md-1 about-text">
            <h2>O aplikácii Study Path</h2>
            <p>
            Study Path je webová aplikácia určená na zjednodušenie a efektívne riadenie administratívnych procesov spojených so študijnými programami na univerzitách. 
            Umožňuje správcom a členom rady študijného programu lepšie organizovať a koordinovať svoje aktivity, čím podporuje hladký priebeh študijných programov. 
            Je navrhnutá tak, aby uľahčila komunikáciu, plánovanie a sledovanie pokroku v rámci akademických štruktúr, 
            čím zlepšuje celkovú efektivitu administrácie a zabezpečuje, že všetky potrebné úlohy sú vykonávané včas a správne.
            </p>
          </div>
          <div className="col-md-6 order-md-2 about-image">
            <img
              src={about1}
              alt="About Us"
              className="img-fluid"
            />
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className={`container5 mt-5 shadow-box ${featuresInView ? 'animate' : ''}`} ref={featuresRef}>
        <div className="row align-items-center">
          <div className="col-md-6 order-md-1 features-image">
            <img
              src={carosuel1}
              alt="Features"
              className="img-fluid"
            />
          </div>
          <div className="col-md-6 order-md-2 features-text">
          <h2>Kľúčové funkcie</h2>
          <p>
            Aplikácia Study Path ponúka množstvo kľúčových funkcií, ktoré uľahčujú riadenie študijných programov.
            Medzi hlavné funkcie patrí možnosť efektívnej správy členov rady študijného programu, plánovanie a organizácia stretnutí, 
            pridávanie a správa dokumentov, a podávanie návrhov a žiadostí. Aplikácia taktiež poskytuje prehľadný kalendár udalostí, ktorý pomáha s plánovaním aktivít. 
            Vďaka responzívnemu dizajnu je aplikácia prístupná na rôznych zariadeniach, čo umožňuje flexibilitu a pohodlné používanie kdekoľvek a kedykoľvek. 
            Tieto funkcie robia Study Path neoceniteľným nástrojom pre administráciu a efektívne riadenie študijných programov.
          </p>
          </div>
        </div>
      </div>
      {/* New Block Section */}
      <div className={`container5 mt-5 shadow-box ${requestInView ? 'animate' : ''}`} ref={requestRef}>
        <div className="row align-items-center">
        <div className="col-md-6 order-md-2 request-image">
            <img
              src={about3}
              alt="Request Information"
              className="img-fluid"
            />
          </div>
          <div className="col-md-6 order-md-1 request-text">
            <h2>Podať podnet k študijnému programu</h2>
            <p>
              Máte pripomienky k niektorým študijným programom alebo zaujímajú vás naše študijné programy? 
              Radi by sme počuli váš názor! Ak máte nejaký podnet, pripomienku alebo žiadosť týkajúcu sa konkrétneho študijného programu, 
              neváhajte ich podať. Vaša spätná väzba je pre nás veľmi dôležitá a pomáha nám neustále zlepšovať kvalitu našich programov. 
              Kliknite na tlačidlo nižšie a jednoducho odošlite svoju žiadosť alebo podnet k študijnému programu. 
            </p>
            <Button className="button-request" variant="primary" onClick={handleShowModal} style={{padding: "6px", fontSize: "18px", borderRadius : "4px", width: "100%", marginTop: "25px" }}>
              Podať podnet
            </Button>
          </div>
        </div>
      </div>
      <FeedbackForm isVisible={showModal} onClose={handleCloseModal}/> 
    </div>
  );
};

export default Landing;
