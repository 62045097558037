import React, { useState, useEffect, useRef, useContext } from 'react';
import { FaChevronDown, FaChevronUp, FaLock, FaExclamationTriangle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import "./RSPBar.css";
import { ProgramContext } from '../../Contexts/ProgramContext';
import RegistrationModal from '../RegistrationModal/RegistrationModal';
import PlanMeetingForm from '../PlanMeetingForm/PlanMeetingForm';
import FileRequestFrom from '../FileRequestFrom/FileRequestFrom';
import FeedbackForm from '../FeddbackForm/FeedbackForm';
import EvaluationForm from '../EvaulationForm/EvaulationForm';

const RSPBar = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isMenuOpen1, setMenuOpen1] = useState(false);
    const [isMenuOpen2, setMenuOpen2] = useState(false);
    const [isMenuOpen3, setMenuOpen3] = useState(false);
    const [isRSPBarVisible, setIsRSPBarVisible] = useState(true);
    const [isVisibleRegistration, setIsVisibleRegistration] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isDean, setIsDean] = useState(false);
    const [isRector, setIsRector] = useState(false);
    const { selectedProgram, setSelectedProgram } = useContext(ProgramContext);
    const [isLocked, setIsLocked] = useState(!selectedProgram || selectedProgram === 'Žiadne dostupné programy');
    const [isMeetingFormVisible, setIsMeetingFormVisible] = useState(false);
    const [isRequestFormVisible, setIsRequestFormVisible] = useState(false);
    const [role, setRole] = useState(null);
    const [isPollingAvailable, setIsPollingAvailable] = useState(false);
    const contentRef = useRef(null);
    const contentRef1 = useRef(null);
    const contentRef2 = useRef(null);
    const contentRef3 = useRef(null);
    const rspBarRef = useRef(null);
    const [rspBarHeight, setRspBarHeight] = useState("auto");
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeanFacultyRSP, setIsDeanFacultyRSP] = useState(false);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [isEvaluationFormVisible, setIsEvaluationFormVisible] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleCloseEvalutionFrom = () => setIsEvaluationFormVisible(false);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("user"));
        if (userData) {
            setIsAdmin(!!userData.admin);
            setIsRector(!!userData.rector);
            setIsDean(!!userData.idFaculty);
        }
    }, [role]);

    useEffect(() => {
        const handleStorageChange = () => {
            const storedProgram = localStorage.getItem("selectedProgramId");
            setIsLocked(!storedProgram || storedProgram === 'Žiadne dostupné programy');
        };

        handleStorageChange();
    }, [selectedProgram]);

    useEffect(() => {
        const fetchRole = async () => {
            setIsLoading(true);
            const token = localStorage.getItem('token');
            const userData = JSON.parse(localStorage.getItem('user'));
            console.log(userData);
            console.log(token);

            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getUserRolesByRspId`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ rspId: selectedProgram, userId: userData.id, token }),
                });

                const isDeanFacultyRSPResposne = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/isDeanFacultyRSP`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ programId: selectedProgram }),
                });

                const data = await response.json();
                const deanResponse = await isDeanFacultyRSPResposne.json();
                setIsDeanFacultyRSP(deanResponse);
                setRole(data.idRole);
                localStorage.setItem('role', data.idRole);
            } catch (error) {
                
            } finally {
                setIsLoading(false);
            }
        };

        const fetchPolls = async () => {
            const selectedProgramId = localStorage.getItem('selectedProgramId');
            const token = localStorage.getItem('token');

            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/polls/getRspPolls`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ selectedProgramId, token }),
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.length > 0) {
                        setIsPollingAvailable(true);
                    } else{
                        setIsPollingAvailable(false);
                    }
                } else{
                    setIsPollingAvailable(false);
                }
               
            } catch (error) {
                
                setIsPollingAvailable(false);
            }
        };

        if (selectedProgram) {
            setIsTransitioning(true);
            setTimeout(() => {
                fetchRole();
                fetchPolls();
                setIsTransitioning(false);
            }, 50);
        }
    }, [selectedProgram]);

    useEffect(() => {
        if (isMenuOpen) {
            contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
        } else {
            contentRef.current.style.maxHeight = '0px';
        }
        if (isMenuOpen1) {
            contentRef1.current.style.maxHeight = `${contentRef1.current.scrollHeight}px`;
        } else {
            contentRef1.current.style.maxHeight = '0px';
        }
        if (isMenuOpen2) {
            contentRef2.current.style.maxHeight = `${contentRef2.current.scrollHeight}px`;
        } else {
            contentRef2.current.style.maxHeight = '0px';
        }
        if (isMenuOpen3) {
            contentRef3.current.style.maxHeight = `${contentRef3.current.scrollHeight}px`;
        } else {
            contentRef3.current.style.maxHeight = '0px';
        }
    }, [isMenuOpen, isMenuOpen1, isMenuOpen2, isMenuOpen3]);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
        setMenuOpen1(false);
        setMenuOpen2(false);
        setMenuOpen3(false);
        handleRspBarHeight();
    };

    const toggleMenu1 = () => {
        setMenuOpen(false);
        setMenuOpen1(!isMenuOpen1);
        setMenuOpen2(false);
        setMenuOpen3(false);
        handleRspBarHeight();
    };

    const toggleMenu2 = () => {
        setMenuOpen(false);
        setMenuOpen1(false);
        setMenuOpen2(!isMenuOpen2);
        setMenuOpen3(false);
        handleRspBarHeight();
    };

    const toggleMenu3 = () => {
        setMenuOpen(false);
        setMenuOpen1(false);
        setMenuOpen2(false);
        setMenuOpen3(!isMenuOpen3)
        handleRspBarHeight();
    };

    const handleRspBarHeight = () => {
        if (isRSPBarVisible) {
            setRspBarHeight('850px');
            setTimeout(() => {
                setRspBarHeight(`auto`);
            }, 1800);
        }
    };

    const toggleRSPBarVisibility = () => {
        if (isRSPBarVisible) {
            setMenuOpen(false);
            setMenuOpen1(false);
            setMenuOpen2(false);
            setMenuOpen3(false);
            setRspBarHeight(`${rspBarRef.current.scrollHeight}px`);
            setTimeout(() => {
                setRspBarHeight('0px');
                setTimeout(() => {
                    setIsTransitioning(false);
                }, 800);
            }, 10);
        } else {
            setRspBarHeight('auto');
            setTimeout(() => {
                setRspBarHeight(`${rspBarRef.current.scrollHeight}px`);
                setTimeout(() => {
                    setIsTransitioning(false);
                }, 800);
            }, 10);
        }
        setIsRSPBarVisible(!isRSPBarVisible);
    };

    const handleRegistration = () => {
        if (isAdmin || isRector || isDean) {
            setIsVisibleRegistration(true);
        }
    };

    const closeRegistration = () => {
        setIsVisibleRegistration(false);
    };

    const handleCreateRSP = () => {
        if(isAdmin || isDean || isRector){
            navigate('/createRSP');
        }
    }

    const handlePlanMeeting = () => {
        if (isAdmin || role === 6) {
            setIsMeetingFormVisible(true);
        }
    };

    const closePlanMeeting = () => {
        setIsMeetingFormVisible(false);
    };

    const handleDeleteMeeting = () => {
        if (isAdmin || role === 6) {
            navigate("/cancelMeeting", { state: { role } });
        }
    };

    const handleAddDocumentMeeting = () => {
        if(isAdmin || isRector || (role !== null && role !== undefined) || isDeanFacultyRSP){
            navigate("/AddDocumentMeeting");
        }
    }

    const handleDocumentMeetingList = () => {
        if(!!isAdmin || !!isRector || (role !== null && role !== undefined) || isDeanFacultyRSP){
            navigate('/DocumentListMeetingPage');
        }
    }

    const handleRequest = () => {
        if(!!isAdmin || (role !== null && role !== undefined)){
            setIsRequestFormVisible(true);
        }
    }

    const closeRequest = () => {
        setIsRequestFormVisible(false);
    }

    const handleProposalPage = () => {
        if(!!isAdmin || !!isRector || (role !== null && role !== undefined) || isDeanFacultyRSP){
            navigate('/ProposalList');
        }
    }

    const handleCreatePoll = () => {
        if(!isAdmin && (role === null && role === undefined) && !isLocked && !isDeanFacultyRSP && !isRector){
            return;
        }
        navigate('/createPoll');
    }

    const handleVote = () => {
        if(!isAdmin && (role === null && role === undefined) && !isLocked && !isDeanFacultyRSP && !isRector){
            return;
        }
        navigate('/pollList');
    }

    const handlePollManagmentList = () => {
        if(!!isAdmin || !!isRector || (role !== null && role !== undefined) || isDeanFacultyRSP) {
            navigate('/pollManagmentList');
        }
    }

    const handleMemberManagement = () => {
        if(!isAdmin && role !== 6 && !isLocked && !isDeanFacultyRSP && !isRector){
            return;
        }
        navigate('/memberManagment');
    }

    const handleFeedbackList = () => {
        if(!!isAdmin || !!isRector || (role !== null && role !== undefined) || isDeanFacultyRSP) {
            navigate('/feedbackListPage');
        }
    }

    const handleOpenEvalutionFrom = () => {
        if(!!isAdmin || !!isRector || (role !== null && role !== undefined) || isDeanFacultyRSP) {
            setIsEvaluationFormVisible(true);
        }
    };

    const handleEvaluationPage = () => {
        if(!!isAdmin || !!isRector || (role !== null && role !== undefined) || isDeanFacultyRSP) {
                navigate('/evaluationListPage');
        }
        
    }

    return (
        <div className={`rsp-bar ${isTransitioning ? 'hidden' : ''}`}>
            <div className='row'>
                <div className='col-lg-9 col-md-9 col-sm-9'>
                    <h2 className="title" style={{ textAlign: "left" }}>Možnosti RŠP</h2>
                </div>
                <div id='arrow' className='col-lg-3 col-md-3 col-sm-3'>
                    {isRSPBarVisible ? (
                        <FaChevronUp onClick={toggleRSPBarVisibility} style={{ cursor: 'pointer' }} />
                    ) : (
                        <FaChevronDown onClick={toggleRSPBarVisibility} style={{ cursor: 'pointer' }} />
                    )}
                </div>
            </div>
            <hr id="line" />
            <div className="dropdown-menu-container" ref={rspBarRef} style={{ maxHeight: rspBarHeight, overflow: 'hidden', transition: 'max-height 0.5s ease-in-out' }}>
                {/* 1 option */}
                {isLoading && (
                    <div className="loading-spinner-component">
                    <div className="spinner"></div>
                    </div>
                )}
                <div className={`menu-header ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <span className="menu-title">Správa Rady Študijného programu</span>
                    {isMenuOpen ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                <div ref={contentRef} className="menu-content">
                    <div className="menu-item" onClick={!isLocked ? handleMemberManagement : undefined}>
                        <span>Spravovať členstvá RŠP</span>
                        {isLocked && <FaLock />}
                        {(!isAdmin && role !== 6 && !isLocked && !isDeanFacultyRSP && !isRector) && <FaLock />}
                    </div>
                    <div className="menu-item" onClick={!isLocked ? () => {} : undefined}>
                        <span>Aktivita členov RŠP</span>
                        {isLocked && <FaLock />}
                        {(!isAdmin && role !== 6 && !isLocked && !isDeanFacultyRSP && !isRector) && <FaLock />}
                    </div>
                    <div className="menu-item" onClick={!isLocked ? () => {} : undefined}>
                        <span>Odstrániť RŠP</span>
                        {isLocked && <FaLock />}
                        {(!isAdmin && !isLocked && !isDeanFacultyRSP && !isRector) && <FaLock />}
                    </div>
                </div>

                {/* 2 option */}
                <div className={`menu-header ${isMenuOpen1 ? 'open' : ''}`} onClick={toggleMenu1}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <span className="menu-title">Akcie Rady Študijného programu</span>
                    {isPollingAvailable && <FaExclamationTriangle  style={{ marginLeft: "10px", marginTop: "6px" }} />}
                </div>
                    {isMenuOpen1 ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                <div ref={contentRef1} className="menu-content">
                    <div className="menu-item" onClick={!isLocked ? handleCreatePoll : undefined}>
                        <span>Vytvoriť hlasovanie</span>
                        {isLocked && <FaLock />}
                        {(!isAdmin && (role === null && role === undefined) && !isLocked && !isDeanFacultyRSP && !isRector) && <FaLock />}
                    </div>
                    <div className={`menu-item`} onClick={!isLocked ? handleVote : undefined}>
                        <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            Hlasovať 
                            {isPollingAvailable && <FaExclamationTriangle  />}
                        </span>
                        {isLocked && <FaLock />}
                        {(!isAdmin && (role === null && role === undefined) && !isLocked && !isDeanFacultyRSP && !isRector) && <FaLock />}
                    </div>
                    <div className="menu-item" onClick={!isLocked ? handleOpenEvalutionFrom : undefined}>
                        <span>Pridať záznam evaluácie predmetu</span>
                        {isLocked && <FaLock />}
                        {(!isAdmin && !isRector && (role === null || role === undefined) && !isDeanFacultyRSP && !isLocked) && <FaLock />}
                    </div>
                    <div className="menu-item" onClick={!isLocked ? handlePlanMeeting : undefined}>
                        <span>Naplánovať zasadnutie rady</span>
                        {isLocked && <FaLock />}
                        {(!isAdmin && role !== 6 && !isLocked) && <FaLock />}
                    </div>
                    <div className="menu-item" onClick={!isLocked ? handleDeleteMeeting : undefined}>
                        <span>Zrušiť zasadnutie rady</span>
                        {isLocked && <FaLock />}
                        {(!isAdmin && role !== 6 && !isLocked) && <FaLock />}
                    </div>
                    <div className="menu-item" onClick={!isLocked ? handleAddDocumentMeeting : undefined}>
                        <span>Pridať dokument k zasadnutiu</span>
                        {isLocked && <FaLock />}
                        {(!isAdmin && !isRector && (role === null || role === undefined) && !isDeanFacultyRSP && !isLocked) && <FaLock />}
                    </div>
                    <div className="menu-item" onClick={!isLocked ? handleRequest : undefined}>
                        <span>Podať návrh alebo žiadosť</span>
                        {isLocked && <FaLock />}
                        {(!isAdmin && (role === null || role === undefined) && !isLocked) && <FaLock />}
                    </div>
                </div>

                {/* 3 option */}
                <div className={`menu-header ${isMenuOpen2 ? 'open' : ''}`} onClick={toggleMenu2}>
                    <span className="menu-title">Dokumenty Rady Študijného programu</span>
                    {isMenuOpen2 ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                <div ref={contentRef2} className="menu-content">
                    <div className="menu-item" onClick={handleEvaluationPage}>
                        <span>Záznamy evaluácií </span>
                        {isLocked && <FaLock />}
                        {(!isAdmin && !isRector && (role === null || role === undefined) && !isDeanFacultyRSP && !isLocked) && <FaLock />}
                    </div>
                    <div className="menu-item" onClick={handleFeedbackList}>
                        <span>Podnety k študijnému programu</span>
                        {isLocked && <FaLock />}
                        {(!isAdmin && !isRector && (role === null || role === undefined) && !isDeanFacultyRSP && !isLocked) && <FaLock />}
                    </div>
                    <div className="menu-item" onClick={!isLocked ? handleDocumentMeetingList : undefined}>
                        <span>Dokumenty zasadnutiam</span>
                        {isLocked && <FaLock />}
                        {(!isAdmin && !isRector && (role === null || role === undefined) && !isDeanFacultyRSP && !isLocked) && <FaLock />}
                    </div>
                    <div className="menu-item" onClick={!isLocked ? handleProposalPage : undefined}>
                        <span>Žiadosti a návrhy</span>
                        {isLocked && <FaLock />}
                        {(!isAdmin && !isRector && (role === null || role === undefined) && !isDeanFacultyRSP && !isLocked) && <FaLock />}
                    </div>
                    <div className="menu-item" onClick={!isLocked ? handlePollManagmentList : undefined}>
                        <span>Vytvorené hlasovania a výsledky</span>
                        {isLocked && <FaLock />}
                        {(!isAdmin && !isRector && (role === null || role === undefined) && !isDeanFacultyRSP && !isLocked) && <FaLock />}
                    </div>
                </div>

                {/* 4 option  */}
                <div className={`menu-header ${isMenuOpen3 ? 'open' : ''}`} onClick={toggleMenu3}>
                    <span className="menu-title">Ostatné</span>
                    {isMenuOpen3 ? <FaChevronUp /> : <FaChevronDown />}
                </div>
                <div ref={contentRef3} className="menu-content">
                    <div className="menu-item" onClick={handleCreateRSP}>
                        <span>Vytvoriť radu študijného programu</span>
                        {(!isAdmin && !isRector && !isDean && !isLocked) && <FaLock />}
                    </div>
                    <div className="menu-item" onClick={handleRegistration} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span>Odoslať registračný formulár</span>
                        {(!isAdmin && !isRector && !isDean && !isLocked) && <FaLock />}
                    </div>
                    <div className="menu-item" onClick={handleShowModal}>
                        <span>Podať podnet</span>
                    </div>
                </div>
            </div>
            <RegistrationModal isVisible={isVisibleRegistration} onClose={closeRegistration}></RegistrationModal>
            <PlanMeetingForm isVisible={isMeetingFormVisible} onClose={closePlanMeeting} />
            <FileRequestFrom isVisible={isRequestFormVisible} onClose={closeRequest} />
            <FeedbackForm isVisible={showModal} onClose={handleCloseModal}/>
            <EvaluationForm isVisible={isEvaluationFormVisible} onClose={handleCloseEvalutionFrom}/>
            
        </div>
    )
};

export default RSPBar;
