import React, { useState, useEffect, useContext } from 'react';
import "../RegistrationModal/RegistrationModal.css";
import { CalendarContext } from '../../Contexts/CalendarContext';

const PlanMeetingForm = ({ isVisible, onClose }) => {
    const {setShouldResetCalendar } = useContext(CalendarContext);
    const [dateStart, setDateStart] = useState('');
    const [place, setPlace] = useState('');
    const [link, setLink] = useState('');
    const [showClass, setShowClass] = useState('');
    const [blinkClass, setBlinkClass] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [succesSend, setSuccesSend] = useState(false);

    useEffect(() => {
        if (isVisible) {
            setShowClass('show');
        } else {
            setShowClass('');
        }
    }, [isVisible]);

    useEffect(() => {
        if (succesSend) {
            setSuccesSend(false);
        }
    }, [isVisible]);

    const handlePlanMeeting = async (e) => {
        e.preventDefault();
        setLoading(true);
        const now = new Date();
        const selectedDate = new Date(dateStart);

        if (selectedDate < now) {
            setMessage('Dátum stretnutia nemôže byť v minulosti');
            setLoading(false);
            return;
        }

        try {
            const token = localStorage.getItem("token");
            const rspId = localStorage.getItem("selectedProgramId");

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/planMeeting`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ dateStart, place, link, rspId, token }),
            });

            setLoading(false);
            if (response.ok) {
                setMessage(null);
                setDateStart('');
                setPlace('');
                setLink('');
                setShouldResetCalendar(true);
                setTimeout(() => {
                    setShouldResetCalendar(false);
                }, 0);
                setSuccesSend(true);
            } else {
                const result = await response.json();
                setMessage(result.message || 'Problém pri naplánovaní zasadnutia');
            }
        } catch (error) {
            setMessage('Problém pri naplánovaní zasadnutia');
            setLoading(false);
        }
    };

    const handleOverlayClick = () => {
        setBlinkClass('blink');
        setTimeout(() => {
            setBlinkClass('');
        }, 300);
    };

    return (
        <div className={`modal-overlay ${showClass} ${blinkClass}`} onClick={handleOverlayClick}>
            {loading && <div className="loading-spinner"></div>}
            <div className={`modal-content2 ${showClass} ${blinkClass}`} onClick={(e) => e.stopPropagation()}>
                {!succesSend && (
                    <>
                        <button className="close-button" onClick={onClose}>&times;</button>
                        <h2 id="titleModal" style={{marginBottom: "5px"}} >Plánovanie zasadnutia RŠP</h2>
                        <form onSubmit={handlePlanMeeting}>
                            <input
                                type="datetime-local"
                                placeholder="Start Date and Time"
                                value={dateStart}
                                onChange={(e) => setDateStart(e.target.value)}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Miesto"
                                value={place}
                                onChange={(e) => setPlace(e.target.value)}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Link (voliteľné)"
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                            />
                            <span style={{ color: "red", marginBottom: "15px", textAlign: "left", fontSize: "16px" }}>{message}</span>
                            <button type="submit" className="login-button">Naplánovať zasadnutie</button>
                        </form>
                    </>
                )}
                {succesSend && (
                    <>
                        <button className="close-button" onClick={onClose}>&times;</button>
                        <h2 id="titleModal">Zasadnutie úspešne naplánované</h2>
                        <button className="ok-button" onClick={onClose}>Ok</button>
                    </>
                )}
            </div>
        </div>
    );
};

export default PlanMeetingForm;
