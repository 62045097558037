import React, { useState, useEffect, useContext } from 'react';
import { ProgramContext } from '../../Contexts/ProgramContext';
import './AddDocumentMeetingPage.css';
import Navbar from '../../Components/Navbar/Navbar';
import Footer from '../../Components/Footer/Footer';
import AddDocumentMeeting from '../../Components/AddDocumentMeeting/AddDocumentMeeting';

const AddDocumentMeetingPage = () => {
    return (
        <div>
           <div>
            <Navbar></Navbar>
           </div>
           <div style={{  marginTop: "120px" }}>
                <AddDocumentMeeting></AddDocumentMeeting>
           </div>
           <div style={{marginTop: "150px"}}>
            <Footer></Footer>
           </div>
        </div>
    )
}


export default AddDocumentMeetingPage;