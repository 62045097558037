import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation } from 'react-router-dom';

import useTokenChecker from './Contexts/tokenChecker.jsx';
import Home from './Pages/Home/Home.jsx';
import Registration from './Pages/Registration/Registration.jsx';
import CreateRSP from './Pages/CreateRSP/CreateRSP.jsx';
import CancelMeetingPage from './Pages/CancelMeetingPage/CancelMeetingPage.jsx';
import AddDocumentMeetingPage from './Pages/AddDocumentMeetingPage/AddDocumentMeetingPage.jsx';
import DocumentListMeetingPage from './Pages/DocumentListMeetingPage/DocumentListMeetingPage.jsx';
import MeetingDocumentPage from './Pages/MeetingDocumentPage/MeetingDocumentPage.jsx';
import ProposalPage from './Pages/ProposalPage/ProposalPage.jsx';
import CreatePollPage from './Pages/CreatePollPage/CreatePollPage.jsx';
import PollListPage from './Pages/PollListPage/PollListPage.jsx';
import VotePollPage from './Pages/VotePollPage/VotePollPage.jsx';
import PollManagmentListPage from './Pages/PollManagmentListPage/PollManagmentListPage.jsx';
import PollResultsPage from './Pages/PollResultsPage/PollResultsPage.jsx';
import MemberManagementPage from './Pages/MemberManagmentPage/MemberManagmentPage.jsx';
import FeedbackListPage from './Pages/FeedbackListPage/FeedbackListPage.jsx';
import EvaluationListPage from './Pages/EvaluationListPage/EvaluationListPage.jsx';

//Responzivny dizajn je pre obrazvovky nad 359px 
function App() {
    const navigate = useNavigate();
    const [tokenLifetime, setTokenLifetime] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const removeLogOut = async () => {
            if (localStorage.getItem("LogOut") !== null) {
                localStorage.removeItem("LogOut");
            }
            if (localStorage.getItem("againLogin") !== null) {
                localStorage.removeItem("againLogin");
            }
        };
      
        const intervalId = setInterval(removeLogOut, 600000);
        return () => clearInterval(intervalId);
    }, []);

    const parseTokenLifetime = (tokenLifetime) => {
        if (tokenLifetime !== null) {
            const match = tokenLifetime.match(/(\d+)([smhd])/);
            if (!match) return 0;

            const value = parseInt(match[1], 10);
            const unit = match[2];

            switch (unit) {
                case 's':
                    return value * 1000;
                case 'm':
                    return value * 60 * 1000;
                case 'h':
                    return value * 60 * 60 * 1000;
                case 'd':
                    return value * 24 * 60 * 60 * 1000;
                default:
                    return 0;
            }
        } else {
            return 0;
        }
    };

    useEffect(() => {
        if (!location.pathname.startsWith("/registration")) {
            const fetchTokenLifetime = async () => {
                const serverUrl = process.env.REACT_APP_SERVER_URL;
                try {
                    const response = await fetch(`${serverUrl}/api/auth/tokenLifeTime`);
                    const data = await response.json();
                    setTokenLifetime(parseTokenLifetime(data.tokenLifeTime));
                    localStorage.setItem('tokenLifetime', data.tokenLifeTime);
                } catch (error) {
                    console.error('Error fetching token lifetime:', error);
                }
            };

            fetchTokenLifetime();
            const intervalId = setInterval(fetchTokenLifetime, 3600000);

            return () => clearInterval(intervalId);
        }
    }, []);


    useEffect(() => {
        if (!location.pathname.startsWith("/registration")) {
            const controlToken = async () => {
                if (localStorage.getItem("token") !== null) {
                    const tokenValidityDuration = tokenLifetime;
                    const token = localStorage.getItem('token');
                    const tokenIssuedAt = localStorage.getItem('tokenIssuedAt');
                    if (token && tokenIssuedAt) {
                        const currentTime = Date.now();
                        const tokenAge = currentTime - parseInt(tokenIssuedAt, 10);
                        const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
                        if (tokenAge > tokenValidityDuration && tokenAge > oneDayInMilliseconds) {
                            localStorage.removeItem('token');
                            localStorage.removeItem('user');
                            localStorage.removeItem('tokenIssuedAt');
                            localStorage.removeItem('tokenLifetime');
                            localStorage.removeItem("selectedProgramId");
                            navigate('/ss');
                        }
                    } else {
                        navigate('/');
                    }
                }
            };

            controlToken();
        }
    }, [location, tokenLifetime]);

    useTokenChecker(tokenLifetime); 

    return (
        <div>
            <div>
                <Routes>   
                    <Route path='/' element={<Home />} />
                    <Route path="/registration/:registrationCode" element={<Registration />} />
                    <Route path="/createRSP" element={<CreateRSP />} />
                    <Route path="/cancelMeeting" element={<CancelMeetingPage />} />
                    <Route path="/AddDocumentMeeting" element={<AddDocumentMeetingPage />} />
                    <Route path="/DocumentListMeetingPage" element={<DocumentListMeetingPage />} />
                    <Route path="/meetingDocuments/:meetingId/:meetingIndex" element={<MeetingDocumentPage />} />
                    <Route path="/ProposalList" element={<ProposalPage />} />
                    <Route path="/createPoll" element={<CreatePollPage />} />
                    <Route path="/pollList" element={<PollListPage />} />
                    <Route path="/votePoll/:pollId" element={<VotePollPage />} />
                    <Route path="/pollManagmentList" element={<PollManagmentListPage />} />
                    <Route path="/pollResult/:pollId" element={<PollResultsPage />} />
                    <Route path="/memberManagment" element={<MemberManagementPage />} />
                    <Route path="/feedbackListPage" element={<FeedbackListPage />} />
                    <Route path="/evaluationListPage" element={<EvaluationListPage />} />
                </Routes>
            </div>
        </div>
    );
}

export default App;
