import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { FaChevronDown, FaChevronUp, FaFile, FaTrashAlt } from 'react-icons/fa';
import './DocumentListMeeting.css';
import CryptoJS from 'crypto-js';
import image from '../../assets/images/errorDocument.png';
import MessageModal from '../MessageModal/MessageModal';
import ConfirmModal from '../ConfrimModal/ConfirmModal';

const DocumentListMeeting = () => {
    const { meetingId, meetingIndex } = useParams();
    let decodedMeetingId = null;
    let decodedMeetingIndex = null;
    try {
        decodedMeetingId = customDecode(meetingId);
        decodedMeetingIndex = customDecode(meetingIndex);
    } catch (error) {
        console.error('Error decoding meeting ID or index:', error);
    }

    const [existingFiles, setExistingFiles] = useState([]);
    const [visibleDocuments, setVisibleDocuments] = useState(true);
    const [documentHeight, setDocumentHeight] = useState('0px');
    const documentRefs = useRef({});
    const token = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('user'));

    const [isConfirmVisible, setIsConfirmVisible] = useState(false);
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        const fetchExistingFiles = async () => {
            const token = localStorage.getItem("token");
            console.log(decodedMeetingId);
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getFilesByMeetingId`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ meetingId: decodedMeetingId, token }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setExistingFiles(data);
                    setTimeout(() => {
                        const scrollHeight = documentRefs.current.scrollHeight;
                        setDocumentHeight(`${scrollHeight}px`); 
                    }, 10);
                }
            } catch (error) {
                console.error('Error fetching files:', error);
            }
        };

        fetchExistingFiles();
    }, [decodedMeetingId, token]);

    const toggleDocumentsVisibility = () => {
        const element = documentRefs.current;
        const isVisible = visibleDocuments;

        const numberOfDocuments = existingFiles.length || 0;
        const individualDelay = 0.4;
        const totalDelay = numberOfDocuments * individualDelay;
        const transitionDuration = isVisible ? `${totalDelay}s` : `${totalDelay}s`;

        element.style.transition = `max-height ${transitionDuration} ease`;

        setVisibleDocuments(!visibleDocuments);

        setTimeout(() => {
            if (isVisible) {
                element.style.transition = `max-height ${totalDelay}s ease`;
                setDocumentHeight('0px');
            } else {
                const scrollHeight = element.scrollHeight;
                element.style.transition = `max-height ${totalDelay}s ease`;
                setDocumentHeight(`${scrollHeight}px`);
            }
        }, 10);
    };

    const handleDeleteFile = (fileId, fileName) => {
        setFileToDelete({ fileId, fileName });
        setIsConfirmVisible(true);
        setMessage(`Naozaj si prajete vymazať súbor: ${fileName}?`);
    };

    const confirmDeleteFile = async () => {
        if (fileToDelete) {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/deleteDocument`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ fileId: fileToDelete.fileId, token }),
                });

                if (response.ok) {
                    setExistingFiles(existingFiles.filter(file => file.idFile !== fileToDelete.fileId));
                    setFileToDelete(null);
                    setIsConfirmVisible(false);
                    setIsMessageVisible(true);
                    setMessage(`Súbor: ${fileToDelete.fileName} bol úspešne odstránený.`);
                } else {
                    alert("Nepodarilo sa vymazať súbor");
                }
            } catch (error) {
                alert("Nepodarilo sa vymazať súbor");
            }
        }
    };

    const handleFileClick = (fileId) => {
        window.open(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getFile/${fileId}/${token}`, '_blank');
    };

    const handleLeftButtonFunction = () => {
        setFileToDelete(null);
        setIsConfirmVisible(false);
    };

    const handleRightButtonFunction = () => {
        confirmDeleteFile();
    };

    const messageModalButtonFunction = () => {
        setIsMessageVisible(false);
    };

    if (decodedMeetingId === null) {
        return (
            <div style={{ marginTop: "-60px" }}>
                <div className="centered-container">
                    <h2 className="centered-text">Neplatný odkaz</h2>
                </div>
                <div className="centered-container-img" style={{ marginTop: "10px" }}>
                    <img src={image} alt="Invalid code illustration" />
                </div>
            </div>
        );
    }

    return (
        <div className="document-list-wrapper">
            <ConfirmModal
                isVisible={isConfirmVisible}
                message={message}
                leftButtonText="Nie"
                rightButtonText="Áno"
                leftButtonFunction={handleLeftButtonFunction}
                rightButtonFunction={handleRightButtonFunction}
            />
            <MessageModal
                isVisible={isMessageVisible}
                message={message}
                buttonFunction={messageModalButtonFunction}
            />
            <div className="document-list-container">
                <h2 className="document-list-title">Dokumenty k zasadnutiu {decodedMeetingIndex}</h2>
                <div className="existing-files-wrapper2">
                    {existingFiles.length > 0 && (
                        <>
                            <div className="toggle-container2" onClick={toggleDocumentsVisibility}>
                                <span>{visibleDocuments ? 'Skryť dokumenty' : 'Zobraziť dokumenty'}</span>
                                {visibleDocuments ? <FaChevronUp className='chevron' /> : <FaChevronDown className='chevron' />}
                            </div>
                            <div
                                className="existing-files-container2"
                                ref={documentRefs}
                                style={{ maxHeight: documentHeight, overflowY: 'auto', transition: 'max-height 0.6s ease' }}
                            >
                                <ul className="existing-files-list2">
                                    {existingFiles.map((file, index) => (
                                        <li key={index} className="existing-file-item2">
                                            <div className="file-info">
                                                <FaFile className="file-icon" />
                                                <span className="file-name" onClick={() => handleFileClick(file.idFile)}>{file.fileName}</span>
                                                <span className="file-author">Autor: {file.fileAuthor}</span>
                                                {(userData.id === file.idUser || userData.admin === '1' || localStorage.getItem('role') === '6') && (
                                                    <FaTrashAlt className="delete-icon2" onClick={() => handleDeleteFile(file.idFile, file.fileName)} />
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </>
                    )}
                    {existingFiles.length === 0 && (
                        <div>
                            <div className="centered-container" style={{ marginTop: "80px" }}>
                                <h2 className="centered-text" style={{ fontSize: "26px" }}>Zasadnutie nemá žiadne existujúce dokumenty</h2>
                            </div>
                            <div className="centered-container-img" style={{ marginTop: "10px", marginBottom: "100px" }}>
                                <img src={image} alt="No documents available" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DocumentListMeeting;

const customDecode = (encoded) => {
    const decodedBase64 = atob(encoded); 
    const strippedPrefix = decodedBase64.replace('customPrefix', ''); 
    const reversedBase64 = strippedPrefix.split('').reverse().join('');
    const parsedWordArray = CryptoJS.enc.Base64.parse(reversedBase64);
    const decoded = CryptoJS.enc.Utf8.stringify(parsedWordArray);
    return decoded;
};
