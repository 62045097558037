import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginModal from '../Components/LoginModal/LoginModal';

const useTokenChecker = (tokenLifetime)  => {
    const navigate = useNavigate();
    const [activity, setActivity] = useState(true);
    const [timer, setTimer] = useState(0); // budem ho merat v minutach
    const timerRef = useRef(timer);
    const serverURL =  process.env.REACT_APP_SERVER_URL;

    //Poziadavka pre obnovenie tokenu
    const refreshToken = async () => {
        const token = localStorage.getItem("token");
        if(token === null || token === undefined){
            return;
        }
        try{
            const response = await fetch(`${serverURL}/api/auth/refreshToken`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ token }),
            });
            if (!response.ok) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('tokenIssuedAt');
                localStorage.removeItem('tokenLifetime');
                localStorage.removeItem("selectedProgramId");
                localStorage.setItem('againLogin', "1");
                localStorage.setItem('showModalLogin', "1");
                navigate('/ss');
                return;
            }
            else{
                const data = await response.json();
                localStorage.removeItem("token");
                localStorage.setItem('token', data.newToken);
                localStorage.setItem('tokenIssuedAt', Date.now().toString());
            }
        } catch (error) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('tokenIssuedAt');
            localStorage.removeItem('tokenLifetime');
            localStorage.removeItem("selectedProgramId");
            localStorage.setItem('againLogin', "1");
            localStorage.setItem('showModalLogin', "1");
            navigate('/ss');
            return;
          }
    };

    //Overovanie aktivity a obnovenie tokenu
    useEffect(() => {
        const checkTokenValidity = () => {
            if (!tokenLifetime) return;
            const tokenValidityDuration = tokenLifetime;
            const token = localStorage.getItem('token');
            const tokenIssuedAt = localStorage.getItem('tokenIssuedAt');
            if (token && tokenIssuedAt) {
                const currentTime = Date.now();
                const tokenAge = currentTime - parseInt(tokenIssuedAt, 10);

                if(activity === false){
                    setActivity(true);
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    localStorage.removeItem('tokenIssuedAt');
                    localStorage.removeItem('tokenLifetime');
                    localStorage.removeItem("selectedProgramId");
                    localStorage.setItem('LogOut', "1");
                    localStorage.setItem('showModalLogin', "1");
                    navigate('/ss');
                }

                else if(activity === true){
                    if (tokenAge > tokenValidityDuration) {
                        if(localStorage.getItem("token") !== null || localStorage.getItem("token") !== undefined){
                            refreshToken();
                            return;
                        }
                    }
                }
                
            } else {
                navigate('/');
            }
        };

        checkTokenValidity();

        const intervalId = setInterval(checkTokenValidity, 6000);

        return () => clearInterval(intervalId);
    }, [tokenLifetime, navigate, activity]);


    //Casovac nastaveny na minuty meria cas od vykonania poslednej aktivity
    useEffect(() => {
        if(localStorage.getItem("token") === null) return;
         const addMinute = () => {
            setTimer(prevTimer => {
                const newTimer = prevTimer + 1;
                if (newTimer >= 30) {
                setActivity(false);
                }
                return newTimer;
            });
        };

        addMinute();

        const intervalId = setInterval(addMinute, 60000);

        return () => clearInterval(intervalId);
    }, [activity]);


    // Resetovanie casovaca pri vykonani aktivity
    useEffect(() => {
        const handleClick = () => {
            setTimer(0);
        };

        const handleKeyDown = (event) => {
            setTimer(0);
        };
      
          window.addEventListener('click', handleClick);
          window.addEventListener('keydown', handleKeyDown);
      
          return () => {
            window.removeEventListener('click', handleClick);
            window.removeEventListener('keydown', handleKeyDown);
          };

    }, [timer]);

};

export default useTokenChecker;
