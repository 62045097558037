import React, { useState, useEffect } from 'react';
import { FaUserPlus, FaTrashAlt, FaExclamationTriangle, FaCheckCircle, FaSyncAlt } from 'react-icons/fa';
import defaultUserImage from '../../assets/images/profile.png';
import ConfirmModal from '../ConfrimModal/ConfirmModal';
import MessageModal from '../MessageModal/MessageModal';
import './MemberManagment.css';
import image from '../../assets/images/codeNotValid.png';

const MemberManagement = () => {
    const [members, setMembers] = useState([]);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [showAddMemberModal, setShowAddMemberModal] = useState(false);
    const [isConfirmVisible, setIsConfirmVisible] = useState(false);
    const [isMessageVisible, setIsMessageVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [memberToRemove, setMemberToRemove] = useState(null);
    const [memberToRenew, setMemberToRenew] = useState(null); // Added for renewing
    const [loading, setLoading] = useState(false);
    const [loadingAction, setLoadingAction] = useState(false); 
    const [programType, setProgramType] = useState('');
    const [programName, setProgramName] = useState('');
    const [userImages, setUserImages] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const programId = localStorage.getItem('selectedProgramId');
    const [accessDenied, setAccessDenied] = useState(false);

    useEffect(() => {
        const fetchMembersAndUsers = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const userData = JSON.parse(localStorage.getItem('user'));
                const role = localStorage.getItem('role');

                const isDeanFacultyRSPResposne = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/isDeanFacultyRSP`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ programId }),
                });

                const membersResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/getRSPMembers`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ programId }) 
                });

                const usersResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/auth/getAllUsers`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token }),
                });

                const programResponse = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/${programId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
                const isDeanFacultyRSP = await isDeanFacultyRSPResposne.json();
                const membersData = await membersResponse.json();
                const usersData = await usersResponse.json();
                const programData = await programResponse.json();

                if (membersResponse.ok && usersResponse.ok && programResponse.ok) {
                    if(userData.admin !== 1 && userData.rector !== 1 && !isDeanFacultyRSP && role !== '6'){
                        setAccessDenied(true);
                        setProgramName(programData.name);
                    } else {
                        setMembers(membersData);
                        setProgramType(programData.combineRSP);
                        setProgramName(programData.name);
                        convertUserImages(usersData);
                        const filteredUsers = usersData.filter(user => !membersData.some(member => member.email === user.email));
                        setAvailableUsers(filteredUsers);
                    }

                } else {
                    console.error('Failed to fetch members, users, or program data.');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMembersAndUsers();
    }, [programId]);

    const handleAddMemberClick = (role) => {
        setSelectedMember(role);
        setShowAddMemberModal(true);
        setTimeout(() => {
            setModalVisible(true); 
        }, 10);
    };

    const handleModalClose = () => {
        setModalVisible(false);
        setTimeout(() => {
            setShowAddMemberModal(false);
        }, 500);
    };

    const convertUserImages = (users) => {
        const images = {};
        users.forEach(user => {
            if (user.picture && user.picture.data) {
                const isPng = user.picture.toString('hex', 0, 4) === '89504e47';
                const isJpeg = user.picture.toString('hex', 0, 2) === 'ffd8';
                const isWebp = user.picture.toString('hex', 0, 4) === '52494646' && user.picture.toString('hex', 8, 12) === '57454250';

                let mimeType = 'image/png'; // default to PNG
                if (isJpeg) mimeType = 'image/jpeg';
                if (isWebp) mimeType = 'image/webp';

                const blob = new Blob([new Uint8Array(user.picture.data)], { type: mimeType });
                const url = URL.createObjectURL(blob);
                images[user.email] = url;
            }
        });
        setUserImages(images);
    };

    const handleMemberSelect = async (user) => {
        if (user.countMembership >= 3 || user.isGraduateEnsurer || user.isStudentRepresentative) {
            return;
        }
    
        handleModalClose();
        setLoadingAction(true); 
    
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/addMember`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ memberId: user.idUser, programId, role: selectedMember })
            });
    
            if (response.ok) {
                setMembers(prevMembers => [
                    ...prevMembers, 
                    { ...user, idRole: selectedMember } 
                ]);
                setAvailableUsers(prevUsers => prevUsers.filter(u => u.idUser !== user.idUser));
                setMessage(`${user.name} bol úspešne pridaný.`);
                setIsMessageVisible(true);
            } else {
                console.error('Failed to add member:', await response.json());
                setMessage(`Uživateľa ${user.name} sa nepodarilo úspešne pridať na pozíciu.`);
                setIsMessageVisible(true);
            }
        } catch (error) {
            console.error('Error adding member:', error);
            setMessage(`Uživateľa ${user.name} sa nepodarilo úspešne pridať na pozíciu.`);
            setIsMessageVisible(true);
        } finally {
            setLoadingAction(false);
        }
    };

    const handleRemoveMemberClick = (member) => {
        setMemberToRemove(member);
        setIsConfirmVisible(true);
    };

    const handleConfirmRemove = async () => {
        setLoadingAction(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/removeMember`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ memberId: memberToRemove.idUser, programId })
            });
    
            if (response.ok) {
                setMembers(prevMembers => prevMembers.filter(member => member.idUser !== memberToRemove.idUser));
                setAvailableUsers(prevUsers => [...prevUsers, memberToRemove]);
                setMessage(`${memberToRemove.name} bol úspešne odstránený.`);
                setIsConfirmVisible(false);
                setIsMessageVisible(true);
            } else {
                console.error('Failed to remove member:', await response.json());
                setIsConfirmVisible(false);
                setIsMessageVisible(true);
                setMessage(`Uživateľa ${memberToRemove.name} sa nepodarilo odstrániť.`);
            }
        } catch (error) {
            console.error('Error removing member:', error);
            setIsConfirmVisible(false);
            setIsMessageVisible(true);
            setMessage(`Uživateľa ${memberToRemove.name} sa nepodarilo odstrániť.`);
        } finally {
            setLoadingAction(false); 
        }
    };

    const handleRenewMembershipClick = (member) => {
        setMemberToRenew(member);
        setIsConfirmVisible(true);
    };

    const handleConfirmRenew = async () => {
        setLoadingAction(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/rsp/renewMembership`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ memberId: memberToRenew.idUser, programId, role: memberToRenew.idRole })
            });

            if (response.ok) {
                const updatedMember = await response.json();
                console.log(updatedMember);
                setMembers((prevMembers) =>
                    prevMembers.map((member) =>
                        member.idUser === updatedMember.idUser
                            ? { ...member, ...updatedMember } 
                            : member
                    )
                );
                setMessage('Členstvo bolo úspešne obnovené.');
            } else {
                setMessage('Nepodarilo sa obnoviť členstvo.');
            }
        } catch (error) {
            console.error('Error renewing membership:', error);
            setMessage('Nepodarilo sa obnoviť členstvo.');
        } finally {
            setLoadingAction(false);
            setIsConfirmVisible(false);
            setIsMessageVisible(true);
        }
    };

    const handleCancelRemove = () => {
        setIsConfirmVisible(false);
        setTimeout(() => {
            setMemberToRemove(null);
            setMemberToRenew(null);
        }, 800);
    };

    const handleMessageModalClose = () => {
        setIsMessageVisible(false);
    };

    const filteredUsers = availableUsers.filter(user =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    
    const totalRequiredMembers = programType === 1 ? 9 : 8;
    const totalCurrentMembers = members.length;
    const isComplete = totalCurrentMembers >= totalRequiredMembers;

    if(accessDenied){
        return (
            <div className="member-management-container">
                <div className="header-container">
                <h2 className="member-management-title">Správa členov rady</h2>
                <div className="program-name-container">
                    <h2 className="label-title2">Program {programName}</h2>
                    <div className="member-count">
                        <span>{totalCurrentMembers}/{totalRequiredMembers}</span>
                        Počet členov {isComplete ? <FaCheckCircle color="green" /> : <FaExclamationTriangle color="red" />}
                    </div>
                </div>
            </div>
                <div style={{ marginTop: "-200px", marginBottom: "20px" }}>
                    <div className="centered-container">
                        <h2 className="centered-text">Nemáte povolenia na správu členstiev rady</h2>
                    </div>
                    <div className="centered-container-img">
                        <img src={image} alt="Invalid code illustration" />
                    </div>
                </div>
            </div>
        );
    }

    const renderMembersByRole = (role, label) => {
        const roleMembers = members.filter(member => member.idRole === role);
        let requiredCount = 0;
    
        if (programType === 1 && role === 1) {
            requiredCount = 2;
        } else if (programType !== 1 && role === 1) {
            requiredCount = 4;
        } else if (programType === 1 && role === 2) {
            requiredCount = 3;
        } else if (role !== 1) {
            requiredCount = 1;
        }
    
        const addMemberSlots = Math.max(0, requiredCount - roleMembers.length);
        const isComplete = addMemberSlots === 0;
    
        const currentDate = new Date();
        const threeYearsFromNow = new Date();
        threeYearsFromNow.setFullYear(currentDate.getFullYear() + 3);
    
        return (
            <div className="role-section">
                <div className="role-header">
                    <h3 className="label-title">{label}</h3>
                </div>
                <div className="members-list">
                    {roleMembers.map((member, index) => {
                       const membershipEnd = new Date(member.membershipEnd);
                       const remainingDays = Math.ceil((membershipEnd - currentDate) / (1000 * 60 * 60 * 24));
                       const isExpiringSoon = remainingDays <= 32 && remainingDays > 0;
                       const isExpired = remainingDays <= 0; 
    
                        return (
                            <div
                                key={index}
                                className={`member-item ${isExpired ? 'expired' : ''} ${isExpiringSoon ? 'soon-expiring' : ''}`}
                            >
                                <img
                                    src={userImages[member.email] || defaultUserImage}
                                    alt={member.name}
                                    className="member-image"
                                />
                                <div className="member-info">
                                    <span className="member-name">{member.name}</span>
                                    <span className="member-email">{member.email}</span>
                                    {(isExpiringSoon || isExpired) && (
                                        <div className="expiration-warning">
                                            <FaExclamationTriangle className="expiration-warning-icon" />
                                            {isExpiringSoon && (
                                                <span>Členstvo vyprší o {remainingDays} dní.</span>
                                            )}
                                            {isExpired && (
                                                <span>Členstvo vypršalo dňa {membershipEnd.toLocaleDateString()}.</span>
                                            )}
                                        </div>
                                    )}
                                    {isExpired && (
                                        <button
                                            className="renew-membership-button"
                                            onClick={() => handleRenewMembershipClick(member)}
                                        >
                                            <FaSyncAlt /> Obnoviť členstvo
                                        </button>
                                    )}
                                      {(!isExpired && !isExpiringSoon) && (
                                        <div className="expiration-warning">
                                            <FaExclamationTriangle className="expiration-warning-icon" />
                                            <span>Členstvo vyprší o {remainingDays} dní.</span>
                                        </div>)}
                                </div>
                                <div className="action-buttons-container">
                                    <button
                                        className="remove-member-button"
                                        onClick={() => handleRemoveMemberClick(member)}
                                    >
                                        <FaTrashAlt />
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                    {[...Array(addMemberSlots)].map((_, index) => (
                        <div key={index} className="member-item add-member" onClick={() => handleAddMemberClick(role)}>
                            <FaUserPlus className="add-member-icon" />
                            <span>Pridať člena</span>
                        </div>
                    ))}
                </div>
                <div className={`status-icon ${isComplete ? '' : 'exclamation'}`}>
                    {isComplete ? <FaCheckCircle /> : <FaExclamationTriangle />}
                    {!isComplete && (
                        <div className="tooltip-text">
                            Je potrebné doplnenie členov rady!
                        </div>
                    )}
                    {isComplete && (
                        <div className="tooltip-text2">
                            Všetko je v poriadku.
                        </div>
                    )}
                </div>
                <div style={{ height: "30px" }}>
                    <div className="member-count-info">
                        <span>{roleMembers.length}/{requiredCount}</span>
                        Počet členov {isComplete ? <FaCheckCircle color="green" /> : <FaExclamationTriangle color="red" />}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="member-management-container">
            {loadingAction && (
                <div className="loading-spinner-component">
                    <div className="spinner"></div>
                </div>
            )}

            {showAddMemberModal && (
                <div className={`add-member-modal ${modalVisible ? 'show' : ''}`}>
                    <div className="modal-content3">
                        <h3>Vyberte člena na pridanie</h3>
                        <input
                            type="text"
                            className="search-input"
                            placeholder="Hľadať používateľa..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                       <ul className="available-users-list">
                            {filteredUsers.map(user => (
                                <li 
                                    key={user.email} 
                                    onClick={() => handleMemberSelect(user)} 
                                    className={`user-list-item ${user.countMembership >= 3 || user.isGraduateEnsurer || user.isStudentRepresentative ? 'disabled-user' : ''}`}
                                >
                                    <img src={userImages[user.email] || defaultUserImage} alt={user.name} className="user-image" />
                                    <div className="user-details">
                                        <div className="user-name">{user.name}</div>
                                        <div className="user-email">{user.email}</div>
                                        {(user.countMembership >= 3 || user.isGraduateEnsurer || user.isStudentRepresentative) && (
                                            <div className="user-limit-reached">
                                                {user.countMembership >= 3 && "Maximálny počet členstiev dosiahnutý. "}
                                                {user.isGraduateEnsurer && "Na túto poziíciu nemôže byť zvolený absolvent. "}
                                                {user.isStudentRepresentative && "Na túto poziíciu nemôže byť zvolený študentský zástupca"}
                                            </div>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <button className="close-modal-button" onClick={handleModalClose}>Zavrieť</button>
                    </div>
                </div>
            )}

            <ConfirmModal
                isVisible={isConfirmVisible}
                message={
                    memberToRenew
                        ? `Naozaj si prajete obnoviť členstvo pre člena ${memberToRenew?.name}?`
                        : `Naozaj si prajete odstrániť člena ${memberToRemove?.name}?`
                }
                leftButtonText="Nie"
                rightButtonText="Áno"
                leftButtonFunction={handleCancelRemove}
                rightButtonFunction={memberToRenew ? handleConfirmRenew : handleConfirmRemove}
            />
            <MessageModal
                isVisible={isMessageVisible}
                message={message}
                buttonFunction={handleMessageModalClose}
            />
            <div className="header-container">
                <h2 className="member-management-title">Správa členov rady</h2>
                <div className="program-name-container">
                    <h2 className="label-title2">Program {programName}</h2>
                    <div className="member-count">
                        <span>{totalCurrentMembers}/{totalRequiredMembers}</span>
                        Počet členov {isComplete ? <FaCheckCircle color="green" /> : <FaExclamationTriangle color="red" />}
                    </div>
                </div>
            </div>
            {loading ? (
                <div className='loading-spinner'></div>
            ) : (
                <>
                    {renderMembersByRole(1, 'Osoby zabezpečujúce profilové predmety')}
                    {programType === 1 && renderMembersByRole(2, 'Osoby zodpovedné za učiteľský základ')}
                    {renderMembersByRole(3, 'Absolvent študijného programu')}
                    {renderMembersByRole(4, 'Zástupca združení zamestnávateľov')}
                    {renderMembersByRole(5, 'Zástupca študentov')}
                    {renderMembersByRole(6, 'Garant')}
                </>
            )}
        </div>
    );
};

export default MemberManagement;
