import React, { useState, useEffect } from 'react';
import "./RegistrationModal.css"
import { Link } from 'react-router-dom';

const RegistrationModal = ({ isVisible, onClose }) => {

    const [email, setEmail] = useState('');
    const [showClass, setShowClass] = useState(''); 
    const serverURL =  process.env.REACT_APP_SERVER_URL;
    const [blinkClass, setBlinkClass] = useState('');
    const [message, setMessage] = useState(null);
    const [succesSend, setSuccesSend] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isVisible) {
          setShowClass('show');
        } else {
          setShowClass('');
        }
    }, [isVisible]);
  
    useEffect(() => {
       if(setSuccesSend){
        setSuccesSend(false);
       }
    }, [onClose]);

    const handleSendRegistrationForm = async (e) => {
      e.preventDefault();
      setLoading(true);
      try {

        const token = localStorage.getItem("token");
        
        const response = await fetch(`${serverURL}/api/auth/sendRegistration`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, token }),
        });
  
        setLoading(false);
        if (response.ok) {
           setSuccesSend(true);
           setMessage(null);
           setEmail("");
           
        } 

        else if(response.status === 500){
            setMessage("Zlyhanie na strane servera");
        } 
        else if (response.status === 401){
            const result = await response.json();
            setMessage(result.message);
        }
        else{
            setMessage("Účet na tento email je už registrovaný");
        }

      } catch (error) {
        alert('Zaslanie zlyhalo. Skúste to znova neskôr.');
        setMessage("Zlyhanie na strane servera")
        setLoading(false);
      }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handleOverlayClick = () => {
        setBlinkClass('blink');
        setTimeout(() => {
          setBlinkClass('');
        }, 300); 
    };
    
  return (
    <div className={`modal-overlay ${showClass} ${blinkClass}`} onClick={handleOverlayClick}>
        {loading === true &&  <div className="loading-spinner"></div>}
        <div className={`modal-content2 ${showClass} ${blinkClass}`} onClick={(e) => e.stopPropagation()}>
        {succesSend === false && (
            <>
            <button className="close-button" onClick={onClose}>&times;</button>
            <h2 id="titleModal" >Zaslanie Registračného Formuláru</h2>
            <form onSubmit={handleSendRegistrationForm}>
                <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                required
                />
                <span style={{ color: "red", marginBottom: "15px", textAlign: "left", fontSize: "16px" }}>{message}</span>
                <button type="submit" className="login-button">Zaslať registračný formulár</button>
            </form>
            </>)}
        {succesSend === true && (
            <>
             <button className="close-button" onClick={onClose}>&times;</button>
            <h2 id="titleModal"> Registračný formulár bol úspešne odoslaný </h2>
            <button className="ok-button" onClick={onClose}>Ok</button>
        </>
        )}
        </div>
  </div>
  );
};

export default RegistrationModal;
