import React, { useState, useEffect, useRef } from 'react';
import "./RightsModal.css";
import { FaCheckCircle, FaTimesCircle, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const RightsModal = ({ isOpen, onClose, isDean, role, admin, rector }) => {
    const [showClass, setShowClass] = useState('');
    const [expandedSection, setExpandedSection] = useState(null);
    const contentRefs = useRef({}); // Store refs for each section

    useEffect(() => {
        if (isOpen) {
            setShowClass('show');
        } else {
            setShowClass('');
        }
    }, [isOpen]);

    const toggleSection = (section) => {
        setExpandedSection((prevSection) => (prevSection === section ? null : section));
    };

    useEffect(() => {
        if (expandedSection) {
            contentRefs.current[expandedSection].style.maxHeight = `${contentRefs.current[expandedSection].scrollHeight}px`;
        }
        Object.keys(contentRefs.current).forEach((section) => {
            if (section !== expandedSection) {
                contentRefs.current[section].style.maxHeight = '0px';
            }
        });
    }, [expandedSection]);

    const rights = [];

    // Define rights based on the user's roles and statuses
    if (admin) {
        rights.push({
            title: 'Administrátor',
            description: 'Máte plné administrátorské práva.',
            functionalities: [
                'Spravovať členstvá RŠP',
                'Naplánovať a zrušiť zasadnutie rady',
                'Pridať dokumenty k zasadnutiam',
                'Spravovať hlasovania a ich výsledky',
                'Odoslať registračný formulár',
                'Pridať fakultu',
                'Vytvoriť radu študijného programu'
            ],
            hasRight: true,
            section: 'admin',
        });
    } else {
        rights.push({
            title: 'Administrátor',
            description: 'Nemáte administrátorské práva.',
            hasRight: false,
            section: 'admin',
        });
    }

    if (rector) {
        rights.push({
            title: 'Rektor',
            description: 'Máte práva rektora.',
            functionalities: [
                'Spravovať členstvá RŠP',
                'Spravovať hlasovania a ich výsledky',
                'Pridať fakultu',
                'Odoslať registračný formulár',
                'Spravovať dokumenty zasadnutí',
                'Prezerať všetky dokumenty'
            ],
            hasRight: true,
            section: 'rector',
        });
    } else {
        rights.push({
            title: 'Rektor',
            description: 'Nemáte práva rektora.',
            hasRight: false,
            section: 'rector',
        });
    }

    if (isDean) {
        rights.push({
            title: 'Dekan',
            description: 'Máte práva dekana fakulty.',
            functionalities: [
                'Spravovať členstvá',
                'Spravovať hlasovania a ich výsledky',
                'Pridať dokumenty k zasadnutiam',
                'Spravovať dokumenty zasadnutí',
                'Prezerať všetky dokumenty'
            ],
            hasRight: true,
            section: 'dean',
        });
    } else {
        rights.push({
            title: 'Dekan',
            description: 'Nemáte práva dekana fakulty.',
            hasRight: false,
            section: 'dean',
        });
    }

    if (role === 6) {
        rights.push({
            title: 'Garant',
            description: 'Máte práva garanta.',
            functionalities: [
                'Spravovať členstvá',
                'Naplánovať zasadnutie rady',
                'Pridať dokumenty k zasadnutiam',
                'Spravovať hlasovania a ich výsledky',
                'Podávať návrhy a žiadosti',
                'Prezerať dokumenty'
            ],
            hasRight: true,
            section: 'role',
        });
    } else if (role === 1) {
        rights.push({
            title: 'Osoba zabezpečujúca profilové predmety',
            description: 'Máte práva osoby zabezpečujúcej profilové predmety.',
            functionalities: [
                'Pridať dokumenty k zasadnutiam',
                'Vytvárať hlasovania a prezerať ich výsledky',
                'Podávať návrhy a žiadosti',
                'Prezerať dokumenty',
                'Spravovať dokumenty vami pridané'
            ],
            hasRight: true,
            section: 'role',
        });
    } else if (role === 2) {
        rights.push({
            title: 'Osoba zodpovedná za učiteľský základ',
            description: 'Máte práva osoby zodpovednej za učiteľský základ.',
            functionalities: [
                'Pridať dokumenty k zasadnutiam',
                'Vytvárať hlasovania a prezerať ich výsledky',
                'Podávať návrhy a žiadosti',
                'Prezerať dokumenty',
                'Spravovať dokumenty vami pridané'
            ],
            hasRight: true,
            section: 'role',
        });
    } else if (role === 3) {
        rights.push({
            title: 'Absolvent',
            description: 'Máte práva absolventa študijného programu.',
            functionalities: [
                'Pridať dokumenty k zasadnutiam',
                'Vytvárať hlasovania a prezerať ich výsledky',
                'Podávať návrhy a žiadosti',
                'Prezerať dokumenty',
                'Spravovať dokumenty vami pridané'
            ],
            hasRight: true,
            section: 'role',
        });
    } else if (role === 4) {
        rights.push({
            title: 'Zástupca združení zamestnávateľov',
            description: 'Máte práva zástupcu združení zamestnávateľov.',
            functionalities: [
                'Pridať dokumenty k zasadnutiam',
                'Vytvárať hlasovania a prezerať ich výsledky',
                'Podávať návrhy a žiadosti',
                'Prezerať dokumenty',
                'Spravovať dokumenty vami pridané'
            ],
            hasRight: true,
            section: 'role',
        });
    } else if (role === 5) {
        rights.push({
            title: 'Zástupca študentov',
            description: 'Máte práva zástupcu študentov.',
            functionalities: [
                'Pridať dokumenty k zasadnutiam',
                'Vytvárať hlasovania a prezerať ich výsledky',
                'Podávať návrhy a žiadosti',
                'Prezerať dokumenty',
                'Spravovať dokumenty vami pridané'
            ],
            hasRight: true,
            section: 'role',
        });
    } else {
        rights.push({
            title: 'Bez role',
            description: 'Nemáte špecifické práva podľa role.',
            hasRight: false,
            section: 'role',
        });
    }

    return (
        <div className={`modal-overlay ${showClass}`}>
            <div className={`modal-content3 ${showClass}`}>
                <button className="close-button" onClick={onClose}>&times;</button>
                <h3 className="modal-title">Práva používateľa</h3>
                <div className="rights-list">
                    {rights.map((right, index) => (
                        <div key={index} className="right-section">
                            <div className="right-header" onClick={() => toggleSection(right.section)}>
                                <span>{right.title}</span>
                                {expandedSection === right.section ? <FaChevronUp /> : <FaChevronDown />}
                            </div>
                            <div
                                ref={(el) => (contentRefs.current[right.section] = el)}
                                className="right-content"
                                style={{
                                    maxHeight: expandedSection === right.section ? '1000px' : '0px',
                                    overflow: 'hidden',
                                    transition: 'max-height 0.5s ease-in-out',
                                }}
                            >
                                <ul>
                                    <li className="right-item">
                                        {right.hasRight ? (
                                            <FaCheckCircle className="icon-check" style={{ color: 'green' }} />
                                        ) : (
                                            <FaTimesCircle className="icon-cross" style={{ color: 'red' }} />
                                        )}
                                        <span>{right.description}</span>
                                    </li>
                                    {right.functionalities && right.functionalities.map((func, i) => (
                                        <li key={i} className="functionality-item">
                                            <FaCheckCircle className="icon-check" style={{ color: 'green' }} />
                                            <span>{func}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RightsModal;
